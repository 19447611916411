const useValidations = () => {

    const isEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }
    const isPhone = (phone: string) => {
        const re = /^(374|0)(4[134]|55|77|88|9[134689])\d{6}$/;
        return !re.test(phone);

    }
    const isEmpty = (data: string) => {
        return !data.trim().length
    }

    const isNull = (data: string | number | null) => {
        return !data
    }

    const isFullName = (data: string) => {
        return !(data.split(" ").length > 1 && data.split(" ")[0].length > 0 && data.split(" ")[1].length)
    }

    return {
        isEmail, isEmpty, isNull, isPhone, isFullName
    }


}
export default useValidations