import "./style.scss"
import {useTranslation} from "react-i18next";
import {useContext, useRef, useState} from "react";
import {ClickSoundContext} from "../../../../context/clickSoundContext";
import {MusicContext} from "../../../../context/musicPlayerContext";
import {useLocation} from "react-router";
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "../../../../router";
import {IHomeMusic} from "../../../../models/homePageMusic.model";

interface IHomeAudioPopUpProps {
    isOpen: string;
    backPage: RoutesName|"#";
    music: IHomeMusic;
    duration: number;
    currentTime: number;
    backClick?:()=>void
}

const HomeAudioPopUp = ({isOpen, backPage, music, duration, currentTime,backClick}: IHomeAudioPopUpProps) => {
    const {t} = useTranslation()
    const textContainer = useRef<HTMLDivElement | null>(null)
    const [translatePosition, setTranslatePosition] = useState(0)
    const [isActive, setIsActive] = useState<"repeat" | "loop">("loop")
    const {playSound} = useContext(ClickSoundContext)
    const {setMethod, method, format, setFormat} = useContext(MusicContext)
    const location = useLocation()


    return (
        <div className={`P-audioPopUp G-PopUp ${!!isOpen ? "open" : ""} ${music.type}`}>
            <div className={`G-container`}>
                <div className={`P-actionsContainer`}>
                    <GoBackButton backPage={backPage} backClick={backClick}/>
                    <div className={`P-icons`}>
                        <span className={`icon-repeat ${method === "repeat" ? "active" : ""}`}
                              onClick={() => {
                                  setMethod("repeat")
                              }}></span>
                        <span className={`icon-loop ${method === "loop" ? "active" : ""}`}
                              onClick={() => {
                                  setMethod("loop")
                              }}></span>
                        <span className={`icon-wav ${format === "wav" ? "active" : ""}`} onClick={() => {
                            setFormat("wav")
                        }}>wav</span>
                        <span className={`icon-mp3 ${format === "mp3" ? "active" : ""}`} onClick={() => {
                            setFormat("mp3")
                        }}>mp3</span>
                    </div>
                </div>
                <div className={`G-flex`}>
                    {music.comment && <p className={`P-comment`}>{music.comment}</p>}

                    {(music.text || music.description) && <div className={`P-textContainer`}>
                        <div className={`P-musicTextContainer`}>
                            <div className={`P-textWrapper`}>
                                <div className={`P-musicText`}
                                     style={{transform: `translateY(calc(10vw - ${translatePosition}px))`}}
                                     ref={textContainer}>{
                                    music.text
                                }</div>
                            </div>
                        </div>
                        {music.description && <p className={`P-description`}>{music.description}</p>}
                    </div>}
                </div>

            </div>
        </div>
    )
}
export default HomeAudioPopUp