import "./style.scss"
import FutuButton from "src/components/futuButton";
import {useTranslation} from "react-i18next";
import {FutuButtonSizeEnum, FutuButtonTypeEnum} from "src/enums/futuButtonEnum";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {RoutesName} from "src/router";
import AudioPlayerCircle from "src/components/audioPlayerCircle";
import React, {createRef, LegacyRef, ReactElement, useContext, useEffect, useRef, useState} from "react";
import AudioWavePlayer from "../../components/audioWavePlayer";
import {MusicContext} from "../../context/musicPlayerContext";
import useMixColors from "../../hooks/useMixColors";
import SocialIcons from "src/components/socialIcons";
import {PageContentContext} from "../../context/pageContentContext";
import {IHomeMusic} from "../../models/homePageMusic.model";

const Home = () => {
    const {t} = useTranslation()
    const {homePage} = useContext(PageContentContext).data
    const {mainMusicData, homeMusicData} = useContext(MusicContext)
    const [data, setData] = useState([...homeMusicData.map(item => {
        return {
            ...item, src: <audio src={item.src as string}/>, src2: <audio src={item.src2 as string}/>
        }
    })])
    const [mainMusic, setMainMusic] = useState<IHomeMusic>({
            ...(mainMusicData as IHomeMusic),
            src: <audio src={mainMusicData?.src as string}/>,
            src2: <audio src={mainMusicData?.src2 as string}/>
        }
    )
    const [takenElement, setTakenElement] = useState
    < string > ("")
    const [coveredElement, setCoveredElement] = useState
    < string > ("")
    const [errorElement, setErrorElement] = useState
    < string > ("")
    const [musicId, setMusicID] = useState("")
    const [nextId, setNextID] = useState(data[data.findIndex(item => item.id === musicId) + 1 >
    data.length ? 0 : data.findIndex(item => item.id === musicId) + 1])
    const [portrait, setPortrait] = useState(window.innerWidth < window.innerHeight)
    const {onBack, onFinish, isPlay, setIsPlay, isClick, format, setIsLoaded} = useContext(MusicContext)
    const [searchParams] = useSearchParams();
    const musicItemContainer = useRef(null)
    const mainMusicRef = useRef(null)
    const navigate = useNavigate()
    const mixColor = useMixColors

    const musicItem = useRef(homeMusicData.map(() => createRef()))

    const setError = (currentElement: IHomeMusic) => {
        setErrorElement(currentElement.id)
        const timer = setTimeout(() => {
            setErrorElement("")
            clearTimeout(timer)
        }, 1000)
        return;
    }

    const onDragEnd = (event: React.DragEvent<Element>, index: number) => {
        const newData = [...data]
        const currentElement = newData.find(element => element.id === takenElement)
        const covered = newData.find(element => element.id === coveredElement)
        if (currentElement && covered) {
            if (!("sequelOf" in currentElement) && !("prequelOf" in currentElement) && !("prequelOf" in covered) && !("sequelOf" in covered)) {
                setError(currentElement)

            }
            if ("sequelOf" in currentElement) {
                if (currentElement.sequelOf === covered.id) {
                    covered.id = covered.id + "_" + currentElement.id
                    covered.color = mixColor(covered.color, currentElement.color)
                    newData.splice(index, 1)
                    setData([...newData])
                } else {
                    setError(currentElement)
                }
            }
            if ("prequelOf" in currentElement) {
                if (currentElement.prequelOf === covered.id) {

                    covered.id = currentElement.id + "_" + covered.id
                    covered.color = mixColor(covered.color, currentElement.color)
                    newData.splice(index, 1)
                    setData([...newData])
                } else {
                    setError(currentElement)

                }
            }
            if ("prequelOf" in covered) {
                if (covered.prequelOf === currentElement.id) {

                    covered.id = currentElement.id + "_" + covered.id
                    covered.color = mixColor(covered.color, currentElement.color)
                    newData.splice(index, 1)
                    setData([...newData])
                } else {
                    setError(currentElement)

                }
            }
            if ("sequelOf" in covered) {
                if (covered.sequelOf === currentElement.id) {

                    covered.id = currentElement.id + "_" + covered.id
                    covered.color = mixColor(covered.color, currentElement.color)
                    newData.splice(index, 1)
                    setData([...newData])
                } else {
                    setError(currentElement)

                }
            }

        } else {
            return
        }


    }

    const onDragOver = (event: React.DragEvent<Element>, item: IHomeMusic) => {
        setCoveredElement(item.id)
    }
    const moveTo = (current: HTMLElement) => {
        if (current) {
            const container = musicItemContainer.current as unknown as HTMLDivElement

            current.style.marginRight = portrait ? "45vw" : "15vw";

            container.scrollLeft = current.offsetLeft - container.offsetLeft - 5
        }

        // }


    }
    useEffect(() => {
        onFinish(() => () => {
            if (!!data[data.findIndex(item => item.id === musicId) + 1] && !!data[data.findIndex(item => item.id === musicId) + 1].id) {
                navigate(`?musicId=${data[data.findIndex(item => item.id === musicId) + 1].id}`)
                setIsPlay(true)
            } else {
                navigate("/")
            }
        })
        onBack(() => () => {
            if (!!data[data.findIndex(item => item.id === musicId) - 1] && !!data[data.findIndex(item => item.id === musicId) - 1].id) {
                navigate(`?musicId=${data[data.findIndex(item => item.id === musicId) - 1].id}`)
                setIsPlay(true)
            } else if (!!mainMusic) {
                navigate(`?musicId=${mainMusic.id}`)
                if (mainMusic.id === musicId) {
                    navigate("/")
                }
            }
        })
    }, [musicId])
    useEffect(() => {


        window.addEventListener("resize", () => {
            setPortrait(window.innerWidth < window.innerHeight)
        })
        return () => {
            window.removeEventListener("resize", () => {
                setPortrait(window.innerWidth < window.innerHeight)
            })
        }
    }, [])
    /*useEffect(() => {
            if (searchParams.has("musicId")) {
                const newMusicID = searchParams.get("musicId") as string
                setMusicID(newMusicID)
                // if (newMusicID.split("_").length > 1) {
                //     const ids = newMusicID.split("_")
                const newData = [...data]
                // ids.map((item, index) => {
                //     if (index !== 0) {
                //
                //         const deletedIndex = newData.findIndex((element) => element.id === item)
                //         const changedIndex = newData.findIndex((element) => element.id === ids[0])

                //
                //         newData[changedIndex].id = newData[changedIndex].id + "_" + item
                //         newData[changedIndex].color = mixColor(newData[1].color, newData[deletedIndex].color)
                //         // newData[changedIndex].src = newData[changedIndex].src + "_" + newData[deletedIndex].src
                //         newData.splice(deletedIndex, 1)

                //         setData([...newData])
                //     } else return
                // })
                const current = musicItem.current[newData.findIndex((item) => item.id === newMusicID)].current as HTMLDivElement
                // if (newData.findIndex((item) => item.id === newMusicID) === musicItem.current.length - 1) {
                //     current = musicItemContainer.current as unknown as HTMLDivElement
                // }
                moveTo(current)

                // }
            }
        }
        ,
        []
    )*/
    useEffect(() => {
        setData([...homeMusicData.map(item => {
            return {
                ...item, src: <audio src={item.src as string}/>, src2: <audio src={item.src2 as string}/>
            }
        })])
        setMainMusic({
            ...(mainMusicData as IHomeMusic),
            src: <audio src={mainMusicData?.src as string}/>,
            src2: <audio src={mainMusicData?.src2 as string}/>
        })
        // musicItem = useRef()
    }, [homeMusicData])
    useEffect(() => {

        if (mainMusic && mainMusicRef.current) {
            if (searchParams.has("musicId")) {


                const newMusicID = searchParams.get("musicId") as string
                setMusicID(newMusicID)
                let current: HTMLDivElement

                if (newMusicID === mainMusic?.id) {
                    current = mainMusicRef.current as unknown as HTMLDivElement
                    {
                        if (musicItem) {

                            musicItem.current.map(current => {
                                if (current.current) {
                                    (current.current as HTMLElement).style.marginRight = "0"

                                }
                            })
                        }
                    }
                    moveTo(current)
                } else {
                    (mainMusicRef.current as unknown as HTMLDivElement).style.marginRight = "1.5vw"
                    if (musicItem) {
                        musicItem.current.map(current => {
                            if (current.current) {
                                (current.current as HTMLElement).style.marginRight = "0"
                            }
                        })

                        if (data.findIndex((item) => item.id === newMusicID) !== musicItem.current.length - 1) {
                            current = musicItem?.current[data.findIndex((item) => item.id === newMusicID)]?.current as HTMLDivElement
                            moveTo(current)
                        } else {
                            current = musicItemContainer.current as unknown as HTMLDivElement

                            current.style.marginRight = portrait ? "45vw" : "15vw";

                            current.scrollLeft = current.scrollWidth
                            // current.scroll({left: current.scrollWidth, behavior: "smooth"})

                        }
                    }
                }
                // moveTo(current)
            } else {
                (mainMusicRef.current as unknown as HTMLDivElement).style.marginRight = "1.5vw"
                if (musicItem) {
                    musicItem.current.map(current => {
                        if (current.current) {
                            (current.current as HTMLElement).style.marginRight = "0"
                        }
                    })
                }
                if (musicItemContainer.current) {
                    (musicItemContainer.current as HTMLElement).style.marginRight = "0"
                }
                setMusicID("")
            }
        }

    }, [searchParams, homeMusicData])


    return (
        <div className={`P-homePage`}>
            <main className={`G-container G-justify-between`}>
                <section className={`P-actionsContainer`}>

                    <div className={`P-socials G-mobile`}>
                        {/*<p className={`P-streamMe`}>{t("STREAM-ME-HERE-text")}</p>*/}
                        <SocialIcons/>
                    </div>
                    <div className={`P-buttons`}>
                        <NavLink to={RoutesName.Yoga_page}>
                            <FutuButton size={FutuButtonSizeEnum.l}
                                        type={FutuButtonTypeEnum.yoga}>
                                <p>
                                    <span className={"G-futu"}>{t("Futu-text")}</span><span
                                    className={"G-yoga"}>{t("Yoga-text")}</span>
                                </p>
                            </FutuButton>
                        </NavLink>
                        <h4 className={`P-description`}>{homePage.buttons.futuYoga.description}</h4>
                    </div>

                    <div className={`P-buttons`}>
                        <NavLink to={RoutesName.Home_page} className={`comingSoon`}>
                            <FutuButton size={FutuButtonSizeEnum.l}
                                        type={FutuButtonTypeEnum.live}>
                                <p>
                                    <span className={"G-futu"}>{t("Futu-text")}</span><span
                                    className={"G-live"}>{t("Live-text")}</span>
                                </p>
                            </FutuButton>

                        </NavLink>
                        <h4 className={`P-description`}>{homePage.buttons.futuLive.description}</h4>

                        <h3 className={`P-title G-mobile`}
                            // ref={mainMusicItem}
                        >
                            @
                            <span className={`P-futu`}>Futu</span>
                            <span className={`P-rili`}>rili</span>
                        </h3>
                        <h4 className={`P-description`}>{homePage.buttons.futuLive.description}</h4>
                        {homePage.samplePage && <NavLink to={RoutesName.Exclusive_page}
                                                         className={`P-exclusiveButton G-desktop`}>{t("Samples-text")}</NavLink>}
                        {/* <div className={`P-socialIconsContainer G-desktop`}>
                            <SocialIcons/>
                        </div>*/}

                    </div>
                    {homePage.samplePage && <NavLink to={RoutesName.Exclusive_page}
                                                     className={`P-exclusiveButton G-mobile`}>{t("Samples-text")}</NavLink>}
                    <div className={`P-message G-desktop`}>

                        {/* <button className={`P-triangleButton`}>download<br/>
                            SAMPLES
                            <br/>
                            from the album
                        </button>*/}

                        <p className={`P-data`}>{homePage.adBanner1}</p>
                        <p className={`P-mainText`}>{homePage.adBanner2}</p>
                        <p className={`P-secondaryText`}>{homePage.adBanner3}</p>
                    </div>
                </section>

                <section className={`P-scrollContainer`}>

                    <h3 className={`P-title G-desktop`}
                        // ref={mainMusicItem}
                        // style={{marginLeft: `${marginLeft}px`}}
                    >
                        @
                        <span className={`P-futu`}>Futu</span>
                        <span className={`P-rili`}>rili</span>
                        {/*<p>{elementTop + "  //  " + size.width + "/" + size.height + "/" + marginLeft}</p>*/}
                    </h3>

                    <div className={`G-flex P-musicContainer`}>

                        {mainMusic && <div
                            className={`P-musicMainItem  ${musicId === mainMusic.id ? "open" : ""}`}
                            // onClick={() => setIsOpen(mainMusic.id)}
                            ref={mainMusicRef}
                        >
                            <AudioPlayerCircle size={"L"} music={mainMusic}
                                               isOpen={musicId === mainMusic.id}
                            />

                        </div>}
                        <section className={`P-musicItemsContainer  `}
                                 ref={musicItemContainer as LegacyRef<HTMLElement>}>

                            {data.map((item, index) => (

                                <div
                                    className={`P-musicItem ${musicId === item.id && item.id.length > 0 ? "open" : ""}`}
                                    key={item.id + item.name}
                                    ref={musicItem.current[index] as LegacyRef<HTMLDivElement>}


                                >

                                    <AudioPlayerCircle music={item}
                                                       size={"M"} draggable={true}
                                                       onDragStart={(event) => {
                                                           setTakenElement(item.id)
                                                       }}

                                                       errorClass={errorElement === item.id ? "errorClass" : ""}
                                                       onDragEnd={(event) => {
                                                           onDragEnd(event, index)
                                                       }}
                                                       onDragOver={(event) => {
                                                           onDragOver(event, item)
                                                       }}
                                                       isOpen={musicId === item.id && item.id.length > 0}
                                    />
                                </div>

                            ))}
                        </section>
                        {((!!musicId && data.find(item => item.id === musicId)) || (!!musicId && mainMusic && mainMusic.id === musicId)) &&
                            <AudioWavePlayer backPage={RoutesName.Home_page}
                                             music={(mainMusic && mainMusic.id === musicId) ? mainMusic : data.find(item => item.id === musicId) as IHomeMusic}
                                             mediaElement={format === "wav" ? ((mainMusic && mainMusic?.id === musicId) ? mainMusic.src as ReactElement : data.find(item => item.id === musicId)?.src as ReactElement) : ((mainMusic && mainMusic?.id === musicId) ? mainMusic.src2 as ReactElement : data.find(item => item.id === musicId)?.src2 as ReactElement)}
                                             isClick={isClick}
                                             isPlay={isPlay}
                                             setIsPlay={setIsPlay}></AudioWavePlayer>
                        }
                        {/*   {!!musicId && mainMusic.id === musicId &&
                            <AudioWavePlayer backPage={RoutesName.Home_page}
                                             music={mainMusic as ITestData} isClick={isClick}
                                             isPlay={isPlay}
                                             setIsPlay={setIsPlay}></AudioWavePlayer>
                        }*/}
                    </div>
                </section>
                <section className={`G-flex G-mobile G-align-end`}>
                    <div className={`P-message `}>
                        {/*<NavLink to={RoutesName.Exclusive_page}*/}
                        {/*         className={`P-exclusiveButton`}>{t("exclusive-text")}</NavLink>*/}
                        <p className={`P-data`}>{homePage.adBanner1}</p>
                        <p className={`P-mainText`}>{homePage.adBanner2}</p>
                        <p className={`P-secondaryText`}> {homePage.adBanner3}</p>
                    </div>

                </section>
            </main>
        </div>)
}

export default Home