import "./style.scss"

interface IProps {
    labelLeft: string
    labelRight: string
    value: boolean
    onChange: () => void
}

const FutuSwitcher = ({labelLeft, labelRight, value, onChange}: IProps) => {
    return (<div className={`P-futuSwitcher`}>
        <span className={`P-label`}>{labelLeft}</span>
        <span className={`P-switcherContainer ${value ? "checked" : ""}`} onClick={onChange}><span
            className={`P-switcher`}/></span>
        <span className={`P-label`}>{labelRight}</span>
    </div>)
}

export default FutuSwitcher