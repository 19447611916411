import FutuButton from "src/components/futuButton"
import "./style.scss"
import {useTranslation} from "react-i18next";
import {FutuButtonSizeEnum, FutuButtonTypeEnum} from "../../enums/futuButtonEnum";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {RoutesName} from "../../router";
import AudioPlayer from "src/components/audioPlayer";
import {AudioPlayerEnum} from "../../enums/audioPlayerEnum";
import {useContext, useEffect, useState} from "react";
import {ClickSoundContext} from "../../context/clickSoundContext";
import {MusicContext} from "../../context/musicPlayerContext";
import {PageContentContext} from "../../context/pageContentContext";
import MeditationButton from "./components/meditationButton";
import {publishableKey} from "../../models/publishableKey";
import {MusicContextYoga} from "../../context/musicContextYoga";

type TabT = "present" | "past" | "future" | "helltation" | ""
const YogaPage = () => {
    const {t} = useTranslation()

    const {yogaPage} = useContext(PageContentContext).data
    const {playSound} = useContext(ClickSoundContext)
    const {getMusics, data} = useContext(MusicContextYoga)
    const {onFinish, onBack, isPlay, setIsPlay, isClick} = useContext(MusicContext)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [tab, setTab] = useState("")
    const [currentPlaying, setCurrentPlaying] = useState<string>("")

    const musics = {
        preset: {
            src: "https://futuilritest.s3.eu-north-1.amazonaws.com/FUTU+-+The+Present.wav",
            src2: "https://futuilritest.s3.eu-north-1.amazonaws.com/FUTU+-+The+Present.mp3",
            route: "present"
        },
        past: {
            src: "",
            src2: "",
            route: "past"
        },
        future: {src: "", src2: "", route: "future"},
        helltation: {
            src: "https://futuilritest.s3.eu-north-1.amazonaws.com/helltation+final.wav5b1b6f060fe2ceeee8537c25f1af3ab5ae578e19e88a0bab67a64fc152070ff4",
            src2: "https://futuilritest.s3.eu-north-1.amazonaws.com/helltation+final.mp32609a5f93c2286581cf23c1c0f1328c3038be9c8a8f4ed12fa142015d9f8a97c",
            route: "helltation"
        }
    }
    useEffect(() => {
        if (searchParams.has("tab")) {
            const newMusicID = searchParams.get("tab") as string
            setTab(newMusicID)
            setCurrentPlaying(newMusicID as TabT)
        } else {
            setTab("")
            setCurrentPlaying("")

        }
    }, [searchParams])

    useEffect(() => {

            onFinish(() => {
                return () => {
                    if (tab === "present") {
                        if (!!musics.helltation.src) {
                            navigate(`?tab=${musics.helltation.route}`)
                        } /*else if (!!musics.past.src) {
                            navigate(`?tab=${musics.past.route}`)
                        }*/ else {
                            navigate(RoutesName.Yoga_page)
                        }
                    } /*else if (tab === "future") {
                        if (!!musics.past.src) {
                            navigate(`?tab=${musics.past.route}`)
                        } /!*else if (!!musics.future.src) {
                            navigate(`?tab=${musics.future.route}`)
                        }*!/ else {
                            navigate(RoutesName.Yoga_page)
                        }
                    }*/ else if (tab === "helltation") {
                        /* if (!!musics.past.src) {
                             navigate(`?tab=${musics.past.route}`)
                         } /!*else if (!!musics.future.src) {
                             navigate(`?tab=${musics.future.route}`)
                         }*!/ else {*/
                        navigate(RoutesName.Yoga_page)
                        /*}*/
                    }

                }

            })
            onBack(() => {
                return () => {
                    if (tab === "present") {
                        navigate(RoutesName.Yoga_page)


                    } else if (tab === "helltation") {
                        if (!!musics.preset.src) {
                            navigate(`?tab=${musics.preset.route}`)
                        } /*else if (!!musics.future.src) {
                            navigate(`?tab=${musics.future.route}`)
                        }*/ else {
                            navigate(RoutesName.Yoga_page)
                        }
                    } /*else if (tab === "past") {
                        if (!!musics.future.src) {
                            navigate(`?tab=${musics.future.route}`)
                        } else if (!!musics.past.src) {
                            navigate(`?tab=${musics.preset.route}`)
                        } else {
                            navigate(RoutesName.Yoga_page)
                        }
                         /!*if (!!musics.past.src) {
                             navigate(`?tab=${musics.past.route}`)
                         } /!*else if (!!musics.future.src) {
                             navigate(`?tab=${musics.future.route}`)
                         }*!/ else {
                        navigate(RoutesName.Yoga_page)
                        }*!/
                    }*/

                }

            })
        }, [tab]
    )
    // useEffect(() => {
    //     setIsPlayYoga(currentPlaying !== "")
    // }, [currentPlaying])


    useEffect(() => {
        (async () => {
            await getMusics()
        })()
    }, [])


    return (
        <div className={`P-yogaPage`}>
            {/* <audio ref={audio} autoPlay={true} controls={false}>
                <source src={rain}/>
            </audio>*/}
            <main className={`G-container `}>
                <section className={`P-titleAndActionsContainer G-align-end G-justify-between`}>
                    <section className={`P-titleSection`}>
                        <p className={`P-mainText G-futu`}>{t("Futu-text")}<span
                            className={`G-yoga`}>{t("Yoga-text")}</span></p>
                        <p className={`P-subText G-yellowText`}>{yogaPage.subtitle}</p>
                    </section>
                    <section className={`P-actionsSection G-align-end`}>
                        <NavLink to={RoutesName.Home_page} className={`P-button`}>
                            <p className={`P-homeTitle G-yellowText`}>{yogaPage.buttons.futuHome.description}</p>
                            <FutuButton type={FutuButtonTypeEnum.home}
                                        size={FutuButtonSizeEnum.m}><span
                                className={`G-home`}>{t("Home-text")}</span></FutuButton>
                        </NavLink>
                        <NavLink to={RoutesName.Yoga_page} className={`P-button`}>
                            <p className={`P-liveTitle G-futu`}>{yogaPage.buttons.futuLive.description}</p>

                            <FutuButton type={FutuButtonTypeEnum.live}
                                        size={FutuButtonSizeEnum.m}><span
                                className={`G-live`}>{t("Live-text")}</span></FutuButton>
                        </NavLink>
                    </section>
                </section>
                <div className="G-mainContainer">
                    <section className={`P-playersSection`}>
                        {data && data.map(item => {
                                if (item._id) {
                                    return (<section className={`P-present`} key={item._id as string}>
                                            <AudioPlayer orientation={AudioPlayerEnum.right} textAsBanner={""}
                                                         currentPlaying={currentPlaying === item._id as string}
                                                         onPlay={(e) => {
                                                             setCurrentPlaying(() => item._id as string)
                                                         }}
                                                         music={{src: item.src, src2: item.src2, route: item._id as string}}
                                                         title={
                                                             <div className={`P-presentText`}>
                                                                 {item.title}
                                                                 <p className={`P-info`}>{item.subtitle} </p>
                                                             </div>
                                                         }
                                            />

                                        </section>
                                    )
                                } else {
                                    return null
                                }
                            }
                        )}

                        {/* <section className={`P-future`}>
                        <div className={`P-playerContainer`} onMouseDown={playSound}>
                            <AudioPlayer
                                onPlay={(e) => {

                                    setCurrentPlaying(() => "future")

                                }}
                                currentPlaying={currentPlaying === "future"}
                                orientation={AudioPlayerEnum.left}
                                title={t("The-Future-text")}/>
                        </div>
                    </section>*/}
                        {/*/___________________*/}
                        {/*
                    <section className={`P-present test`}>
                        <AudioPlayer orientation={AudioPlayerEnum.right} textAsBanner={" "}
                                     currentPlaying={currentPlaying === "present"}
                                     onPlay={(e) => {
                                         setCurrentPlaying(() => "present")
                                     }}
                                     music={musics.preset}
                                     title={
                                         <div className={`P-presentText`}>
                                             {t("The-Present-text")}
                                             <p className={`P-info`}>{t("eight-minutes-of-text")} <span
                                                 className={`P-colored`}>{t("peace-text")}</span></p>
                                         </div>
                                     }
                        />

                    </section>*/}


                        {/*/___________________*/}
                        {/*<section className={`P-past`} onMouseDown={playSound}>
                        <AudioPlayer
                            onPlay={(e) => {
                                setCurrentPlaying(() => "past")

                            }}
                            currentPlaying={currentPlaying === "past"}
                            orientation={AudioPlayerEnum.right}
                            title={t("The-Past-text")}/>

                    </section>*/}
                        {/*onMouseDown={playSound}*/}
                        {/*<section className={`P-past`}>
                        <AudioPlayer
                            onPlay={(e) => {
                                setCurrentPlaying(() => "helltation")

                            }}
                            music={musics.helltation}
                            currentPlaying={currentPlaying === "helltation"}
                            orientation={AudioPlayerEnum.right}
                            title={t("Helltation-text")}/>

                    </section>*/}


                        {/*{publishableKey && <MeditationPopUp/>}*/}
                    </section>
                    {publishableKey && <section className={`P-meditationSection`}>
                        <NavLink to={`/${RoutesName.Meditation}`}>

                            <MeditationButton data={yogaPage.buttons.meditation}/>
                        </NavLink>
                    </section>}
                </div>
            </main>
        </div>
    )
}
export default YogaPage