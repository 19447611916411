import Service from "../sevice/request.service"
import {IPageContent} from "../../models/content.model";

interface ISuccess {
    success: boolean
}

class ContentAPI {
    static update = (data: IPageContent) => {
        return Service.PATCH <IPageContent, ISuccess>("/content", data)
    }

    static read = () => {
        return Service.GET<null, IPageContent>("/content",)
    }
}

export default ContentAPI