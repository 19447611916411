import "./style.scss"
import Slider from "react-slick";
import React, {useContext, useEffect, useRef, useState} from "react";
import ExclusivePlayer from "../../components/exclusivePlayer";
import {TReadChoice, TReadChoices} from "../../models/meditation.model";
import AudioWavePlayer from "../../components/audioWavePlayer";
import {musicPopUpEnum} from "../../models/homePageMusic.model";
import {MusicContext} from "../../context/musicPlayerContext";
import {RoutesName} from "../../router";
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthorizationContext} from "../../context/authorizationContext";
import useDownloadFile from "../../hooks/useDownloadFile";
import ClickOutsideBlock from "../../components/click-outsideBlock";
import PopUpContainer from "../../components/popUpContainer";
import DonationPopUp from "../../components/donationPopUp";
import {IProfile, ITokenField} from "../../models/auth.model";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AccountsEnum} from "../../enums/accountsEnum";
import {publishableKey} from "../../models/publishableKey";
import {MeditationContext} from "../../context/meditationContext";


type TSelected = { [key: string]: TReadChoice | TReadChoice[] | TReadChoices[] }
const MySwal = withReactContent(Swal)

const Meditation = () => {
    const {t} = useTranslation()
    const [selected, setSelected] = useState<TSelected>({})
    const {
        setMethod,
        setIsClick,
        setIsPlay,
    } = useContext(MusicContext)

    const {getData, data} = useContext(MeditationContext)
    const {user, setUser, token} = useContext(AuthorizationContext)
    const [step, setStep] = useState<{ [key: string]: number }>()
    const [historyIndex, setHistoryIndex] = useState<{ [key: string]: number }>()
    const [prevHistoryIndex, setPrevHistoryIndex] = useState<{ [key: string]: number }>()
    const [play, setPlay] = useState(false)
    const [buyed, setBuyed] = useState<string[]>([])
    const [currentPlaying, setCurrentPlaying] = useState(false)
    // const [months, setMonths] = useState<TReadMonth[]>([])
    const [settings, setSetting] = useState({});
    const [amount, setAmount] = useState(0)
    const [moving, setMoving] = useState(false)

    const downloadFile = useDownloadFile()
    const nameContainer = useRef<HTMLParagraphElement | null>(null)
    const name = useRef<HTMLSpanElement | null>(null)
    const [playing, setPlaying] = useState<TReadChoice &{monthId:string} | null>(null)
    const audioRef = useRef<HTMLAudioElement | null>(null)

    const navigate = useNavigate()
    useEffect(() => {
        if (name.current && nameContainer.current) {

            if (name.current?.offsetWidth > nameContainer.current?.clientWidth - (2 * (window.innerWidth / 100))) {
                setMoving(true)


            }
        }
    }, [nameContainer.current, name.current])

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        if (play) {
            // audioRef.current?.play()
        } else {
            // audioRef.current?.pause()
        }
    }, [audioRef, play])
    useEffect(() => {
        const newArr: TSelected = {}
        const newHistoryIndex: { [key: string]: number } = {}
        const newPrevHistoryIndex: { [key: string]: number } = {}
        const newSteps: { [key: string]: number } = {}
        data.map((month) => {
            month.days.map(day => {
                if (day.choices.length <= 1) {
                    if (day.choices[0] && day.choices[0].subChoices.length <= 1) {
                        newArr[day._id] = day.choices[0].subChoices[0]
                    } else if (day.choices[0]) {
                        newArr[day._id] = day.choices[0].subChoices
                    }
                } else {
                    newArr[day._id] = day.choices
                }
                newPrevHistoryIndex[day._id] = 0
                newHistoryIndex[day._id] = 0
                newSteps[day._id] = 0

            })

        })
        if (data.length > 1) {
            setSetting({
                customPaging: function (i: number) {
                    return (
                        <div
                            className={`P-tab`}
                            key={data[i]._id}
                        >
                            <p className={`P-meditationTab`}><img src={data[i].banner} alt={data[i].bannerName}/>
                            </p>
                            {data[i].title &&

                                <p className={`P-nameContainer moving`} ref={nameContainer}>
                                    <span className={`P-text`} ref={name}
                                          data-name={data[i].title}>{data[i].title}</span>
                                    <span className={`P-text`} ref={name}
                                          data-name={data[i].title}>{data[i].title}</span>
                                </p>}
                        </div>
                    );
                },
                arrows: false,
                dots: true,
                dotsClass: "slick-dots slick-thumb",
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                adaptiveHeight: true,

                slidesToScroll: 1
            })
        }
        setSelected(newArr)
        setHistoryIndex(newHistoryIndex)
        setPrevHistoryIndex(newPrevHistoryIndex)
        setStep(newSteps)
    }, [data])
    return (
        <div className={`G-container P-meditationPage`}>
            <div className={`P-meditationContainer`}>
                 <span className={`P-backArrowContainer`}>

        <NavLink to={RoutesName.Yoga_page} className={`G-align-center`}>
            <img loading={"lazy"}  src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/backArrowYellow.png"} alt="backArrow"/>
            <span>{t("go-back-text")}</span>
        </NavLink>
        </span>

                <Slider {...settings} >
                    {data.map((item, index) => {
                        return (<div style={{height: "100%"}} key={item._id}>
                            {item.days.map((day, dayIndex) => {
                                return (
                                    <div key={day._id} className={`P-day`}>
                                        <h3 className={`P-dayTitle`}>{day.title}</h3>
                                        {day.audios.length >= 1 && <div className={`P-audios`}>
                                            {day.audiosTitle && <h3 className={`P-audioTitle`}>{day.audiosTitle}</h3>}
                                            <div className={`P-audiosContainer`}>
                                                {day.audios.map((audio, audioIndex) => {
                                                    return (
                                                        <div
                                                            className={`P-audio`}
                                                            key={audio._id}
                                                            /*onClick={() => {
                                                                setSelected({...selected, [day._id]: audio})
                                                            }}*/>
                                                            <ExclusivePlayer
                                                                index={audioIndex}
                                                                bought={true}
                                                                sample={{
                                                                    title: audio.title,
                                                                    _id: audio._id,
                                                                    subtitle: "",
                                                                    amount: "",
                                                                    order: 1,
                                                                    previewSound: audio.audio,
                                                                    previewSoundName: audio.audioName,
                                                                    downloadableSound: "",
                                                                    downloadableSoundName: "",
                                                                }}
                                                                onBasket={() => {
                                                                    ""
                                                                }}
                                                                buyButton={false}

                                                                isBasket={false}/>
                                                        </div>)
                                                })}

                                            </div>
                                        </div>}
                                        <section className={`P-selectedAudio`} key={day._id + day.title}>
                                            {selected[day._id] && Array.isArray(selected[day._id]) &&
                                                <div><h3 className={`P-choiceTitle`}
                                                >{day.title}</h3></div>}
                                            {step && step[day._id as keyof typeof step] !== 0 &&
                                                <button onClick={() => {
                                                    const newSelected = {...selected}

                                                    const newSteps = {...step}

                                                    if (selected[day._id] && Array.isArray(selected[day._id])) {
                                                        // if()
                                                        newSelected[day._id] = day.choices
                                                    } else {

                                                        if (historyIndex) {
                                                            console.log(historyIndex[day._id])
                                                            newSelected[day._id] = day.choices[historyIndex[day._id] as unknown as number].subChoices.length>1?day.choices[historyIndex[day._id] as unknown as number].subChoices:day.choices
                                                        }
                                                    }

                                                    newSteps[day._id] = step[day._id] - 1
                                                    setStep(newSteps)

                                                    setSelected(newSelected)
                                                }
                                                } className={`P-backButton`}><img src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/Futu_Knopka.png"} alt={"backArrow"}/>
                                                </button>}
                                            <section className={`P-choiceBanners`}>


                                                {selected[day._id] && Array.isArray(selected[day._id]) ? (selected[day._id] as TReadChoice[] | TReadChoices[]).map((choice, choiceIndex) => (
                                                        <div className={`P-choiceBannerContainer`} key={choice._id}
                                                             onClick={() => {
                                                                 const newSteps = {...step}
                                                                 const newHistoryIndex = {...historyIndex}
                                                                 const newPrevHistoryIndex = {...prevHistoryIndex}

                                                                 if ("subChoices" in choice) {
                                                                     if (choice.subChoices.length <= 1) {
                                                                         setSelected({
                                                                             ...selected,
                                                                             [day._id]: choice.subChoices[0]
                                                                         })
                                                                         // newPrevHistoryIndex[day._id] = choiceIndex
                                                                         if (prevHistoryIndex) {
                                                                             console.log(prevHistoryIndex[day._id])
                                                                             newHistoryIndex[day._id] = choiceIndex
                                                                         }
                                                                         // setPrevHistoryIndex(newPrevHistoryIndex)
                                                                         console.log(newHistoryIndex,"history")
                                                                         setHistoryIndex(newHistoryIndex)
                                                                     } else {
                                                                         setSelected({
                                                                             ...selected,
                                                                             [day._id]: choice.subChoices
                                                                         })
                                                                         newPrevHistoryIndex[day._id] = choiceIndex
                                                                         if (prevHistoryIndex) {
                                                                             console.log(prevHistoryIndex[day._id])
                                                                             newHistoryIndex[day._id] = prevHistoryIndex[day._id]
                                                                         }
                                                                         setPrevHistoryIndex(newPrevHistoryIndex)
                                                                         setHistoryIndex(newHistoryIndex)
                                                                     }
                                                                 } else {
                                                                     setSelected({
                                                                         ...selected,
                                                                         [day._id]: choice
                                                                     })
                                                                     newPrevHistoryIndex[day._id] = choiceIndex
                                                                     if (prevHistoryIndex) {
                                                                         console.log(prevHistoryIndex[day._id])
                                                                         newHistoryIndex[day._id] = prevHistoryIndex[day._id]
                                                                     }
                                                                     setPrevHistoryIndex(newPrevHistoryIndex)
                                                                     setHistoryIndex(newHistoryIndex)
                                                                 }
                                                                 if (step) {
                                                                     newSteps[day._id] = step[day._id] + 1
                                                                     setStep(newSteps)
                                                                 }
                                                                 //
                                                                 // console.log(choiceIndex)
                                                                 // newPrevHistoryIndex[day._id] = choiceIndex
                                                                 // if (prevHistoryIndex) {
                                                                 //     console.log(prevHistoryIndex[day._id])
                                                                 //     newHistoryIndex[day._id] = prevHistoryIndex[day._id]
                                                                 // }
                                                                 // setPrevHistoryIndex(newPrevHistoryIndex)
                                                                 // setHistoryIndex(newHistoryIndex)
                                                                 // console.log(newSteps,newHistoryIndex,newPrevHistoryIndex)
                                                             }}>

                                                            <div className={`P-choiceBanner`}>
                                                                {choice.banner &&
                                                                    <img loading={"lazy"}
                                                                         src={choice.banner}
                                                                        alt={choice.bannerName}/>}
                                                                {/* <span
                                                                    className={`icon-${(play || !currentPlaying) ? "play" : "pause"}`}/>*/}

                                                            </div>
                                                        </div>
                                                    )) :
                                                    selected[day._id] && <div className={`P-choiceBannerContainer`}>
                                                        <h3 className={`P-choiceTitle`}>{(selected[day._id] as TReadChoice).title}</h3>

                                                        <div className={`P-choiceBanner`}
                                                             onClick={() => setPlaying({...selected[day._id] as TReadChoice,monthId:item._id} )}>
                                                            {(selected[day._id] as TReadChoice).banner &&
                                                                <img loading={"lazy"}
                                                                     src={(selected[day._id] as TReadChoice).banner}
                                                                    alt={(selected[day._id] as TReadChoice).bannerName}/>}
                                                            <span
                                                                className={`icon-${(play || !currentPlaying) ? "play" : "pause"}`}
                                                            />

                                                        </div>
                                                    </div>
                                                }
                                            </section>
                                            {!Array.isArray(selected[day._id]) && selected[day._id] &&
                                                <p className={`P-descriptionText`}>{(selected[day._id] as TReadChoice).description}</p>}
                                        </section>

                                    </div>
                                )
                            })}


                        </div>)
                    })}
                </Slider>

            </div>
            {playing && <div className={`P-player`}>

                <AudioWavePlayer backPage={"#"}
                                 backClick={() => setPlaying(null)}
                                 music={{
                                     src: user?.buyed.includes(playing._id) ? playing.audioReal : playing?.audio as string,
                                     name: playing?.title as string,
                                     src2: user?.buyed.includes(playing._id) ? playing.audioReal : playing?.audio as string,
                                     type: musicPopUpEnum.musicWithTextCenter,
                                     id: playing?._id as string,
                                     text: playing.description,
                                     color: ""
                                 }} isClick={play}
                                 setIsPlay={(is) => {
                                     is ? "" : ""
                                 }}
                                 mediaElement={<audio
                                     src={playing?.audio as string} /*ref={audioRef}*//>}/>
                <div className={`P-choiceBanner fixed`}
                    // onClick={() => setPlaying(selected[day._id] as TReadChoice)}
                >
                    {playing?.banner &&
                        <img loading={"lazy"}
                             src={playing.banner}
                            alt={playing.bannerName}/>}
                    <span
                        className={`icon-${(play) ? "play" : "pause"}`}
                        onClick={() => {
                            // console.log(isPlay)
                            setMethod("reapet")
                            setPlay(!play)
                            setIsClick(play)
                            setIsPlay(play)
                            // console.log("hi")

                        }}
                    />
                    {(!!user && user.buyed.includes(playing.monthId)) || buyed.includes(playing.monthId) ?
                        null : <button onClick={() => {
                            if (!token) {
                                MySwal.fire({
                                    title: 'Warning',
                                    text: "if you continue without logging in, the purchase will not be saved after the page is reloaded",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Continue',
                                    html: <div className={`swal-html`}><p>if you continue without logging in, the
                                        purchase will not be saved
                                        after the page is reloaded</p>
                                        <button onClick={() => {
                                            MySwal.close()
                                            if (publishableKey) navigate(`?popUP=${AccountsEnum.logIn}`)
                                            setPlaying(null)
                                        }} className={`P-loginButton`}>{t("Log-in-text")}</button>
                                    </div>
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        setAmount(+playing?.price)
                                    }
                                })
                            } else {
                                setAmount(+playing?.price)
                            }
                        }} className={`P-downloadButton`}>{t("buy-for-download-text")} (${playing.price})</button>
                    }
                </div>
            </div>}
            {amount > 0 && !!playing ? <ClickOutsideBlock onClick={() => {
                setAmount(0)
            }}><PopUpContainer onClose={() => {
                setAmount(0)
            }}>
                <div><DonationPopUp amount={amount}
                                    onSuccess={() => {
                                        if (!!user) {
                                            setUser({
                                                ...user,
                                                buyed: [...user.buyed, playing?.monthId]
                                            } as IProfile & ITokenField)
                                        }


                                        // downloadFile(playing?.audioReal, playing?.audioRealName)

                                        // setUser({...user, basket: []} as IProfile & ITokenField)

                                    }}/></div>
            </PopUpContainer></ClickOutsideBlock> : ""}
        </div>)
}

export default Meditation