import TextareaAutosize from "react-textarea-autosize";
import React, {ChangeEventHandler, useContext, useState} from "react";
import {PageContentContext} from "../../../../context/pageContentContext";
import ButtonLoader from "../../../../components/buttonLoader";
import {useTranslation} from "react-i18next";
import RegulationsApi from "../../../../requests/api/regulationsApi";
import ErrorPopUp from "../../../../components/errorPopUp";
import GoBackButton from "../../../../components/goBackButton";
import {RoutesName} from "../../../../router";
import {IMonth} from "../../../../models/meditation.model";

interface IValues {
    termsAndConditions: string
    cookiesPolicy: string
    privacyPolicy: string
}

const RegulationsAdminPage = () => {
    const {t} = useTranslation()
    const {regulations, setRegulation} = useContext(PageContentContext)
    const [value, setValue] = useState<IValues>({...regulations})
    const [disabled,setDisabled]=useState(false)

    const onChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
    }

    const onSave=async ()=>{
        setDisabled(true)
        try {
            const result = await RegulationsApi.update({...value})
            ErrorPopUp({
                icon: "success",
                title: 'Success',
            })
        }catch (err){
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
        setDisabled(false)
    }
    const onCancel=()=>{
        setValue({...regulations})
    }
    return (
        <div className={`P-regulationsAdminPage G-flex-column`} style={{minHeight:"100%"}}>

            <p className={`P-title`}>{t("Regulations-text")}</p>
            <div className={"P-inputsContainer"}>
            <div className={`P-inputBox`}>
                <div className={`P-textArea`}>

                    <p className={`P-label`}>Terms And Conditions</p>
                    <TextareaAutosize className={`P-input`} value={value.termsAndConditions}
                                      onChange={onChangeHandler} name={"termsAndConditions"}></TextareaAutosize>

                </div>
            </div>
            <div className={`P-inputBox`}>
                <div className={`P-textArea`}>

                    <p className={`P-label`}>Privacy Policy</p>

                    <TextareaAutosize className={`P-input`} value={value.privacyPolicy} name={"privacyPolicy"}
                                      onChange={onChangeHandler}></TextareaAutosize>

                </div>

            </div>
            <div className={`P-inputBox`}>
                <div className={`P-textArea`}>

                    <p className={`P-label`}>Cookies Policy</p>

                    <TextareaAutosize className={`P-input`} value={value.cookiesPolicy} name={"cookiesPolicy"}
                                      onChange={onChangeHandler}></TextareaAutosize>

                </div>
            </div>
            </div>
            <div className={`P-actionsSection`} >
                <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

            </div>
        </div>
    )
}

export default RegulationsAdminPage