import React, {useState} from "react";


interface IMuteContext {
    isMute: boolean;
    setIsMute: (flag: boolean) => void
}


export const MuteContext = React.createContext<IMuteContext>({
    isMute: false,
    setIsMute: () => {
        ""
    }
})
const MuteProvider = ({children}: { children: React.ReactNode }) => {
    const [isMute, setIsMute] = useState(false)

    return (
        <MuteContext.Provider value={{
            isMute, setIsMute
        }}>
            {children}
        </MuteContext.Provider>
    )


}

export default MuteProvider