import React, {FC, MouseEventHandler, ReactElement} from 'react';
import "./style.scss"

interface IClickOutSide {
    onClick: MouseEventHandler,

    children: ReactElement
}

const ClickOutsideBlock: FC<IClickOutSide> = ({onClick, children}) => {


    return (
        <div className="P-click-outsideBlock" onClick={onClick}>{children}</div>
    )
}

export default ClickOutsideBlock;