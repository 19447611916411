import Service from "../sevice/request.service"
import {ISample, ISampleData} from "../../models/sample.model";

interface ISuccess {
    success: boolean
}

class SampleApi {
    static creat = (data: ISample,) => {
        return Service.POST <ISample, ISuccess>(`/sample`, data)
    }
    static delete = (id: string,) => {
        return Service.DELETE <null, ISuccess>(`/sample/${id}`, null)
    }
    static update = (data: Omit<ISampleData, "order">,) => {
        return Service.PATCH <Omit<ISampleData, "order">, ISuccess>(`/sample/${data._id}`, data)
    }
    static updateAll = (data: ISampleData[]) => {
        return Service.PATCH <{ data: ISampleData[] }, ISuccess>("/sample", {data})
    }

    static read = () => {
        return Service.GET<null, string[]>("/sample",)
    }
}

export default SampleApi