import "./style.scss"
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import {AuthorizationContext} from "../../context/authorizationContext";
import {NavLink} from "react-router-dom";
import {RoutesName} from "src/router";
import ClickOutside from "../click-outside";

const ProfileElement = () => {
    const {t} = useTranslation()
    const [show, setShow] = useState<boolean>(false)
    const toggle = () => {
        setShow(!show)
    }
    const {isAdmin, setToken, setIsAdmin,user} = useContext(AuthorizationContext)
    return (<div className={`P-profileElement`}>
        <ClickOutside onClickOutside={() => {
            if (show) {
                setShow(false)
            }
        }}><>
            {user?.avatar? <img loading={"lazy"} className={`P-avatar`}src={user.avatar} alt="avatar" onClick={toggle}/>:<span className={`icon-mdi_user`} onClick={toggle}/>}

            <div className={`P-profileParams ${show ? "open" : ""}`} onClick={() => {
                setShow(false)
            }}>
                {isAdmin &&
                    <NavLink to={RoutesName.Admin_page}><p><span className={`icon-pen`}/> {t("Admin-text")}</p>
                    </NavLink>}
                <NavLink to={RoutesName.Settings_Page}><p><span className={`icon-settings`}/> {t("Settings-text")}</p>
                </NavLink>
                <NavLink to={RoutesName.My_music_page}><p><span className={`icon-music`}/> {t("My-musics-text")}</p>
                </NavLink>
                <p className={`P-signOutBTN`} onClick={() => {
                    localStorage.removeItem("AUTH_TOKEN")
                    setToken("")
                    setIsAdmin(false)
                }}><span className={`icon-exit`}/> {t("Sign-out-text")}</p>
            </div>
        </>
        </ClickOutside>
    </div>)
}
export default ProfileElement