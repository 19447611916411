import {IProfile, IProfileEdit, ITokenField} from "src/models/auth.model"
import Service from "../sevice/request.service"

interface ILoginData {
    email: string,
    password: string
}
interface ISuccess {
    success: boolean
}
export interface ISignUpData extends ILoginData {
    fullName: string
}


class AuthAPI {
    static logIn = (data: ILoginData) => {
        return Service.POST <ILoginData, IProfile & ITokenField>("/auth/signin", data)
    }
    static signUp = (data: ISignUpData) => {
        return Service.POST <ISignUpData, IProfile & ITokenField>("/auth/signup", data)
    }
    static verify = (data: ISignUpData) => {
        return Service.POST <ISignUpData, ISuccess>("/auth/verify", data)
    }
    static forgot = (data: { email:string }) => {
        return Service.POST <{ email:string }, ISuccess>("/auth/forgotPassword", data)
    }
    static update = (data: IProfileEdit) => {
        return Service.PATCH <IProfileEdit, { success:boolean }>("/auth", data)
    }
    static changePassword = (data: { password:string, },id:string) => {
        return Service.PATCH <{ password:string }, { success:boolean }>(`/auth/${id}`, data)
    }

    static getProfile = () => {
        return Service.GET<null, IProfile>("/auth",)
    }
}

export default AuthAPI
