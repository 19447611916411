import "./style.scss"
import {useTranslation} from "react-i18next";
import {ReactElement} from "react";

const SignInPopUp = ({onClose, children,title}: { onClose: () => void; children: ReactElement,title?:string }) => {
    return (

        <div className={`P-authPopUp`}
        onClick={(event)=>{event.stopPropagation()}}>
            <span className={`icon-cross`} onClick={(event) => {
                onClose()
            }}/>
            {title&&<p className={`P-popUpTitle`}>{title}</p>}
            {children}
        </div>
    )
}

export default SignInPopUp