import PopUpContainer from "../popUpContainer";
import {useContext} from "react";
import {SocialIconsContext} from "../../context/socialIconsContext";
import {NavLink} from "react-router-dom";
import "./style.scss"

interface IProps {
    onClose: () => void
}

const SocialLinksPopUp = ({onClose}: IProps) => {
    const {data} = useContext(SocialIconsContext)
    return (<PopUpContainer onClose={onClose}>
        <div className={`P-socialLinksPopUp`}>
            <h3 className={`P-title`}>stream my music</h3>
            <section className={`P-iconsSection`}>
                {[...data].map((item, index) => {
                    return (<div className={`P-iconBox`} key={item.link + item.icon}><NavLink to={item.link}
                                                                                              target={"_blank"}>
                        <img
                            src={item.icon}
                            alt={item.iconName}/></NavLink>
                    </div>)
                })}
            </section>
        </div>
    </PopUpContainer>)
}

export default SocialLinksPopUp