import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {RoutesName} from "../../../../router";
import {useTranslation} from "react-i18next";

const SideBar = () => {
    const [show, setShow] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(true)
    const [portrait, setPortrait] = useState(window.innerWidth < window.innerHeight)

    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {


        window.addEventListener("resize", () => {
            setPortrait(window.innerWidth < window.innerHeight)
        })
        return () => {
            window.removeEventListener("resize", () => {
                setPortrait(window.innerWidth < window.innerHeight)
            })
        }
    }, [])
    useEffect(() => {
        setOpen(!portrait)

    }, [portrait])
    const onClick = () => {
        if (portrait) {
            setOpen(false)
        }
    }
    return (<div className={`P-sideBar ${open ? "" : "close"}`}>
            <span className={`P-sideBarIcon ${open ? "icon-menu_open" : "icon-menu"}`} onClick={() => {
                setOpen(!open)
            }}/>
            {/*<NavLink to={RoutesName.Admin_PagesContent}
                     className={`P-dropDownLabel`} onClick={(e) => {
                e.preventDefault()
                if (!show) {
                    navigate(RoutesName.Admin_HomePageContent)
                    setShow(true)
                } else {
                    setShow(false)
                }
            }}>{t("Pages-content-text")}<span className={show ? `icon-circle-up` : `icon-circle-down`}/></NavLink>*/}
            {/*<div className={`P-dropDown ${show ? "open" : ""}`}>*/}
            {/*    <NavLink to={RoutesName.Admin_HomePageContent}>{t("Home-Page-content-text")}</NavLink>*/}
            {/*    <NavLink to={RoutesName.Admin_YogaPageContent}>{t("Yoga-Page-content-text")}</NavLink>*/}
            {/*</div>*/}
            {/*<NavLink to={RoutesName.Admin_HomePageMusic}>{t("Home-page-music-text")}</NavLink>*/}
            <NavLink to={RoutesName.Personal_Information} onClick={onClick}>{t("Personal-information-text")}</NavLink>
        </div>
    )
}

export default SideBar