import React, {useContext, useRef, useState} from "react";
import rain from "../../assets/sounds/rain.mp3";
import {MuteContext} from "../muteContext";
import {IYogaPageMusic} from "../../models/content.model";
import YogaMusicApi from "../../requests/api/yogaMusicApi";


interface IMusicContextYoga {
    setIsPlayYoga: (flag: boolean) => void
    setPlay: (flag: boolean) => void
    data: IYogaPageMusic[]
    setData: (data: IYogaPageMusic[]) => void
    getMusics: () => Promise<void>
}


export const MusicContextYoga = React.createContext<IMusicContextYoga>({
    setIsPlayYoga: () => {
        ""
    },
    setPlay: () => {
        ""
    },
    getMusics: async () => {
        try {
            const result = await YogaMusicApi.read()
        } catch (err) {
            console.log(err)
        }
    },
    data: [{
        title: "",
        subtitle: "",
        src: "",
        src2: "",
        originalName: "",
        originalName2: "",
        order: 0
    }],
    setData: () => {
        ""
    }
})
const MusicProviderYoga = ({children}: { children: React.ReactNode }) => {
    const audio = useRef<HTMLAudioElement | null>(null)
    const {isMute} = useContext(MuteContext)
    const [isPlayYoga, setIsPlayYoga] = useState(false)
    const [play, setPlay] = useState(false)
    const [data, setData] = useState<IYogaPageMusic[]>([{
        title: "",
        subtitle: "",
        src: "",
        src2: "",
        originalName: "",
        originalName2: "",
        order: 0
    }])

    const getMusics: () => Promise<void> = async () => {
        try {
            const result = await YogaMusicApi.read()

            setData([...result.data].sort((a, b) => a.order - b.order))
        } catch (err) {
            console.log(err)
        }
    }
    /*useEffect(() => {
        getMusics()
    }, [])
*/

    return (
        <MusicContextYoga.Provider value={{
            setIsPlayYoga, setPlay, data, setData, getMusics
        }}>
            <audio ref={audio} controls={false}>
                <source src={rain}/>
            </audio>
            {children}
        </MusicContextYoga.Provider>
    )


}

export default MusicProviderYoga