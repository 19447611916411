import Service from "../sevice/request.service"
import {IMonth, TReadMonth} from "../../models/meditation.model";

interface ISuccess {
    success: boolean
}

class MeditationAPI {
    static creat = (data: IMonth) => {
        return Service.POST <IMonth, TReadMonth>("/meditation", data)
    }

    static read = () => {
        return Service.GET<null, TReadMonth>("/meditation",)
    }
 static readById = (id:string) => {
        return Service.GET<null, TReadMonth>("/meditation/"+id,)
    }

    static update = (id: string, data: IMonth) => {
        return Service.PATCH<IMonth, ISuccess>("/meditation/" + id, data)
    }

    static updateAll = (data:IMonth[]) => {
        return Service.PATCH<IMonth[], TReadMonth>("/meditation",data)
    }

    static delete = (id:string) => {
        return Service.DELETE<null, ISuccess>("/meditation/"+id,null)
    }
}

export default MeditationAPI