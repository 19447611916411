import {IRoute} from "../models/router.model";
import {Pages} from "src/pages";

export enum RoutesName {
    Home_page = "/",
    Yoga_page = "/FUTUYOGA",
    Live_page = "/FUTULIVE",
    Exclusive_page = "/SAMPLES",
    My_music_page = "/MY_MUSIC",
    Settings_Page = "/SETTINGS",
    Personal_Information = "Personal_Information",
    Admin_page = "/ADMIN",
    Admin_PagesContent = "Pages_contents",
    Admin_HomePageContent = "Pages_contents/Home",
    Admin_YogaPageContent = "Pages_contents/Yoga",
    Admin_HomePageMusic = "Home_music",
    Admin_HomePageEachMusic = "Home_music/:id",
    Admin_SampleMusic = "Sample",
    Admin_MeditationAdminPage = "Meditation",
    Admin_EachMeditationAdminPage = "Meditation/:id",
    Admin_EachMeditationDayAdminPage = "Meditation/:id/:dayId",
    Admin_EachMeditationChoiceAdminPage = "Meditation/:id/:dayId/:choiceId",
    Admin_EachMeditationSubChoiceAdminPage = "Meditation/:id/:dayId/:choiceId/:subChoiceId",
    Admin_SampleEachMusic = "Sample/:id",
    Admin_YogaPageMusic = "Yoga_music",
    Admin_EachYogaPageMusic = "Yoga_music/:id",
    Admin_SocialMedias = "SocialMediasPage",
    Admin_EachSocialMedia = "SocialMediasPage/:id",
    Admin_PaymentKeys = "PaymentKeys",
    Admin_Regulation = "Regulation",
    Meditation = "Meditation"

}


export const privateRoutes: IRoute[] = [
    {
        path: RoutesName.My_music_page,
        exact: true,
        component: Pages.MyMusicPage,
        permission: []
    },
    {
        path: RoutesName.Settings_Page,
        exact: true,
        component: Pages.SettingsPage,
        permission: []
    },
]
export const settingSubRoutes: IRoute[] = [
    {
        path: RoutesName.Personal_Information,
        exact: true,
        component: Pages.PersonalInformation,
        permission: []
    },
]
export const adminRoutes: IRoute[] = [
    {
        path: RoutesName.Admin_page,
        exact: true,
        component: Pages.AdminPage,
        permission: []
    },

]
export const adminSubRoutes: IRoute[] = [
    {
        path: RoutesName.Admin_HomePageContent,
        exact: true,
        component: Pages.HomePageContentPage,
        permission: []
    }, {
        path: RoutesName.Admin_PagesContent,
        exact: true,
        component: Pages.HomePageContentPage,
        permission: []
    }, {
        path: RoutesName.Admin_YogaPageContent,
        exact: true,
        component: Pages.YogaPageContentPage,
        permission: []
    },
    {
        path: RoutesName.Admin_HomePageMusic,
        exact: true,
        component: Pages.HomePageMusicPage,
        permission: []
    },
    {
        path: RoutesName.Admin_SampleMusic,
        exact: true,
        component: Pages.SampleMusicPage,
        permission: []
    },
    {
        path: RoutesName.Admin_SampleEachMusic,
        exact: true,
        component: Pages.SampleEachMusicPage,
        permission: []
    },
    {
        path: RoutesName.Admin_YogaPageMusic,
        exact: true,
        component: Pages.YogaPageMusicPage,
        permission: []
    },
    {
        path: RoutesName.Admin_HomePageEachMusic,
        exact: true,
        component: Pages.HomeEachMusicPage,
        permission: []
    },
    {
        path: RoutesName.Admin_SocialMedias,
        exact: true,
        component: Pages.SocialMediasPage,
        permission: []
    },
    {
        path: RoutesName.Admin_EachSocialMedia,
        exact: true,
        component: Pages.EachSocialMedia,
        permission: []
    }, {
        path: RoutesName.Admin_PaymentKeys,
        exact: true,
        component: Pages.PaymentKeys,
        permission: []
    }, {
        path: RoutesName.Admin_MeditationAdminPage,
        exact: true,
        component: Pages.MeditationAdminPage,
        permission: []
    }, {
        path: RoutesName.Admin_EachMeditationAdminPage,
        exact: true,
        component: Pages.EachMeditationAdminPage,
        permission: []
    }, {
        path: RoutesName.Admin_EachMeditationDayAdminPage,
        exact: true,
        component: Pages.EachMeditationDayAdminPage,
        permission: []
    }, {
        path: RoutesName.Admin_EachMeditationChoiceAdminPage,
        exact: true,
        component: Pages.EachMeditationChoiceAdminPage,
        permission: []
    }, {
        path: RoutesName.Admin_EachMeditationSubChoiceAdminPage,
        exact: true,
        component: Pages.EachMeditationSubChoiceAdminPage,
        permission: []
    }, {
        path: RoutesName.Admin_EachYogaPageMusic,
        exact: true,
        component: Pages.EachYogaMusic,
        permission: []
    },{
        path: RoutesName.Admin_Regulation,
        exact: true,
        component: Pages.RegulationsAdminPage,
        permission: []
    }
]


export const publicRoutes: IRoute[] = [
    {
        path: RoutesName.Home_page,
        exact: true,
        component: Pages.Home,
        permission: []
    },
    {
        path: RoutesName.Yoga_page,
        exact: true,
        component: Pages.YogaPage,
        permission: []
    }, {
        path: RoutesName.Meditation,
        exact: true,
        component: Pages.Meditation,
        permission: []
    },
    /* {
         path: RoutesName.Live_page,
         exact: true,
         component: Pages.LivePage,
         permission: []
     },*/

]

export const exclusivePage = {
    path: RoutesName.Exclusive_page,
    exact: true,
    component: Pages.ExclusivePage,
    permission: []
}