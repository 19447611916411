import ExclusivePlayer from "../../components/exclusivePlayer"
import "./style.scss"
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "../../router";
import {Fragment, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ISampleData} from "../../models/sample.model";
import SampleApi from "../../requests/api/sampleApi";
import {decodeToken} from "react-jwt";
import {Jwt} from "jsonwebtoken";
import {AuthorizationContext} from "../../context/authorizationContext";
import {IProfile, ITokenField} from "../../models/auth.model";
import DonationPopUp from "../../components/donationPopUp";
import PopUpContainer from "../../components/popUpContainer";
import ClickOutsideBlock from "../../components/click-outsideBlock";
import useDownloadFile from "../../hooks/useDownloadFile";

const ExclusivePage = () => {
    const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const [data, setData] = useState<ISampleData[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const {user, setUser} = useContext(AuthorizationContext)
    const [amount, setAmount] = useState(0)
    const downloadFile=useDownloadFile()
    const [basket, setBasket] = useState<ISampleData[]>(data.filter((sample) => {
        return (user?.basket.find(i => i === sample._id))
    }))
    const [ids, setIds] = useState<string[]>([])
    const {t} = useTranslation()
    const onBasket = (item: string) => {
        const index: number = user?.basket.findIndex((i) => i === item) as number
        if (index >= 0) {
            const newBasket = [...user?.basket as string[]]
            newBasket.splice(index, 1)
            setUser({...user, basket: [...newBasket]} as IProfile & ITokenField)
        } else {
            setUser({...user, basket: [...user?.basket as string[], item]} as IProfile & ITokenField)

        }

    }

    useEffect(() => {
        (async () => {
            const result = await SampleApi.read()
            const newData: ISampleData[] = []
            result.data.map((item: string) => {
                const token = decodeToken(item) as Jwt & { _doc: ISampleData }
                newData.push(token._doc)
            })
            setData([...newData])

        })()
    }, [])
    useEffect(() => {
        const newData = data.filter((sample) => {
            return (user?.basket.find(i => i === sample._id))
        })
        setBasket([...newData])
    }, [user?.basket,data])
    return (
        <div className={`P-exclusivePage`}>
            <main className={`G-container`}>
                <GoBackButton backPage={RoutesName.Home_page}/>
                <section className={`P-pageContent`}>
                    <section className={`P-playersSection`}>
                        {data.map((item: ISampleData, index) => {
                            return (<div className={`P-player`} key={item + "" + index}>
                                <ExclusivePlayer
                                    index={index}
                                    bought={user?.buyed.includes(item._id) as boolean}
                                    sample={item}
                                    onBasket={() => onBasket(item._id)}
                                    isBasket={basket.findIndex((i) => i._id === item._id) >= 0}
                                    buyButton={true}
                                    onBuyButton={() => {
                                        setAmount(+item.amount)
                                        setIds([item._id])
                                    }}
                                /></div>)
                        })
                        }</section>
                    {/*   <section className={`P-actionsSection`}>
                        <button className={`P-exclusiveButton yellow`}>{t("download-album-text")}</button>
                        <button className={`P-exclusiveButton`}>{t("Subscribe-text")}</button>

                    </section>*/}
                    <div className={`P-basketSection`} onMouseLeave={() => {
                        setIsOpen(false)
                    }}>
                        <div className={`P-content ${isOpen && basket.length > 0 ? "active" : ""}`}>
                            <section className={`P-playersSection`}>
                                {basket.map((item, index) => {
                                    return (<Fragment key={item + "" + index}><ExclusivePlayer
                                        index={index}
                                        bought={false}
                                        onBasket={() => onBasket(item._id)}
                                        inBasket={true}
                                        buyButton={true}
                                        sample={item}
                                        onBuyButton={() => {
                                            setAmount(+item.amount)
                                            setIds([item._id])
                                        }}
                                        isBasket={basket.findIndex((i) => i._id === item._id) >= 0}
                                    /></Fragment>)
                                })
                                }</section>
                            <button className={`P-bayAll`} onClick={() => {
                                const allAmount = basket.reduce((a, b) => a + +b.amount, 0)
                                setAmount(allAmount)
                                setIsOpen(false)

                            }}>{t("buy-all-text")}</button>
                        </div>
                        <p className={`icon-shopping-cart`} onClick={() => setIsOpen(!isOpen)}>
                            {basket.length > 0 &&
                                <span className={`P-count`}>{basket.length}</span>}
                        </p>
                    </div>
                </section>

            </main>
            {amount > 0 && !!user ? <ClickOutsideBlock onClick={() => {
                setAmount(0)
            }}><PopUpContainer onClose={() => {
                setAmount(0)
            }}>
                <div><DonationPopUp amount={amount}
                                    onSuccess={() => {
                                        setUser({
                                            ...user,
                                            buyed: [...user.buyed, ...ids]
                                        } as IProfile & ITokenField)
                                        basket.map((item) => {
                                            downloadFile(item.downloadableSound, item.downloadableSoundName)
                                        })
                                        setUser({...user, basket: []} as IProfile & ITokenField)

                                    }}/></div>
            </PopUpContainer></ClickOutsideBlock> : ""}
        </div>
    )
}

export default ExclusivePage