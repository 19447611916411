import {useParams} from "react-router";
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "src/router";
import {ChangeEventHandler, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import FutuInput from "src/components/futuInput";
import UploadApi from "../../../../requests/api/uploadApi";
import ErrorPopUp from "../../../../components/errorPopUp";
import UploadCircleImageComponent from "../../../../components/upload-circle-image-component";
import {SocialIconsContext} from "../../../../context/socialIconsContext";
import useValidation from "../../../../hooks/useValidation";
import SocialIconsApi from "../../../../requests/api/socialIconsApi";
import {ISocialIconsData} from "../../../../models/content.model";
import ButtonLoader from "../../../../components/buttonLoader";

interface IValue {
    icon: string,
    iconName: string,
    link: string,
}

interface IError {
    icon: boolean,
    link: boolean,

}

const EachSocialMedia = () => {
    const {id} = useParams()
    const {data, setData} = useContext(SocialIconsContext)
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentMedia, setCurrentMedia] = useState(data.find(item => item._id === id))
    const {isEmpty} = useValidation()
    const [values, setValues] = useState<IValue>({
        icon: currentMedia?.icon ?? "",
        iconName: currentMedia?.iconName ?? "",
        link: currentMedia?.link ?? ""
    })
    const [error, setError] = useState<IError>({
        icon: false,
        link: false
    })
    const uploadFile = async (file: File | undefined,) => {
        setIsLoading(true)
        if (file) {
            try {
                const result = await UploadApi.send(file)
                setValues({
                    ...values,
                    icon: result.data.src,
                    iconName: result.data.name
                })
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }

    }
    const validation = () => {
        let isValid = true
        const errors = {...error}
        if (isEmpty(values.icon)) {
            isValid = false
            errors.icon = true
        }
        if (isEmpty(values.link)) {
            isValid = false
            errors.link = true
        }
        setError({...errors})
        return isValid
    }
    const onSave = async () => {


        if (validation()) {
            setDisabled(true)
            if (currentMedia) {
                try {
                    const result = await SocialIconsApi.update({...values, id: id as string})
                    if (result.data) {
                        const newData: ISocialIconsData[] = [...data]
                        newData[newData.findIndex(item => item._id === id)] = {
                            ...newData[newData.findIndex(item => item._id === id)],
                            ...values
                        }
                        setData([...newData])
                        ErrorPopUp({
                            icon: "success",
                            title: 'Succeeded',
                        })
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }
                } catch (err) {
                    console.log(err)
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } else {
                try {
                    const result = await SocialIconsApi.creat({
                        ...values,
                        order: !!data[data.length - 1] ? (data[data.length - 1].order as number) + 1 : 0
                    })
                    if (result.data) {
                        const newData: ISocialIconsData[] = [...data]
                        newData.push({...result.data})
                        setData([...newData])
                        ErrorPopUp({
                            icon: "success",
                            title: 'Succeeded',
                        })
                        onCancel()
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }
                } catch (err) {
                    console.log(err)
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            }
            setDisabled(false)

        }
    }
    const onCancel = () => {
        setValues({
            icon: currentMedia?.icon ?? "",
            iconName: currentMedia?.iconName ?? "",
            link: currentMedia?.link ?? ""
        })
    }

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    return (
        <div className={`P-homeEachMusicPage P-pageContent  `}>
            <GoBackButton backPage={`${RoutesName.Admin_page}/${RoutesName.Admin_SocialMedias}` as RoutesName}/>

            <div className={`P-musicContainer`}>
                <p className={`P-title`}>{currentMedia ? "" : "Add Music"}</p>
                <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                    <div className={`P-inputBox G-flex`}>
                        {/* <label className={`P-circleInput`} style={{backgroundColor: values.color}}>
                            <input value={values.color} onChange={onChangeHandler} type={"color"}
                                   name={"color"}
                            /></label>*/}
                        <UploadCircleImageComponent getFile={uploadFile} src={values?.icon ? values.icon : ""}
                                                    isLoading={isLoading}/>
                    </div>

                    <div className={`P-inputBox`}>
                        <FutuInput value={values.link} onChange={onChangeHandler} name={"link"}
                                   label={"Link"}/>

                    </div>

                </div>


            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

            </div>

        </div>
    )
}
export default EachSocialMedia