import React, {FC, MouseEventHandler, ReactElement} from "react";
import "./style.scss"
import {DefaultTFuncReturn} from "i18next";

interface IButton {
    type?: "button" | "submit" | "reset" | undefined
    className: "primary" | "secondary"
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean,
    children: ReactElement | string | Element | DefaultTFuncReturn
    id?: string
}


const ButtonLoader: FC<IButton> = ({type = "button", className, onClick, disabled = false, children, id}) => {
    return (<button type={type} id={id} className={`P-buttonLoader P-${className}BTN  ${disabled ? "disabled" : ""}`}
                    onClick={onClick}
                    disabled={disabled}>
        {disabled ? (<p className={`G-loaderContainer`}><span className={`icon-loader G-loader`}>Loading...</span>
        </p>) as ReactElement : children as ReactElement}
    </button>)
}
export default ButtonLoader