import {IHomeMusic} from "src/models/homePageMusic.model";
import {NavLink} from "react-router-dom";
import React, {useContext} from "react";
import HomeMusicApi from "../../../../../../requests/api/homeMusicApi";
import {MusicContext} from "../../../../../../context/musicPlayerContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ErrorPopUp from "../../../../../../components/errorPopUp";

const MySwal = withReactContent(Swal)

const AudioCircle = ({music}: { music: IHomeMusic }) => {
    const {allHomeMusicData, setAllHomeMusicData} = useContext(MusicContext)

    const onDelete = async (event: MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await HomeMusicApi.delete(music.id)
                    if (result.status === 200) {
                        const newData = [...allHomeMusicData]
                        newData.splice(newData.findIndex(item => item.id === music.id), 1)
                        setAllHomeMusicData([...newData])
                        MySwal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }

                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }
    return (<div className={`P-audioCircle`} draggable={true}>
        <NavLink
            to={!!music.id ? `${music.id}` : ""}
            draggable={true}
        >
            <div draggable={true}>{!!music.fit &&
                <span className={` P-fit P-text yellow `}>{music.fit}</span>}

                <p className={`P-circle ${!music.id ? "glitch" : ""}`} style={{backgroundColor: music.color}}><span
                    className={`P-delete icon-bin`} onClick={(event) => onDelete(event as unknown as MouseEvent)}/></p>


                <div className={`P-nameContainer`}>
                    <p className={`P-text`}>{music.name}</p>

                </div>
            </div>
        </NavLink>
    </div>)
}

export default AudioCircle