import Service from "../sevice/request.service"

interface ISuccess {
    success: boolean
}

export interface IRegulation {
    termsAndConditions: string,
    cookiesPolicy: string,
    privacyPolicy:string
}

class RegulationsApi {

    static update = (data: IRegulation,) => {
        return Service.PATCH <IRegulation, ISuccess>(`/regulations`, data)
    }

    static read = () => {
        return Service.GET<null, IRegulation>("/regulations",)
    }

}

export default RegulationsApi