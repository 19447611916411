import {useTranslation} from "react-i18next";
import FutuInput from "src/components/futuInput";
import {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {AuthorizationContext} from "../../../../context/authorizationContext";
import {IProfileEdit} from "../../../../models/auth.model";
import UploadImageComponent from "src/components/upload-image-component";
import UploadApi from "../../../../requests/api/uploadApi";
import AuthApi from "../../../../requests/api/authApi";
import ErrorPopUp from "src/components/errorPopUp";
import ButtonLoader from "../../../../components/buttonLoader";

interface IErrors {
    fullName: boolean,
    email: boolean,
    avatar: boolean,
    avatarName: boolean
}

const PersonalInformation = () => {
    const {t} = useTranslation()
    const {user, setUser} = useContext(AuthorizationContext)
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState<IProfileEdit>({
        fullName: user?.fullName ?? "",
        email: user?.email ?? "",
        avatar: user?.avatar ?? ""
    })
    const [error, setError] = useState<IErrors>({
        fullName: false,
        email: false,
        avatar: false,
        avatarName: false
    })
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    useEffect(() => {
        if (!!user)
            setValues({fullName: user.fullName ?? "", email: user.email ?? "", avatar: user.avatar ?? ""})
    }, [user])
    const uploadFile = async (file: File,) => {
        try {
            setIsLoading(true)
            const result = await UploadApi.send(file)
            setValues({
                ...values,
                avatar: result.data.src,
                avatarName: result.data.name
            })
            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    const onSave = async () => {
        setDisabled(true)
        try {
            const result = await AuthApi.update({...values,email:values.email.toLowerCase()})
            if (result.data.success && !!user) {
                setUser({
                    ...user,
                    avatar: values.avatar,
                    avatarName: values.avatarName,
                    fullName: values.fullName,
                    email: values.email.toLowerCase()
                })
                ErrorPopUp({
                    icon: "success",
                    title: 'Successed',
                })
            }
        } catch (err) {
            console.log(err)
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
        setDisabled(false)
    }
    const onCancel = () => {
        setValues({fullName: user?.fullName ?? "", email: user?.email ?? "", avatar: user?.avatar ?? ""})
    }


    return (<div className={`P-pageContent`}>
            <p className={`P-title`}>{t("Settings-text")}</p>
            <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                <div className={`P-inputBox G-justify-center`}>
                    <UploadImageComponent getFile={(file) => {
                        if (file) uploadFile(file)
                    }} accept={"image/png, image/jpeg, image/jpg"} src={values.avatar}
                                          isLoading={isLoading}></UploadImageComponent>
                </div>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.fullName} onChange={onChangeHandler} name={"fullName"}
                               label={"fullName"}/>
                    <FutuInput value={values.email} onChange={onChangeHandler} name={"email"}
                               label={"email"}/>
                </div>
            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

            </div>
        </div>
    )
}

export default PersonalInformation