import {loadStripe, Stripe} from '@stripe/stripe-js';
import {Elements} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import CheckoutForm from "./componenets/checkoutForm";
import "./style.scss"
import {decodeToken} from "react-jwt";
import PaymentApi from "../../requests/api/paymentApi";

const appearance = {
    rules: {
        ".Tab": {
            padding: " 4vw 8vw"
        },
        ".Input:focus": {
            color: "#C6F91F",
            boxShadow: "none",
            border: ".2vw solid #C6F91F"
        },
        ".Tab--selected,.Tab--selected:focus": {
            boxShadow: "none",
        },
        ".Input": {
            borderWidth: ".2vw"
        }
    },
    variables: {
        colorBackground: "#5A108F",
        colorTextSecondary: "#B429F9",
        colorPrimary: "#C6F91F",
        borderRadius: "10vw",
        colorText: "#B429F9",
        colorDanger: "#ff1818",
        colorTextPlaceholder: "#B429F9"
    }
}
const DonationPopUp = ({amount, onSuccess}: { amount: number, onSuccess?: () => void }) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const [clientSecret, setClientSecret] = useState("")

    useEffect(() => {
        (async () => {
            try {
                const result = await PaymentApi.config()
                const resultCreat = await PaymentApi.creat({amount: amount * 100})
                const publishableKey = (decodeToken(result.data.publishableKey) as { key: string }).key
                setStripePromise(loadStripe(`${publishableKey}`))
                setClientSecret((decodeToken(resultCreat.data.clientSecret) as { key: string }).key)
            } catch (err) {
                console.log(err)
            }

        })()
    }, [])


    return (
        <>
            {stripePromise && clientSecret && (<Elements stripe={stripePromise} options={{
                clientSecret, appearance
            }}>
                <CheckoutForm onSuccess={onSuccess}/>

            </Elements>)}
        </>
    )
}

export default DonationPopUp