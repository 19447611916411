import {useTranslation} from "react-i18next";
import FutuInput from "../../../../components/futuInput";
import {ChangeEventHandler, useEffect, useState} from "react";
import ErrorPopUp from "../../../../components/errorPopUp";
import PaymentApi from "../../../../requests/api/paymentApi";
import {decodeToken} from "react-jwt";
import ButtonLoader from "../../../../components/buttonLoader";

interface IValues {
    publishableKey: string
    secretKey: string
}

interface IShow {
    publishableKey: boolean
    secretKey: boolean
}

const PaymentKeys = () => {
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [values, setValues] = useState<IValues>({
        publishableKey: "",
        secretKey: ""
    })
    const [newValues, setNewValues] = useState<IValues>({
        publishableKey: values.publishableKey ?? "",
        secretKey: values.secretKey ?? ""
    })
    const [show, setShow] = useState<IShow>({
        publishableKey: false,
        secretKey: false
    })
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewValues({...values, [event.target.name]: event.target.value})

    }


    const getKeys = async () => {
        try {
            const result = await PaymentApi.read()

            setValues({
                    publishableKey: (decodeToken(result.data[0].publishableKey) as { key: string }).key,
                    secretKey: (decodeToken(result.data[0].secretKey) as { key: string }).key
                }
            )
            setNewValues({
                publishableKey: (decodeToken(result.data[0].publishableKey) as { key: string }).key,
                secretKey: (decodeToken(result.data[0].secretKey) as { key: string }).key
            })

        } catch
            (err) {
            console.log(err)
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
    }
    useEffect(() => {
        getKeys()
    }, [])
    const onCancel = () => {
        setNewValues({...values})

    }
    const onSave = async () => {
        setDisabled(true)
        try {
            const result = await PaymentApi.update({
                ...newValues
            })
            ErrorPopUp({
                icon: "success",
                title: 'Succeed',

            })
            setValues({...newValues})
        } catch (err) {
            console.log(err)
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
        setDisabled(false)

    }
    return (<div className={`P-pageContent`}>
        <p className={`P-title`}>{t("Payment-keys-text")}</p>
        <div className={`P-inputsContainer G-flex G-flex-wrap`}>
            <div className={`P-inputBox`}>
                <FutuInput value={newValues.publishableKey} onChange={onChangeHandler} name={"publishableKey"}
                           label={"Publishable KEY"} type={"password"}
                />
            </div>
            <div className={`P-inputBox`}>
                <FutuInput value={newValues.secretKey} onChange={onChangeHandler} name={"secretKey"}
                           label={"Secret KEY"} type={"password"}
                />
            </div>
        </div>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave}
                          disabled={disabled}>{t("save-text") as string}</ButtonLoader>
            <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text") as string}</ButtonLoader>

        </div>
    </div>)
}

export default PaymentKeys