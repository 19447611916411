import {useTranslation} from "react-i18next";
import FutuInput from "src/components/futuInput";
import {ChangeEvent, ChangeEventHandler, useContext, useEffect, useState} from "react";
import {IYogaPage} from "../../../../models/content.model";
import {PageContentContext} from "../../../../context/pageContentContext";
import ErrorPopUp from "../../../../components/errorPopUp";
import ContentApi from "../../../../requests/api/contentApi";
import ButtonLoader from "../../../../components/buttonLoader";
import UploadCircleImageComponent from "../../../../components/upload-circle-image-component";
import UploadApi from "../../../../requests/api/uploadApi";

interface IErrors {
    subtitle: boolean,
    buttons: {
        futuHome: {
            description: boolean
        },
        futuLive: {
            description: boolean
        },
        meditation: {
            bannerName: boolean,
            banner: boolean,
            title: boolean,
        }
    }
}

const YogaPageContentPage = () => {
    const {t} = useTranslation()
    const {data, setData} = useContext(PageContentContext)

    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState<IYogaPage>({
        subtitle: "",
        buttons: {
            futuHome: {
                description: ""
            },
            futuLive: {
                description: ""
            },
            meditation: {
                bannerName: "",
                banner: "",
                title: "",
            }
        }
    })
    const [error, setError] = useState<IErrors>({
        subtitle: false,
        buttons: {
            futuHome: {
                description: false
            },
            futuLive: {
                description: false
            },
            meditation: {
                bannerName: false,
                banner: false,
                title: false,
            }
        }
    })
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }
    const onChangeButton = (event: ChangeEvent<HTMLInputElement>, path: string) => {
        setValues({...values, buttons: {...values.buttons, [path]: {[event.target.name]: event.target.value}}})
        setError({...error, buttons: {...error.buttons, [path]: {[event.target.name]: event.target.value}}})
    }
    useEffect(() => {
        if (!!data)
            setValues({...data.yogaPage})
    }, [data])
    const uploadFile = async (file: File | undefined,) => {

        if (file) {
            setIsLoading(true)
            try {
                const result = await UploadApi.send(file)
                setValues({
                    ...values, buttons: {
                        ...values.buttons,
                        meditation: {
                            ...values.buttons.meditation,
                            banner: result.data.src,
                            bannerName: result.data.name
                        }
                    }

                })
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }

    }
    const onSave = async () => {
        setDisabled(true)
        try {
            const result = await ContentApi.update({...data, yogaPage: values})
            if (result.data.success) {
                setData({...data, yogaPage: values})

                ErrorPopUp({
                    icon: "success",
                    title: 'Succeeded',
                })
            }
        } catch (err) {
            console.log(err)
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
        setDisabled(false)
    }
    const onCancel = () => {
        setValues({...data.yogaPage})
    }
    return (<div className={`P-pageContent`}>
            <p className={`P-title`}>{t("Yoga-Page-content-text")}</p>
            <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                {/*<div className={`P-inputBox`}>
                    <FutuInput value={values.title} onChange={onChangeHandler} name={"title"} label={"Title"}/>
                </div>*/}
                <p className={`P-subTitle`}>{t("Meditation-button-title-and-banner-text")}</p>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.meditation.title} onChange={(event) => {
                        setValues({
                            ...values,
                            buttons: {
                                ...values.buttons,
                                meditation: {...values.buttons.meditation, title: event.target.value}
                            }
                        })
                    }} name={"title"}
                               label={"title"}/>
                    <UploadCircleImageComponent getFile={uploadFile} src={values.buttons.meditation.banner}
                                                isLoading={isLoading}/>
                </div>

                <p className={`P-subTitle`}>{t("Page-subtitle-texts-text")}</p>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.subtitle} onChange={onChangeHandler} name={"subtitle"}
                               label={"subtitle"}/>
                </div>

                <p className={`P-subTitle`}>{t("Futu-text")}{t("Home-text")} {t("button-text-text")}</p>
                {/*<div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuYoga.title}
                               onChange={(event) => onChangeButton(event, "futuYoga")}
                               name={"title"}
                               label={"title"}/>

                </div>*/}
                <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuHome.description}
                               onChange={(event) => onChangeButton(event, "futuHome")} name={"description"}
                               label={"description"}/>
                </div>
                <p className={`P-subTitle`}>{t("Futu-text")}{t("Live-text")} {t("button-text-text")}</p>
                {/* <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuLive.title}
                               onChange={(event) => onChangeButton(event, "futuLive")}
                               name={"title"}
                               label={"title"}/>

                </div>*/}
                <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuLive.description}
                               onChange={(event) => onChangeButton(event, "futuLive")} name={"description"}
                               label={"description"}/>
                </div>
            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

            </div>
        </div>
    )
}

export default YogaPageContentPage