import React, {Dispatch, ReactElement, SetStateAction, useState} from "react";
import "./style.scss"
import AudioPopUp from "./components/AudioPopUp";
import WavePlayer from "./components/wavePlayer";
import {RoutesName} from "../../router";
import {IHomeMusic} from "../../models/homePageMusic.model";

interface IAudioWavePlayerProps {
    music: IHomeMusic,
    isClick?: boolean,
    isPlay?: boolean,
    setIsPlay?: Dispatch<SetStateAction<boolean>>,
    backPage: RoutesName|"#",
    mediaElement: ReactElement
    backClick?:()=>void
}

const AudioWavePlayer = ({music, isPlay, isClick, setIsPlay, backPage, mediaElement,backClick}: IAudioWavePlayerProps) => {
    // const [isFirstTime, setIsFirstTime] = useState(true)
    // const containerRef = useRef()
    // const sources = music.src.split("_")
    // const [src, setSrc] = useState(sources[0])
    // const [audioUrl, setAudioUrl] = useState(sources[0])

    const [musicIndex, setMusicIndex] = useState(1)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState<undefined | number>(undefined)

    // const swipeMusic = useCallback(() => {
    //     if (duration && !!setIsPlay) {
    //         if (sources.length > 1 && currentTime >= duration - .0001) {
    //             if (musicIndex <= sources.length) {
    //                 console.log(src)
    //                 setSrc(sources[musicIndex])
    //                 setMusicIndex(musicIndex + 1)
    //             } else {
    //                 setIsPlay(false)
    //             }
    //
    //         }
    //     }
    // }, [currentTime])

    // Swap the audio URL
    // const onUrlChange = useCallback(() => {
    //     sources.reverse()
    //     setAudioUrl(sources[0])
    // }, [])
    // useEffect(() => {
    //     swipeMusic()
    // }, [currentTime])
    // useEffect(() => {
    //     setAudioUrl(music.src)
    // }, [music.src])
    return (
        <>
            <AudioPopUp isOpen={"open"} backPage={backPage} backClick={backClick} music={music}
                        duration={duration ? duration : 0}
                        currentTime={currentTime}></AudioPopUp>
            {/*<button onClick={onUrlChange}>Change audio</button>*/}
            {!!music.src && <WavePlayer mediaElement={mediaElement}/>}

            {/*<span>{currentProgress}</span>*/}
        </>
    )
}

export default AudioWavePlayer