import {NavLink} from "react-router-dom";
import "./style.scss"
import {useContext} from "react";
import {SocialIconsContext} from "../../context/socialIconsContext";
import {AccountsEnum} from "../../enums/accountsEnum";

const SocialIcons = () => {
    const {data} = useContext(SocialIconsContext)
    return (
        <section className={`P-socialIconsSection`}>
            {data.slice(0, 4).map((item, index) => {
                return (<NavLink to={item.link} target={"_blank"} key={item.link + item.icon}><img loading={"lazy"}
                    src={item.icon}
                    alt={item.iconName}/></NavLink>)
            })}
            <NavLink to={`?${AccountsEnum.streamingLinks}`} className={`P-dots`}>...</NavLink>
            {/* <NavLink to={"https://music.apple.com/us/artist/futu/1692067038"} target={"_blank"}><img src={itunes}
                                                                                                     alt="itunes"/></NavLink>
            <NavLink to={"https://open.spotify.com/artist/0qCeHaAxsHH4rRUzumBLvP"} target={"_blank"}><img src={spotify}
                                                                                                          alt="spotify"/></NavLink>
            <NavLink to={"https://www.facebook.com/lilianabellamusic"} target={"_blank"}><img src={facebook}
                                                                                              alt="facebook"/></NavLink>
            <NavLink to={"https://www.instagram.com/futurili/"} target={"_blank"}><img src={instagram} alt="instagram"/></NavLink>
            <NavLink to={"https://youtu.be/iB59bQZFmRM"} target={"_blank"}><img
                src={youtube}
                alt="youtube"/></NavLink>*/}

        </section>
    )
}
export default SocialIcons