import React, {useContext, useEffect, useState} from "react";
import {SocialIconsContext} from "../../../../context/socialIconsContext";
import ErrorPopUp from "../../../../components/errorPopUp";
import SocialIconsApi from "../../../../requests/api/socialIconsApi";
import {ISocialIconsData} from "../../../../models/content.model";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./style.scss"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ButtonLoader from "../../../../components/buttonLoader";

const MySwal = withReactContent(Swal)

const SocialMediasPage = () => {
    const {t} = useTranslation()
    const {data, setData} = useContext(SocialIconsContext)
    const [newData, setNewData] = useState<ISocialIconsData[]>(structuredClone(data))
    const [editMode, setEditMode] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [tackedElement, setTackedElement] = useState("")
    const [coveredElement, setCoveredElement] = useState("")
    useEffect(() => {
        setNewData(structuredClone(data))
    }, [data])

    const onSave = async () => {
        if (editMode) {
            setDisabled(true)
            try {
                const result = await SocialIconsApi.updateAll(newData)
                if (result.data) {

                    setData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
            setDisabled(false)

        }
        setEditMode(!editMode)
    }
    const onCancel = () => {
        setNewData(structuredClone(data))
        setEditMode(false)

    }

    const onDelete = async (event: MouseEvent, item: ISocialIconsData) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await SocialIconsApi.delete(item._id)
                    if (result.status === 200) {
                        const newData = [...data]
                        newData.splice(newData.findIndex(elem => item._id === elem._id), 1)
                        setData([...newData])
                        MySwal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )

                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }

                } catch (err) {
                    console.log(err)
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }

    return (<div className={`P-socialMediasPage P-pageContent`}>
        <div className={`G-flex G-justify-between G-align-center P-top`}>
            <p className={`P-title`}>{t("Social-medias-text")} </p>
            <NavLink className={`P-addButton P-primaryBTN`}
                     to={"add_social_media"}>{t("Add-New-Social-Media-text")}</NavLink>
        </div>
        <div className={`P-socialIcons`}>
            {newData.sort((a, b) => {
                if (a.order && b.order) {
                    return (a.order - b.order as number)
                } else {
                    return 0
                }
            }).map((item, index) => {
                return <div className={`P-icon`} key={item._id}
                            draggable={editMode}


                            onDragStart={(e) => {
                                setTackedElement(item._id)

                            }}
                            onDragOver={(e) => {
                                setCoveredElement(item._id)

                            }}
                            onDragEnd={(e) => {
                                if (editMode) {
                                    const data = [...newData]
                                    const tacked = newData.find(element => element._id === tackedElement)
                                    const covered = newData.find(element => element._id === coveredElement)
                                    if ((tacked?.order && covered?.order) || (tacked?.order === 0 || covered?.order === 0)) {
                                        const preOrder = covered?.order as number
                                        const file = data.find(el => el.order === tacked?.order) as ISocialIconsData
                                        if (tacked?.order as number > (covered?.order as number)) {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order < tacked.order && item.order >= covered.order) {
                                                    item.order = item.order + 1
                                                }
                                            })
                                        } else {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order > tacked.order && item.order <= covered.order) {
                                                    item.order -= 1
                                                }
                                            })
                                        }

                                        file.order = preOrder
                                        setNewData([...data])
                                    }
                                }
                            }}
                >
                    <NavLink className={`P-socialElement`}
                             to={item._id}><img loading={"lazy"}  src={item.icon} alt={item.iconName}/><span
                        className={`P-delete icon-bin`}
                        onClick={(event) => onDelete(event as unknown as MouseEvent, item)}/></NavLink>
                </div>

            })
            }
        </div>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{editMode ? t("save-text") :
                <span><span className={`icon-pen`}/> {t("Edit-text")}</span>}</ButtonLoader>
            {editMode && <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>}

        </div>


    </div>)
}

export default SocialMediasPage