import {NavLink} from "react-router-dom";
import "./style.scss"
import React, {DragEventHandler, useContext, useEffect, useRef, useState} from "react";
import {MusicContext} from "../../context/musicPlayerContext";
import {IHomeMusic} from "../../models/homePageMusic.model";

interface IAudioPlayerCircle {
    music: IHomeMusic;
    size: "L" | "M";
    draggable?: boolean;
    onDragStart?: DragEventHandler;
    onDragLeave?: DragEventHandler;
    onDragEnd?: DragEventHandler;
    onDragOver?: DragEventHandler;
    onDrop?: DragEventHandler;
    errorClass?: "errorClass" | "";
    isOpen: boolean

}

const AudioPlayerCircle = ({
                               music,
                               size,
                               draggable = false,
                               onDrop,
                               onDragOver,
                               onDragEnd,
                               onDragLeave,
                               onDragStart,
                               errorClass,
                               isOpen
                           }: IAudioPlayerCircle) => {
    const [dragClass, setDragClass] = useState<string>("")
    const [moving, setMoving] = useState(false)
    const {setIsClick, setIsPlay, isPlay, isClick, isLoaded, setIsLoaded} = useContext(MusicContext)
    const nameContainer = useRef<HTMLParagraphElement | null>(null)
    const name = useRef<HTMLSpanElement | null>(null)
    useEffect(() => {
        if (!isOpen) {
            setIsPlay(false)
        }
    }, [isOpen])
    const play = (event: KeyboardEvent) => {
        if (isOpen) {
            if (event.code === "Space") {
                setIsClick(!isClick)
            }
        }
    }
    useEffect(() => {

        window.addEventListener("keyup", play)
        return () => {
            window.removeEventListener("keyup", play)
        }
    }, [isClick])

    useEffect(() => {
        if (name.current && nameContainer.current) {

            if (name.current?.offsetWidth > nameContainer.current?.clientWidth - (2 * (window.innerWidth / 100))) {
                setMoving(true)


            }
        }
    }, [nameContainer.current, name.current])
    return (<div
            className={`P-audioPlayerCircle ${isOpen ? "open" : ""} ${size} ${!isOpen ? errorClass : ""} ${!isOpen ? dragClass : ""}`}
            draggable={draggable && !isOpen}

            onDrop={onDrop}
            onDragEnd={(event) => {
                onDragEnd && onDragEnd(event);
                setDragClass("")
            }}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDragStart={(event) => {
                onDragStart && onDragStart(event);
                setDragClass("drag")
            }}
            onTouchStart={(event) => onDragStart && onDragStart(event as unknown as React.DragEvent<Element>)}
            onTouchMove={(event) => onDragOver && onDragOver(event as unknown as React.DragEvent<Element>)}
            onTouchEnd={(event) => onDragEnd && onDragEnd(event as unknown as React.DragEvent<Element>)}
        >
            <NavLink
                to={!!music.id ? `?musicId=${music.id}` : ""}

            >            {!!music.fit &&
                <div className={`moving`}><span className={` P-fit P-text yellow `}>{music.fit}</span><span
                    className={` P-fit P-text yellow `}>{music.fit}</span></div>}

                <p className={`P-circle ${!music.id ? "glitch" : ""}`} style={{backgroundColor: music.color}}/>
                <div className={`G-flex`}>


                    {isLoaded ?
                        <p className={`G-loaderContainer`}><span className={`icon-loader G-loader`}>Loading...</span>
                        </p> :
                        <span className={`${isPlay && isOpen && !!music.src ? "icon-pause" : "icon-play"} `}
                              onClick={() => {
                                  if (isOpen) {
                                      setIsPlay(!isPlay)
                                      if (!isPlay) {
                                          setIsLoaded(true)
                                      }
                                  }
                                  setIsClick(!isClick)
                              }}/>}
                    <p className={`P-nameContainer moving`} ref={nameContainer}>
                        <span className={`P-text`} ref={name} data-name={music.name}>{music.name}</span>
                        <span className={`P-text`} ref={name} data-name={music.name}>{music.name}</span>
                    </p>
                </div>
            </NavLink>

            {/* {isOpen && <div className={`P-player`}><AudioWavePlayer backPage={RoutesName.Home_page}
                                                                    music={music} isClick={isClick}
                                                                    isPlay={isPlay}
                                                                    setIsPlay={setIsPlay}></AudioWavePlayer></div>}*/}


        </div>
    )
}

export default AudioPlayerCircle