import React, {ChangeEventHandler, useCallback, useContext, useEffect, useState} from "react";
import {MeditationContext} from "../../../../../context/meditationContext";
import {IMonth, TReadAudio, TReadChoices, TReadDay, TReadMonth} from "../../../../../models/meditation.model";
import {useParams} from "react-router";
import GoBackButton from "../../../../../components/goBackButton";
import FutuInput from "../../../../../components/futuInput";
import ButtonLoader from "../../../../../components/buttonLoader";
import {useTranslation} from "react-i18next";
import ExclusivePlayer from "../../../../../components/exclusivePlayer";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import "./style.scss"
import ClickOutsideBlock from "../../../../../components/click-outsideBlock";
import PopUpContainer from "../../../../../components/popUpContainer";
import EachMeditationAudioAdminPage from "../eachMeditationAudioAdminPage";
import {RoutesName} from "../../../../../router";
import MeditationAPI from "../../../../../requests/api/meditationAPI";
import ErrorPopUp from "../../../../../components/errorPopUp";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal)

const EachMeditationDayAdminPage = () => {
    const {data, getData, setData, create, update} = useContext(MeditationContext)
    const {id, dayId} = useParams()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [audioWindow, setAudioWindow] = useState<string>("")
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState<boolean>(false)

    const [value, setValue] = useState<Omit<TReadDay, "_id">>({
        title: "",
        audios: [],
        audiosTitle: "",
        choices: [],
    })

    const getFromData = () => {
        const result = data.find(item => item._id === id) as TReadMonth
        const {title, audios, audiosTitle, choices} = result.days.find(item => item._id === dayId) as TReadDay
        setValue({title, audios, audiosTitle, choices})

    }
    const onLoad = async () => {
        if (data.length <= 0) {
            await getData()
        } else if (id === "AddMonth" && data[data.length - 1]._id) {
            navigate("/Admin/Meditation/AddMonth")
        } else if (dayId !== "AddDay" && data.length > 0) {
            getFromData()
        } else {
            setValue({
                title: "",
                audiosTitle: "",
                audios: [],
                choices: []
            })
        }
    }
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
    }

    const onCancel = async () => {
        await onLoad()
    }

    const onSave = async () => {
        setDisabled(true)
        if (dayId !== "AddDay") {
            try {
                const newData = [...data]
                const current = newData.find(item => item._id === id) as TReadMonth
                const currentDay = current.days.find(day => day._id === dayId) as TReadDay
                for (const key in value) {
                    // @ts-ignore
                    currentDay[key as keyof TReadDay] = value[key as keyof Omit<TReadDay, "_id">]
                }
                await setData([...newData])
                await update(id as string)
            } catch (err) {
                console.log(err)
            }
        } else if (id === "AddMonth") {
            try {
                const result = await MeditationAPI.creat({
                    ...data[data.length - 1],
                    days: [...data[data.length - 1].days, {...value}]
                })
                navigate("/Admin/Meditation" + result.data._id)
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                const newData = [...data]
                const current = newData.find(item => item._id === id) as TReadMonth
                current.days.push({...value} as TReadDay)

                await setData([...newData])

                await update(id as string)

                navigate("/Admin/Meditation/" + id)

            } catch (err) {
                console.log(err)
            }
        }
        setDisabled(false)
    }
    const onClose = () => {
        searchParams.delete("audio")
        setSearchParams(searchParams);
    }

    const onDelete = async (event: React.MouseEvent, keyId: string, key: "audios" | "choices") => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // const result = await meditationAPI.delete(id)
                    // if (result.status === 200) {
                    const newData = [...data]
                    const currentMonth = newData.find(item => item._id === id)
                    if (currentMonth) {
                        const currentDay = currentMonth.days.find(day => day._id === dayId)

                        if (currentDay && Array.isArray(currentDay[key as keyof TReadDay])) {
                            (currentDay[key as keyof TReadDay] as []).splice((currentDay[key as keyof TReadDay] as TReadAudio[] | TReadChoices[]).findIndex(item => item._id === keyId), 1)
                            await setData([...newData])
                            await update(id as string)
                            MySwal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )

                        } else {
                            ErrorPopUp({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something wrong with the network',

                            })
                        }
                    }
                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',
                    })
                }
            }
        })
    }

    const getParams = useCallback(() => {
        if (searchParams.has("audio")) {
            setAudioWindow(searchParams.get("audio") as string)
        } else {
            setAudioWindow("")
        }
    }, [searchParams])
    useEffect(() => {
        onLoad()
    }, [data])
    useEffect(() => {
        getParams()
    }, [searchParams])
    return (<div className={`P-eachMeditationDayAdminPage`}>
        <GoBackButton
            backPage={(RoutesName.Admin_page + "/" + RoutesName.Admin_MeditationAdminPage + "/" + id) as RoutesName}/>
           <p className={`P-title`}>{data.find(item => item._id === id) ? (data.find(item => item._id === id) as IMonth).title : "Add Day"}</p>
        <div className={`P-inputsContainer G-flex G-flex-wrap`}>

            <div className={`P-inputBox`}>
                <FutuInput value={value.title} onChange={onChangeHandler} name={"title"}
                           label={"Title"}/>
                <FutuInput value={value.audiosTitle} onChange={onChangeHandler} name={"audiosTitle"}
                           label={"Audios Title"}/>

            </div>

        </div>
        {dayId !== "AddDay"&& <div className={`P-inputBox`}>
            <div className={`G-flex G-justify-between G-align-center P-top`}>
                <p className={`P-title`}>{t("Audios-text")} </p>
                {<NavLink className={`P-addButton P-primaryBTN`} to={"?audio=AddAudio"}>Add Audio</NavLink>}
            </div>
            <div className={`G-flex-wrap`}>
                {value.audios.map((audio, audioIndex) => (
                    <div key={audio._id} className={`P-audioContainer P-deleteContainer`}>
                        <span style={{zIndex: 3}} className={`icon-bin P-delete`}
                              onClick={(event) => onDelete(event, audio._id, "audios")}/>

                        <NavLink to={`?audio=${audio._id}`}>
                            <ExclusivePlayer
                                index={audioIndex}
                                bought={true}
                                sample={{
                                    title: audio.title,
                                    _id: audio._id,
                                    subtitle: "",
                                    amount: "",
                                    order: 1,
                                    previewSound: "",
                                    previewSoundName: "",
                                    downloadableSound: "",
                                    downloadableSoundName: "",
                                }}
                                onBasket={() => {
                                    ""
                                }}
                                buyButton={false}

                                isBasket={false}/>
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>}
        {dayId !== "AddDay"&& <div className={`P-inputBox`}>
            <div className={`G-flex G-justify-between G-align-center P-top`}>
                <p className={`P-title`}>{t("Choices-text")} </p>
                {dayId !== "AddDay" &&
                    <NavLink className={`P-addButton P-primaryBTN`} to={"AddChoice"} onClick={async () => {
                        if (dayId === "AddDay") {
                            const newData = [...data]
                            const current = newData.find(item => item._id === id) as TReadMonth
                            current.days.push({...value} as TReadDay)

                            await setData([...newData])
                            await update(id as string)

                        }
                    }}>Add Choice</NavLink>
                }            </div>
            <div className={`G-flex-wrap P-choices`}>
                {value.choices.map((choice, choiceIndex) => (
                    <div key={choice._id} className={`P-choice P-deleteContainer`}>
                        <span style={{zIndex: 3}}
                              className={`icon-bin P-delete`}
                              onClick={(event) => onDelete(event, choice._id, "choices")}/>

                        <NavLink to={choice._id}>
                            <div className={`P-choiceBannerContainer`} key={choice._id}>
                                <div className={`P-choiceBanner`}>
                                    {choice.banner ?
                                        <img loading={"lazy"}
                                             src={choice.banner}
                                            alt={choice.bannerName}/> :
                                        <p className={`P-choiceText`}> {"Choice " + (choiceIndex + 1)}</p>
                                    }
                                </div>
                            </div>
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
        }        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
            <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

        </div>
        {
            audioWindow && <ClickOutsideBlock onClick={onClose}>
                <PopUpContainer onClose={onClose}>
                    <EachMeditationAudioAdminPage
                        audio={value.audios.find(audio => audio._id === audioWindow) ?? {
                            title: "",
                            description: "",
                            audio: "",
                            audioName: ""
                        }}
                        setAudio={async (audio) => {
                            const newData = [...data]
                            const current = newData.find(item => item._id === id) as TReadMonth
                            const currentDay = current.days.find(day => day._id === dayId) as TReadDay
                            if (audioWindow !== "AddAudio") {
                                const currentAudio = currentDay.audios.find(audio => audio._id === audioWindow) as TReadAudio
                                for (const key in audio) {
                                    // @ts-ignore
                                    currentAudio[key as keyof TReadAudio] = audio[key as keyof Omit<TReadAudio, "_id">]
                                }
                            } else {
                                currentDay.audios.push({...audio} as TReadAudio)
                            }
                            await setData([...newData])
                            await update(id as string)
                            onClose()
                        }
                        }/>
                </PopUpContainer>
            </ClickOutsideBlock>
        }
    </div>)
}
export default EachMeditationDayAdminPage