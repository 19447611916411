import {useParams} from "react-router";
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "src/router";
import {ChangeEventHandler, useContext, useState} from "react";
import {MusicContext} from "src/context/musicPlayerContext";
import {useTranslation} from "react-i18next";
import {IHomeMusic, musicPopUpEnum,} from "src/models/homePageMusic.model";
import FutuInput from "src/components/futuInput";
import UploadFileComponent from "src/components/upload-file-component";
import {NavLink} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import UploadApi from "../../../../requests/api/uploadApi";
import HomeMusicApi from "../../../../requests/api/homeMusicApi";
import ErrorPopUp from "../../../../components/errorPopUp";
import ButtonLoader from "../../../../components/buttonLoader";

interface IValue {
    musicName: string
    color: string
    fit: string
    text: string
    banner: string
    bannerName: string
    src: string
    src2: string
    description: string
    type: musicPopUpEnum
    originalName: string
    originalName2: string
}

interface IError {
    musicName: boolean
    color: boolean
    banner: boolean
    fit: boolean
    text: boolean
    src: boolean
    description: boolean

}

const HomeEachMusicPage = () => {
    const {id} = useParams()
    const {allHomeMusicData, setAllHomeMusicData} = useContext(MusicContext)
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState<""|"wav"|"mp3">("")
    const [currentMusic, setCurrentMusic] = useState<IHomeMusic | undefined>(allHomeMusicData.find(item => item.id === id))
    const [values, setValues] = useState<IValue>({
        musicName: currentMusic?.name ?? "",
        color: currentMusic?.color ?? "transparent",
        fit: currentMusic?.fit ?? "",
        text: currentMusic?.text ?? "",
        src: (currentMusic?.src ?? "") as string,
        src2: (currentMusic?.src2 ?? "") as string,
        banner: (currentMusic?.banner ?? "") as string,
        bannerName: (currentMusic?.bannerName ?? "") as string,
        description: currentMusic?.description ?? "",
        type: currentMusic?.type ?? musicPopUpEnum.mainMusic,
        originalName: currentMusic?.originalName ?? "",
        originalName2: currentMusic?.originalName2 ?? ""
    })
    const [error, setError] = useState<IError>({
        musicName: false,
        color: false,
        fit: false,
        text: false,
        src: false,
        banner: false,
        description: false,

    })
    const uploadFile = async (file: File, key: string) => {
        setIsLoading(key!=="2"?"wav":"mp3")
        console.log(file)
        try {
            const result = await UploadApi.send(file)
            console.log(result)
            setValues({
                ...values,
                ["src" + key]: result.data.src,
                ["originalName" + key]: result.data.name
            })
            setIsLoading("")
        } catch (err) {
            console.log(err)
        }
    }
    const onSave = async () => {
        setDisabled(true)
        const {musicName, ...newValues} = {...values}
        if (!values.src || !values.src2) {
            if (!values.src) {
                newValues.src = values.src2
                newValues.originalName = values.originalName2
            }
            if (!values.src2) {
                newValues.src2 = values.src
                newValues.originalName2 = values.originalName
            }
        }
        if (currentMusic) {
            try {
                const result = await HomeMusicApi.update({...newValues, name: values.musicName, id: id as string})
                if (result.data) {
                    const newData: IHomeMusic[] = [...allHomeMusicData]
                    newData[newData.findIndex(item => item.id === id)] = {
                        ...newValues,
                        name: values.musicName,
                        id: id as string
                    }
                    setAllHomeMusicData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })

                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                console.log(err)
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
        } else {
            try {

                const result = await HomeMusicApi.creat({
                    ...newValues,
                    name: values.musicName,
                    order: !!allHomeMusicData[allHomeMusicData.length - 1] ? (allHomeMusicData[allHomeMusicData.length - 1].order as number) + 1 : 0
                })
                if (result.data) {
                    const newData: IHomeMusic[] = [...allHomeMusicData]
                    newData.push({...result.data, id: result.data._id})
                    setAllHomeMusicData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                console.log(err)
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
        }
        setDisabled(false)
    }
    const onCancel = () => {
        setValues({
            musicName: currentMusic?.name ?? "",
            color: currentMusic?.color ?? "transparent",
            fit: currentMusic?.fit ?? "",
            text: currentMusic?.text ?? "",
            src: (currentMusic?.src ?? "") as string,
            src2: (currentMusic?.src2 ?? "") as string,
            banner: (currentMusic?.banner ?? "") as string,
            bannerName: (currentMusic?.bannerName ?? "") as string,
            description: currentMusic?.description ?? "",
            type: currentMusic?.type ?? musicPopUpEnum.mainMusic,
            originalName: currentMusic?.originalName ?? "",
            originalName2: currentMusic?.originalName2 ?? ""
        })
    }

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    return (
        <div className={`P-homeEachMusicPage P-pageContent  `}>
            <GoBackButton backPage={`${RoutesName.Admin_page}/${RoutesName.Admin_HomePageMusic}` as RoutesName}/>

            <div className={`P-musicContainer`}>
                <p className={`P-title`}>{currentMusic ? currentMusic.name : "Add Music"}</p>
                <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                    <div className={`P-inputBox G-flex`}>
                        <label className={`P-circleInput`} style={{backgroundColor: values.color}}>
                            <input value={values.color} onChange={onChangeHandler} type={"color"}
                                   name={"color"}
                            /></label>
                        {/*<UploadCircleImageComponent getFile={() => {
                            ""
                        }} src={currentMusic?.banner ? currentMusic.banner : ""}/>*/}
                    </div>

                    <div className={`P-inputBox`}>
                        <FutuInput value={values.musicName} onChange={onChangeHandler} name={"musicName"}
                                   label={"Name"}/>
                        <FutuInput value={values.fit} onChange={onChangeHandler} name={"fit"}
                                   label={"Fit"}/>
                        <FutuInput value={values.description} onChange={onChangeHandler} name={"description"}
                                   label={"Description"}/>
                    </div>
                    <div className={`P-inputBox`}>
                        <p className={`P-label`}>WAV Music</p>
                        <UploadFileComponent getFile={(file) => file ? uploadFile(file, "") : ""} isLoading={isLoading==="wav"}
                                             accept={"audio/wav"}/>
                        <NavLink to={values.src} target={"_blank"} className={`P-try`}>Listen WAV</NavLink>
                    </div>
                    <div className={`P-inputBox`}>
                        <p className={`P-label`}>MP3 Music</p>
                        <UploadFileComponent getFile={(file) => file ? uploadFile(file, "2") : ""}
                                             accept={"audio/mp3,audio/mpeg,audio/mpeg3"} isLoading={isLoading==="mp3"}/>
                        <NavLink to={values.src2} target={"_blank"} className={`P-try`}>Listen MP3</NavLink>

                    </div>
                </div>
                <div className={`P-textArea`}>

                    <p className={`P-label`}>Text</p>

                    <TextareaAutosize className={`P-input`} value={values.text}
                                      onChange={(e) => setValues({...values, text: e.target.value})}></TextareaAutosize>

                </div>
                <p className={`P-subTitle`}>{t("PopUp-View-text")}</p>
                <div className={`P-viewsContainer`}>
                    <div className={`P-radioBox`}>
                        <label className={`P-radio`}>

                            <div className={`P-images`}>
                                <img loading={"lazy"} className={`P-desktop`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/mainMusic.png"} alt="mainMusic"/>
                                <img loading ={"lazy"} className={`P-mobile`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/mainMusicMobile.png"} alt="mainMusicMobile"/>
                            </div>
                            <span
                                className={`P-customRadio ${values.type === musicPopUpEnum.mainMusic ? "checked" : ""}`}/>


                            <input type="radio" name={`type`}
                                   id={musicPopUpEnum.mainMusic} checked={values.type === musicPopUpEnum.mainMusic}
                                   onChange={(e) => {
                                       setValues({...values, type: musicPopUpEnum.mainMusic})
                                   }}/>

                        </label>
                    </div>
                    <div className={`P-radioBox`}>

                        <label className={`P-radio`}>
                            <div className={`P-images`}>
                                <img loading={"lazy"} className={`P-desktop`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithText.png"} alt="musicWithText"/>
                                <img loading={"lazy"} className={`P-mobile`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithTextMobile.png"} alt="musicWithTextMobile"/>
                            </div>
                            <span
                                className={`P-customRadio ${values.type === musicPopUpEnum.musicWithText ? "checked" : ""}`}/>
                            <input type="radio" name={`type`} value={values.type}
                                   id={musicPopUpEnum.musicWithText}
                                   checked={values.type === musicPopUpEnum.musicWithText}
                                   onChange={(e) => {
                                       setValues({...values, type: musicPopUpEnum.musicWithText})
                                   }}/>
                        </label>
                    </div>
                    <div className={`P-radioBox`}>

                        <label className={`P-radio`}>
                            <div className={`P-images`}>
                                <img loading={"lazy"} className={`P-desktop`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithoutText.png"} alt="musicWithoutText"/>
                                <img loading={"lazy"} className={`P-mobile`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithoutTextMobile.png"} alt="musicWithoutTextMobile"/>
                            </div>
                            <span
                                className={`P-customRadio ${values.type === musicPopUpEnum.musicWithoutText ? "checked" : ""}`}/>

                            <input type="radio" name={`type`}
                                   checked={values.type === musicPopUpEnum.musicWithoutText}
                                   id={musicPopUpEnum.musicWithoutText} onChange={(e) => {
                                setValues({...values, type: musicPopUpEnum.musicWithoutText})
                            }}/>
                        </label>
                    </div>
                    <div className={`P-radioBox`}>

                        <label className={`P-radio`}>
                            <div className={`P-images`}>
                                <img loading={"lazy"} className={`P-desktop`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithTextCenter.png"} alt="musicWithTextCenter"/>
                                <img loading={"lazy"} className={`P-mobile`} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/exemples/musicWithTextCenterMobile.png"}
                                     alt="musicWithTextCenterMobile"/>
                            </div>
                            <span
                                className={`P-customRadio ${values.type === "musicWithTextCenter" ? "checked" : ""}`}/>

                            <input type="radio" name={`type`}
                                   id={musicPopUpEnum.musicWithTextCenter}
                                   checked={values.type === musicPopUpEnum.musicWithTextCenter}
                                   onChange={(e) => {
                                       setValues({...values, type: musicPopUpEnum.musicWithTextCenter})
                                   }}/>
                        </label>
                    </div>
                </div>

            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave}
                              disabled={disabled}>{t("save-text") as string}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text") as string}</ButtonLoader>

            </div>

        </div>
    )
}
export default HomeEachMusicPage