import "./style.scss"

const MeditationButton = ({data}: { data: { banner: string, bannerName: string, title: string } }) => {
    return (<div className={`P-meditation`}>
            <div className={`P-banner`}>
                {/*<p style={{*/}
                {/*    color: "#ffffff",*/}
                {/*    width: "80%",*/}
                {/*    margin: "auto ",*/}
                {/*    textAlign: "center"*/}
                {/*}}>here will be the image we will upload</p>*/}
                <img  loading={"lazy"}  src={data.banner} alt={data.bannerName}/>
            </div>
            <p className={`P-title`}>{data.title}</p>
        </div>
    )
}

export default MeditationButton