import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = localStorage.getItem('AUTH_TOKEN')

    config.headers = {
        // 'Accept': '*/*',
    }


    if (token) {

        config.headers = {
            ...config.headers, "Authorization": "Bearer" + " " + token
        }

    }
    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    if (response.status === 401 || response.status === 422) {
        localStorage.removeItem('AUTH_TOKEN')
        window.location.reload()
    }
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    // console.error(`[response error] [${JSON.stringify(error)}]`);
    if (error.response?.status === 401 || error.response?.status === 422) {
        localStorage.removeItem('AUTH_TOKEN')
        window.location.reload()
    }
    return Promise.reject(error);
}

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}


class Service {

    constructor() {

        setupInterceptorsTo(axios)
    }

    GET<T, R>(path: string, configs: AxiosRequestConfig = {}) {
        return axios.get<T, AxiosResponse<R>>(this.generatePath(path), configs).catch(err => err)
    }

    POST<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
        return axios.post<T, AxiosResponse<R>>(this.generatePath(path), data, configs).catch(err => err)
    }

    PUT<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
        return axios.put<T, AxiosResponse<R>>(this.generatePath(path), data, configs).catch(err => err)
    }

    PATCH<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
        return axios.patch<T, AxiosResponse<R>>(this.generatePath(path), data, configs).catch(err => err)
    }
    OPTIONS<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
        return axios.patch<T, AxiosResponse<R>>(this.generatePath(path), data, configs).catch(err => err)
    }

    DELETE<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
        return axios.delete<T, AxiosResponse<R>>(this.generatePath(path), configs).catch(err => err)
    }

    generatePath(path: string) {
        return process.env.REACT_APP_BASE_URL + path;
    }
}

export default new Service()

