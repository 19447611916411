import "./style.scss"
import FutuInput from "../futuInput";
import {useTranslation} from "react-i18next";
import PopUpContainer from "../popUpContainer";
import {ChangeEventHandler, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useValidation from "../../hooks/useValidation";
import ButtonLoader from "../buttonLoader";
import {AccountsEnum} from "../../enums/accountsEnum";
import AuthAPI from "../../requests/api/authApi";
import ErrorPopUp from "../errorPopUp";

interface IValues {
    password: string
}


interface IError {
    password: boolean
}

const RecoveryPassword = ({onClose,id}: { onClose: () => void,id:string }) => {
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [show, setShow] = useState<boolean>(false)
    const [values, setValues] = useState<IValues>({
        password: "",
    })
    const navigate = useNavigate()
    const [error, setError] = useState<IError>({
        password: false,
    })
    const {isEmail} = useValidation()

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    const valid = () => {
        let isValid = true
        const errors = {...error}
        if (values.password.length < 8) {
            errors.password = true
            // errors.email = true

            isValid = false
        }
        setError({...errors})
        return isValid
    }

    const onConfirm = async () => {
        if (valid()) {
            setDisabled(true)
            try {
                const result = await AuthAPI.changePassword(values,id)
                if (result.data.success) {
                    ErrorPopUp({
                        icon: "success",
                        title: 'Success',
                    })
                }
                onClose()


            } catch (err) {
                setError({...error})
                console.log(err)
            }
            setDisabled(false)

        }
    }

    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            onConfirm()
            window.removeEventListener("keypress", enter)
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", enter)
        return () => {
            window.removeEventListener("keypress", enter)
        }
    }, [values])
    return (
        <PopUpContainer onClose={onClose} title={t("Forgot-password-text") as string}>
            <div className={`P-signInPopUp P-forgotPassword`}>
                <FutuInput
                    value={values.password}
                    onChange={onChangeHandler}
                    name={`password`}
                    label={`Password`}
                    required={true}
                    type={show ? "text" : `password`}
                    rightIcon={show ? "icon-eye-blocked" : "icon-eye"}
                    error={error.password}
                    errorMessage={"password or e-mail is not correct"}
                    onClickIcon={() => {
                        setShow(!show)
                    }}
                />
                <div className={`P-buttons`}>
                    <ButtonLoader className={`primary`} onClick={onConfirm}
                                  disabled={disabled}>{t("Confirm-text")}</ButtonLoader>
                    <ButtonLoader className={`secondary`} onClick={() => navigate(`?popUP=${AccountsEnum.logIn}`)}
                    >{t("cancel-text")}</ButtonLoader>
                </div>
            </div>
        </PopUpContainer>
    )
}

export default RecoveryPassword