import Service from "../sevice/request.service"

interface ISuccess {
    success: boolean
}

interface IPayment {
    publishableKey: string,
    secretKey: string
}

class PaymentApi {
    static creat = (data: { amount: number }) => {
        return Service.POST<{ amount: number }, { clientSecret: string }>(`/payment/creat-payment`,
            data
        )
    }

    static update = (data: IPayment,) => {
        return Service.PATCH <IPayment, ISuccess>(`/payment`, data)
    }

    static read = () => {
        return Service.GET<null, IPayment>("/payment",)
    }
    static config = () => {
        return Service.GET<null, IPayment>("/payment/config",)
    }
}

export default PaymentApi