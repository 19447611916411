import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './i18n/i18n'

import AuthorizationProvider from "./context/authorizationContext";
import MuteProvider from "./context/muteContext";
import ClickSoundProvider from './context/clickSoundContext';
import MusicProvider from "./context/musicPlayerContext";
import MusicProviderYoga from './context/musicContextYoga';
import PageContentProvider from "./context/pageContentContext";
import SocialIconProvider from "./context/socialIconsContext";
import MeditationProvider from "./context/meditationContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthorizationProvider>
                <PageContentProvider>
                    <MuteProvider>
                        <SocialIconProvider>
                            <MeditationProvider>
                                <MusicProvider>
                                    <MusicProviderYoga>
                                        <ClickSoundProvider>
                                            <App/>
                                        </ClickSoundProvider>
                                    </MusicProviderYoga>
                                </MusicProvider>
                            </MeditationProvider>
                        </SocialIconProvider>
                    </MuteProvider>
                </PageContentProvider>
            </AuthorizationProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
