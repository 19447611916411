import "./style.scss"
import {AudioPlayerEnum} from "src/enums/audioPlayerEnum";
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {MuteContext} from "src/context/muteContext";
import AudioWavePlayer from "../audioWavePlayer";
import {RoutesName} from "../../router";
import {NavLink, useSearchParams} from "react-router-dom";
import {MusicContext} from "../../context/musicPlayerContext";
import {IHomeMusic, musicPopUpEnum} from "../../models/homePageMusic.model";


interface IAudioPlayer {
    orientation: AudioPlayerEnum;
    textAsBanner?: string;
    title: string | ReactNode
    music?: { src: string, src2: string, route: string }
    onPlay?: (isPlay: boolean) => void
    currentPlaying: boolean
}

const AudioPlayer = ({orientation, textAsBanner, title, music, onPlay, currentPlaying}: IAudioPlayer) => {
    const [play, setPlay] = useState<boolean>(true)
    const audio = useRef<HTMLAudioElement | null>(null)
    const {isMute} = useContext(MuteContext)
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState("")
    const {onFinish, isPlay, setIsPlay, isClick, setIsClick} = useContext(MusicContext)

    const mainMusic: IHomeMusic = {
        id: "as",
        name: "88",
        color: "#B429F9",
        src: music ? music.src : "",
        src2: music ? music.src2 : "",
        comment: "",
        description: "",
        text: "",
        type: musicPopUpEnum.mainMusic,

    }

    useEffect(() => {
        if (searchParams.has("tab")) {
            const newMusicID = searchParams.get("tab") as string
            setTab(newMusicID)

        } else {
            setTab("")
        }
    }, [searchParams])
    useEffect(() => {
        if (music?.route !== tab) {
            setPlay(true)
            setIsPlay(false)
        }
    }, [tab])
    return (
        <div className={`P-audioPlayer ${orientation}`}>
            {music && currentPlaying && music.route === tab &&
                <div className={`P-player`} style={{zIndex: 0}}>

                    <AudioWavePlayer backPage={RoutesName.Yoga_page}
                                     music={mainMusic} isClick={!play}
                                     mediaElement={<audio src={music.src} ref={audio}/>}
                        /* setIsPlay={(is) => {
                             is ? setIsPlay(Players[item.title]) : setIsPlay("")
                         }}*//>
                </div>}
       {/*     {!!textAsBanner
                ?
                <p className={`P-textAsBanner P-title`} data-text={textAsBanner}> {textAsBanner}</p>
                :
                <span className={`P-banner`}/>


            }*/}

            {music?.route!=="none"?<NavLink to={music ? `?tab=${music.route}` : ""} className={`P-playButton`}
                      onMouseDown={(event) => {
                          (event.target as HTMLParagraphElement).style.transform = "scale(.95)"
                      }}
                      onClick={() => {
                          if (music?.route === tab) {
                              setPlay(!play);
                              setIsPlay(play)

                          }
                          setIsClick(!isClick)
                          onPlay && onPlay(!play)
                          if (!music) {
                              const timer = setTimeout(() => {
                                  setPlay(true)
                                  clearTimeout(timer)
                              }, 1000)
                          }
                      }}
                      onMouseUp={(event) => {
                          (event.target as HTMLParagraphElement).style.transform = "scale(1)"
                      }}
            >
                <span className={`icon-${(play || !currentPlaying) ? "play" : "pause"}`}/>
            </NavLink>:<p className={`P-playButton`}><span className={`icon-${(play || !currentPlaying) ? "play" : "pause"}`}/></p>}

            <div className={`P-title`}>
                {title}
                {!music && <span className={`P-comingSoon ${play}`}>COMING SOON</span>}
            </div>

        </div>
    )

}
export default AudioPlayer