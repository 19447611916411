import "./style.scss"
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "../../router";
import {useTranslation} from "react-i18next";
import ExclusivePlayer from "../../components/exclusivePlayer";

const MyMusicPage = () => {
    const count: string[] = []
    const {t} = useTranslation()
    return (
        <div className={`P-MyMusicPage`}>
            <main className={`G-container`}>
                <GoBackButton backPage={RoutesName.Home_page}/>
                <section className={`P-pageContent`}>
                    <section className={`P-playersSection`}>
                        {count.map((item, index) => {
                            return (<div className={`P-player`} key={item + "" + index}><ExclusivePlayer
                                index={index}
                                bought={true}
                                sample={{
                                    title: "the title",
                                    _id: "",
                                    subtitle: "Artist",
                                    amount: "30",
                                    order: 1,
                                    previewSound: "",
                                    previewSoundName: "",
                                    downloadableSound: "",
                                    downloadableSoundName: ""
                                }}
                                onBasket={() => {
                                    ""
                                }}
                                buyButton={false}

                                isBasket={false}/></div>)
                        })
                        }</section>
                    {/*<section className={`P-actionsSection`}>*/}
                    {/*    <button className={`P-exclusiveButton yellow`}>{t("download-album-text")}</button>*/}
                    {/*    <button className={`P-exclusiveButton`}>{t("Subscribe-text")}</button>*/}

                    {/*</section>*/}
                </section>
            </main>
        </div>
    )
}

export default MyMusicPage