import React, {useContext, useEffect, useState} from "react";
import {IPageContent} from "src/models/content.model";
import {AuthorizationContext} from "../authorizationContext";
import ContentAPI from "../../requests/api/contentApi";
import ErrorPopUp from "../../components/errorPopUp";
import RegulationsApi, {IRegulation} from "../../requests/api/regulationsApi";

interface IPageContentContext {
    data: IPageContent,
    setData: (data: IPageContent) => void
    loading: boolean
    regulations: IRegulation
    setRegulation: (data: IRegulation) => void

}


export const PageContentContext = React.createContext<IPageContentContext>({
    data: {
        homePage: {
            adBanner1: "",
            adBanner2: "",
            adBanner3: "",
            samplePage: false,
            buttons: {
                futuYoga: {description: ""},
                futuLive: {description: ""}
            }
        },
        yogaPage: {
            subtitle: "",
            buttons: {
                futuHome: {description: ""},
                futuLive: {description: ""},
                meditation: {
                    bannerName: "",
                    banner: "",
                    title: "",
                }
            }

        },
    },
    regulations: {
        termsAndConditions: "",
        cookiesPolicy: "",
        privacyPolicy: ""
    },
    setRegulation() {
        ""
    },
    setData() {
        ""
    },
    loading: true

})
const PageContentProvider = ({children}: { children: React.ReactNode }) => {
    const {token} = useContext(AuthorizationContext)
    const [loading, setLoading] = useState(true)
    const [regulations, setRegulation] = useState<IRegulation>({
        termsAndConditions: "",
        cookiesPolicy: "",
        privacyPolicy: ""
    })
    const [data, setData] = useState<IPageContent>({
        homePage: {
            adBanner1: "",
            adBanner2: "",
            adBanner3: "",
            samplePage: false,
            buttons: {
                futuYoga: {description: ""},
                futuLive: {description: ""}
            }
        },
        yogaPage: {
            subtitle: "",
            buttons: {
                futuHome: {description: ""},
                futuLive: {description: ""},
                meditation: {
                    bannerName: "",
                    banner: "",
                    title: "",
                }
            }

        },
    })
    const getContent = async () => {
        try {
            const result = await ContentAPI.read()
            const regulations = await RegulationsApi.read()
            setRegulation({...regulations.data[0]})
            setData({...result.data[0]})
            setLoading(false)
        } catch (err) {
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
            console.log(err)
        }
    }
    useEffect(() => {
        getContent()

    }, [])
    return (
        <PageContentContext.Provider value={{data, setData, loading, regulations, setRegulation}}>
            {children}
        </PageContentContext.Provider>
    )


}

export default PageContentProvider