import React, {useContext, useEffect, useState} from "react";
import {MuteContext} from "../muteContext";
import sound1 from "src/assets/sounds/clickSounds/1.wav"
import sound2 from "src/assets/sounds/clickSounds/2.wav"
import sound3 from "src/assets/sounds/clickSounds/3.wav"
import sound4 from "src/assets/sounds/clickSounds/4.wav"
import sound5 from "src/assets/sounds/clickSounds/5.wav"


const sounds: HTMLAudioElement[][] = [[
    new Audio(sound1), new Audio(sound2), new Audio(sound4), new Audio(sound3), new Audio(sound5)]
]
// for (let i = 1; i < 70; i++) {
//     sounds.push(`https://futuilritest.s3.eu-north-1.amazonaws.com/clickSounds/${i}.mp3`)
// }


interface IClickSoundContext {
    playSound: () => void

}


export const ClickSoundContext = React.createContext<IClickSoundContext>({
    playSound: () => {
        ""
    }

})
const ClickSoundProvider = ({children}: { children: React.ReactNode }) => {
    const {isMute} = useContext(MuteContext)
    let audio = new Audio()
    const [currentTime, setCurrentTime] = useState(0)
    const [type, setType] = useState(Math.floor(Math.random() * (sounds.length - 0)) + 0)


    const playSound = () => {
        if (Date.now() - currentTime >= 3000) {
            setType(Math.floor(Math.random() * (sounds.length - 0)) + 0)
        }

        sounds.map(item => item.map(element => element.pause()))
        audio = sounds[type][Math.floor(Math.random() * (sounds[type].length - 0)) + 0]
        audio.play()
        setCurrentTime(Date.now())

    }

    useEffect(() => {
        sounds.map(item => {
            item.map(element => {
                    element.muted = isMute
                    element.pause()
                }
            )


        })


    }, [isMute])

    return (
        <ClickSoundContext.Provider value={{
            playSound
        }}>
            {children}
        </ClickSoundContext.Provider>
    )


}

export default ClickSoundProvider