import "./style.scss"
import {useTranslation} from "react-i18next";
import {ISampleData} from "../../models/sample.model";
import {useEffect, useRef, useState} from "react";

interface IExclusivePlayerProps {
    index: number,
    bought: boolean,
    onBasket: () => void,
    isBasket: boolean,
    sample: ISampleData,
    inBasket?: boolean,
    buyButton: boolean
    onBuyButton?: () => void
}

const ExclusivePlayer = ({
                             index,
                             bought = false,
                             sample,
                             onBasket,
                             isBasket,
                             buyButton,
                             inBasket = false,
                             onBuyButton,
                         }: IExclusivePlayerProps) => {
    const {t} = useTranslation()
    const audio = useRef<HTMLAudioElement | null>(null)
    const [play, setPlay] = useState(false)

    useEffect(() => {
        if (!sample.previewSound) return
        if (play) {
            audio.current?.play()

        } else {
            audio.current?.pause()
        }
        audio.current?.addEventListener("ended", () => {
            setPlay(false)
        })
        return () => {
            audio.current?.removeEventListener("ended", () => {
                setPlay(false)
            })
        }
    }, [play])

    return (<div className={`P-exclusivePlayer ${bought ? "" : "forSell"}`}>
        <span className={`P-index`}>{index + 1}</span>
        <button className={`P-playButton`} onClick={() => {
            if (!sample.previewSound) return
            setPlay(!play)
        }}><span
            className={play ? `icon-pause` : `icon-play`}/></button>
        <section className={`P-infoSection`}>
            <p className={`P-title`}>{sample.title}</p>
            <span className={`P-description`}>{sample.subtitle}</span>
        </section>
        {!bought && (buyButton ? <><p className={`P-price`}>$ {sample.amount}</p>
                <button className={`P-buyButton`} onClick={onBuyButton}>{t("buy-text")}</button>
            </> :
            <p className={`P-buyButton`}>$ {sample.amount} </p>)}
        {!inBasket && !bought && <span className={`icon-cart-plus ${isBasket ? "active" : ""}`} onClick={onBasket}/>}
        <audio src={sample.previewSound} ref={audio}/>
    </div>)
}


export default ExclusivePlayer
