import "./style.scss"
import {ReactNode, useContext} from "react";
import {FutuButtonSizeEnum, FutuButtonTypeEnum} from "src/enums/futuButtonEnum";
import {ClickSoundContext} from "../../context/clickSoundContext";
import {MusicContextYoga} from "../../context/musicContextYoga";

interface IFutuButton {
    children: ReactNode

    size: FutuButtonSizeEnum
    type: FutuButtonTypeEnum
}

const FutuButton = ({


                        children,
                        size,
                        type,
                    }: IFutuButton) => {
    const {playSound} = useContext(ClickSoundContext)
    const {setPlay} = useContext(MusicContextYoga)

    return (<button className={`P-futuButton ${size} ${type}`} onClick={() => {
        if (type === FutuButtonTypeEnum.yoga) setPlay(true)
    }} onMouseDown={playSound}>
        {type === FutuButtonTypeEnum.yoga &&
            <img loading={"lazy"}  src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaLeftBack.webp"} alt="" className={`P-beforeBackgroundLeft`}/>}
        {type === FutuButtonTypeEnum.yoga && <img  loading={"lazy"} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/YogaBackground.webp"} alt="" className={`P-yogaBackground`}/>}
        {children}

    </button>)
}

export default FutuButton