import React, {useEffect, useState} from "react";
import {ISocialIconsData} from "src/models/content.model";
import ErrorPopUp from "../../components/errorPopUp";
import SocialIconsApi from "../../requests/api/socialIconsApi";

interface ISocialIconContext {
    data: ISocialIconsData[],
    setData: (data: ISocialIconsData[]) => void
}


export const SocialIconsContext = React.createContext<ISocialIconContext>({
    data: [],
    setData: () => {
        ""
    }


})
const SocialIconProvider = ({children}: { children: React.ReactNode }) => {
    const [data, setData] = useState<ISocialIconsData[]>([])
    const getContent = async () => {
        try {
            const result = await SocialIconsApi.read()
            setData([...result.data.sort((a: ISocialIconsData, b: ISocialIconsData) => {
                if (a.order && b.order) {
                    return (a.order - b.order as number)
                } else {
                    return 0
                }
            })])
        } catch (err) {
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
            console.log(err)
        }
    }
    useEffect(() => {
        getContent()

    }, [])
    return (
        <SocialIconsContext.Provider value={{data, setData}}>
            {children}
        </SocialIconsContext.Provider>
    )


}

export default SocialIconProvider