import React, {useState} from "react";
import YogaMusicApi from "../../requests/api/yogaMusicApi";
import {IMonth, TReadMonth} from "../../models/meditation.model";
import MeditationAPI from "../../requests/api/meditationAPI";


interface IMeditationContext {
    data: TReadMonth[]
    setData: (data: TReadMonth[]) => void
    getData: () => Promise<void>
    create: () => Promise<void>
    update: (id: string) => Promise<void>
}


export const MeditationContext = React.createContext<IMeditationContext>({

    getData: async () => {
        try {
            const result = await YogaMusicApi.read()
        } catch (err) {
            console.log(err)
        }
    },
    data: [],
    setData: () => {
        ""
    },
    update: async (id) => {
        ""
    },
    create: async () => {
        ""
    }

})
const MeditationProvider = ({children}: { children: React.ReactNode }) => {
    const [data, setData] = useState<TReadMonth[]>([])

    const getData: () => Promise<void> = async () => {
        try {
            const result = await MeditationAPI.read()
            setData(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    const update = async (id: string) => {
        const result = await MeditationAPI.update((id as string), {...data.find(item => item._id === id)} as IMonth)
        if(result.data.success){
            await getData()
        }
    }
    const create = async () => {
        const result = await MeditationAPI.creat({...data[data.length - 1]})

    }
    /*useEffect(() => {
        getMusics()
    }, [])
*/

    return (
        <MeditationContext.Provider value={{
            data, setData, getData, update,create
        }}>

            {children}
        </MeditationContext.Provider>
    )


}

export default MeditationProvider