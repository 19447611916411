import "./style.scss"
import {ChangeEventHandler, FC, HTMLInputTypeAttribute} from "react";


interface IFutuInputProps {
    type?: HTMLInputTypeAttribute
    onChange: ChangeEventHandler<HTMLInputElement>
    value: string | number | readonly string[] | undefined
    placeholder?: string
    label?: string
    error?: boolean
    errorMessage?: string
    required?: boolean
    name?: string
    rightIcon?: string
    rightImage?: string
    onClickIcon?: () => void
    onClickImage?: () => void
}


const FutuInput: FC<IFutuInputProps> = ({
                                            type = "text",
                                            onChange,
                                            value,
                                            placeholder,
                                            label,
                                            error = false,
                                            errorMessage,
                                            required = false,
                                            name,
                                            rightIcon,
                                            rightImage,
                                            onClickIcon,
                                            onClickImage
                                        }) => {
    return (<div className={`P-futuInput`}>
        {label && <p className={`P-label`}>{label}{required && <span className={`P-required`}>*</span>}</p>}
        <div className={`P-futuInputContainer`}>
            <input type={type}
                   onChange={(event)=>onChange(event)}
                   value={value}
                   placeholder={placeholder}
                   name={name}
                   className={`P-input ${error ? "error" : ""}`}
                   autoComplete={"off"}
            />
            {!!rightIcon && <span className={rightIcon} onClick={onClickIcon}/>}
            {!!rightImage && <img loading={"lazy"} src={rightImage} alt={"icon"} onClick={onClickImage}/>}
        </div>
        {}
        {error && <span className={`P-errorMessage`}>{errorMessage}</span>}

    </div>)
}

export default FutuInput