
const useMixColors = ( hexColor1: string, hexColor2: string ) => {

    function componentToHex(c: number) {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex([r, g, b]: number[]) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function convert(color: string) {
        return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)?.map(x => parseInt('0x' + x)).slice(1, 4)
    }

    function mixColor(color1: string, color2: string) {
        const [c1, c2] = [color1, color2].map(x => convert(x))
        const cm: number[] = []

        c1?.forEach((c, i) => cm.push(parseInt(`${c1 && c2 ? (c1[i] + c2[i]) / 2 : ""}` as string)))
        return rgbToHex(cm)
    }

    return mixColor(hexColor1, hexColor2);
}

export default useMixColors