import {Outlet} from "react-router";
import React from "react";
import SideBar from "./components/sideBar";
import "./style.scss"

const SettingsPage = () => {
    return (<div className={`P-settingsPage`}>
        <SideBar/>
        <div className={`P-subPages`}>
            <Outlet/>
            {/*<Routes>
                {adminSubRoutes.map((item,) => (
                    <Route path={item.path} element={<item.component/>} key={item.path}/>
                ))
                }
            </Routes>*/}
        </div>
    </div>)
}

export default SettingsPage