import {useEffect, useState} from "react";
import "./style.scss"
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import ErrorPopUp from "../../../../components/errorPopUp";
import ButtonLoader from "../../../../components/buttonLoader";
import {ISampleData} from "../../../../models/sample.model";
import SampleApi from "../../../../requests/api/sampleApi";
import {decodeToken} from "react-jwt";
import {Jwt} from "jsonwebtoken";
import ExclusivePlayer from "../../../../components/exclusivePlayer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal)

const SampleMusicPage = () => {
    const {t} = useTranslation()
    const [preState, setPreState] = useState<ISampleData[]>([])
    const [data, setData] = useState<ISampleData[]>([])
    const [editMode, setEditMode] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [tackedElement, setTackedElement] = useState("")
    const [coveredElement, setCoveredElement] = useState("")
    useEffect(() => {
        (async () => {
            const result = await SampleApi.read()
            const newData: ISampleData[] = []
            result.data.map((item: string) => {
                const token = decodeToken(item) as Jwt & { _doc: ISampleData }
                newData.push(token._doc)
            })
            setData([...newData])
            setPreState([...newData])
        })()
    }, [])

    const onSave = async () => {

        if (editMode) {
            setDisabled(true)
            try {
                const result = await SampleApi.updateAll(data)
                if (result.data) {

                    setData([...data])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
            setDisabled(false)
        }
        setEditMode(!editMode)
    }
    const onCancel = () => {
        setData(structuredClone(preState))
        setEditMode(false)
    }

    const onDelete = async (event: MouseEvent, id: string) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await SampleApi.delete(id)
                    if (result.status === 200) {
                        const newData = [...data]
                        newData.splice(newData.findIndex(item => item._id === id), 1)
                        setData([...newData])
                        MySwal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }

                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }

    return (<div className={`P-pageContent`}>
        <div className={`G-flex G-justify-between G-align-center P-top`}>
            <p className={`P-title`}>{t("Samples-text")} </p>
            <NavLink className={`P-addButton P-primaryBTN`} to={"add_sample"}>Add Sample</NavLink>
        </div>
        <div className={`P-SampleMusic`}>
            {data.sort((a, b) => {
                if (a.order && b.order) {
                    return (a.order - b.order as number)
                } else {
                    return 0
                }
            }).map((item, index) => {
                return <div className={`P-music`} key={item._id}
                            draggable={editMode}


                            onDragStart={(e) => {
                                setTackedElement(item._id)

                            }}
                            onDragOver={(e) => {
                                setCoveredElement(item._id)

                            }}
                            onDragEnd={(e) => {
                                if (editMode) {
                                    const newData = [...data]
                                    const tacked = data.find(element => element._id === tackedElement)
                                    const covered = data.find(element => element._id === coveredElement)
                                    if ((tacked?.order && covered?.order) || (tacked?.order === 0 || covered?.order === 0)) {
                                        const preOrder = covered?.order as number
                                        const file = newData.find(el => el.order === tacked?.order) as ISampleData
                                        if (tacked?.order as number > (covered?.order as number)) {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order < tacked.order && item.order >= covered.order) {
                                                    item.order = item.order + 1
                                                }
                                            })
                                        } else {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order > tacked.order && item.order <= covered.order) {
                                                    item.order -= 1
                                                }
                                            })
                                        }

                                        file.order = preOrder
                                        setData([...data])
                                    }
                                }
                            }}
                >
                    <ExclusivePlayer index={index} bought={false} sample={item}
                                     buyButton={false} inBasket={true} isBasket={true} onBasket={() => {
                        ""
                    }}/>
                    <NavLink to={item._id}><span className={`P-icon icon-pen`}/></NavLink>
                    <span className={`P-icon icon-bin`} onClick={(event) => onDelete(event as unknown as MouseEvent, item._id)}/>
                </div>

            })
            }
        </div>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{editMode ? t("save-text") :
                <span><span className={`icon-pen`}/> {t("Edit-text")}</span>}</ButtonLoader>
            {editMode && <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>}

        </div>
    </div>)
}

export default SampleMusicPage