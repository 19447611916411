import React, {useEffect, useState} from "react";
import {IProfile, IToken, ITokenField} from "../../models/auth.model";
import AuthApi from "../../requests/api/authApi";
import {decodeToken} from "react-jwt";


interface IAuthorizationContext {
    token: string;
    setToken: (token: string) => void
    setIsAdmin: (key: boolean) => void
    isAdmin: boolean
    user: IProfile & ITokenField | null
    setUser: (data: IProfile & ITokenField) => void

}


export const AuthorizationContext = React.createContext<IAuthorizationContext>({
    token: "",
    setToken: () => {
        ""
    },
    setIsAdmin: () => {
        ""
    },
    isAdmin: false,
    setUser: () => {
        ""
    },
    user: null
})
const AuthorizationProvider = ({children}: { children: React.ReactNode }) => {
    const [token, setToken] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    const [user, setUser] = useState<IProfile & ITokenField | null>(null)
    useEffect(() => {
        const storageToken = localStorage.getItem("AUTH_TOKEN") as string

        (async () => {
            if (!!storageToken) {
                try {
                    const result = await AuthApi.getProfile()
                    if ((decodeToken(storageToken) as IToken).role === "admin") {
                        setIsAdmin(true)
                    }
                    setUser(result.data)
                    setToken(storageToken as string)

                } catch (error) {
                    localStorage.removeItem("AUTH_TOKEN")
                }
            }
        })()

    }, [])
    return (
        <AuthorizationContext.Provider value={{
            token, setToken, isAdmin, setIsAdmin, user, setUser: async (item) => {
                try {
                    const result = await AuthApi.update(item)
                    setUser(item)
                } catch (err) {
                    console.log(err)
                }
            }
        }}>
            {children}
        </AuthorizationContext.Provider>
    )


}

export default AuthorizationProvider