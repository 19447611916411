import React, {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {MeditationContext} from "../../../../../context/meditationContext";
import {IMonth, TReadChoice, TReadChoices, TReadDay, TReadMonth} from "../../../../../models/meditation.model";
import {useParams} from "react-router";
import GoBackButton from "../../../../../components/goBackButton";
import FutuInput from "../../../../../components/futuInput";
import ButtonLoader from "../../../../../components/buttonLoader";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import "./style.scss"
import UploadCircleImageComponent from "../../../../../components/upload-circle-image-component";
import UploadApi from "../../../../../requests/api/uploadApi";
import {RoutesName} from "../../../../../router";
import ErrorPopUp from "../../../../../components/errorPopUp";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal)

const EachMeditationChoiceAdminPage = () => {
    const {data, getData, setData, create, update} = useContext(MeditationContext)
    const {id, dayId, choiceId} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [value, setValue] = useState<Omit<TReadChoices, "_id">>({
        title: "",
        banner: "",
        bannerName: "",
        subChoices: []
    })


    const getFromData = () => {
        const newData = [...data]
        const current = newData.find(item => item._id === id) as TReadMonth
        const currentDay = current?.days.find(day => day._id === dayId) as TReadDay
        const {
            title,
            banner,
            bannerName,
            subChoices
        } = currentDay.choices.find(choice => choice._id === choiceId) as TReadChoices
        setValue({title, banner, bannerName, subChoices})
    }
    const onLoad = () => {
        if (choiceId !== "AddChoice") {
            if (data.length <= 0) {
                getData()
            } else {
                getFromData()
            }
        } else {
            setValue({
                title: "",
                banner: "",
                bannerName: "",
                subChoices: []
            })
        }
    }
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
    }

    const onCancel = async () => {
        await onLoad()
    }

    const onSave = async () => {
        setDisabled(true)

        const newData = [...data]
        const current = newData.find(item => item._id === id) as TReadMonth
        const currentDay = current.days.find(day => day._id === dayId) as TReadDay
        if (choiceId !== "AddChoice") {
            const currentChoice = currentDay.choices.find(choice => choice._id === choiceId) as TReadChoices
            for (const key in value) {
                // @ts-ignore
                currentChoice[key as keyof TReadChoices] = value[key as keyof Omit<TReadChoices, "_id">]
            }

        } else {
            currentDay.choices.push({...value} as TReadChoices)
        }
        await setData([...newData])
        await update(id as string)

         navigate("/Admin/Meditation/" + id+"/"+dayId)

        setDisabled(false)

    }
    const uploadFile = async (file: File | undefined,) => {
        if (file) {
            setIsLoading(true)
            try {
                const result = await UploadApi.send(file)
                setValue({
                    ...value,
                    banner: result.data.src,
                    bannerName: result.data.name
                })
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }

    }


    const onDelete = async (event: React.MouseEvent, keyId: string) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // const result = await meditationAPI.delete(id)
                    // if (result.status === 200) {
                    const newData = [...data]
                    const currentMonth = newData.find(item => item._id === id)
                    const currentDay = currentMonth?.days.find(day => day._id === dayId)
                    const currentChoice = currentDay?.choices.find(choice => choice._id === choiceId)

                    currentChoice?.subChoices.splice(currentChoice.subChoices.findIndex(item => item._id === keyId), 1)
                    await setData([...newData])
                    await update(id as string)
                    MySwal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )

                    /*  } else {
                          ErrorPopUp({
                              icon: 'error',
                              title: 'Oops...',
                              text: 'Something wrong with the network',

                          })
                      }
*/
                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }


    useEffect(() => {
        onLoad()
    }, [data])
    return (<div className={`P-eachMeditationDayAdminPage`}>
        <GoBackButton
            backPage={(RoutesName.Admin_page + "/" + RoutesName.Admin_MeditationAdminPage + "/" + id + "/" + dayId) as RoutesName}/>
        <p className={`P-title`}>{data.find(item => item._id === id) ? (data.find(item => item._id === id) as IMonth).title : "Add Choice"}</p>
        <div className={`P-inputsContainer G-flex G-flex-wrap`}>

            <div className={`P-inputBox`}>
                <div className={`P-inputBox G-flex`}>

                    <UploadCircleImageComponent getFile={uploadFile} src={value.banner} isLoading={isLoading}/>
                </div>
                {/*<FutuInput value={value.title} onChange={onChangeHandler} name={"title"}*/}
                {/*           label={"Title"}/>*/}

            </div>

        </div>

        {choiceId !== "AddChoice"&&<div className={`P-inputBox`}>
            <div className={`G-flex G-justify-between G-align-center P-top`}>
                <p className={`P-title`}>{t("Choices-text")} </p>
                {choiceId !== "AddChoice" &&
                    <NavLink className={`P-addButton P-primaryBTN`} to={"AddSubChoice"} onClick={async () => {
                        if (choiceId === "AddChoice") {
                            const newData = [...data]
                            const current = newData.find(item => item._id === id) as TReadMonth
                            const currentDay = (dayId !== "AddDay" ? current.days.find(item => item._id === dayId) : current.days[current.days.length - 1]) as TReadDay
                            currentDay.choices.push({...value} as TReadChoices)

                            await setData([...newData])
                            await update(id as string)

                        }
                    }}>Add Choice</NavLink>}
            </div>
            <div className={`G-flex-wrap`}>
                {value.subChoices.map((choice, choiceIndex) => (
                    <div key={(choice as TReadChoice)._id} className={`P-deleteContainer`}>
                        <span style={{zIndex: 3}} className={`icon-bin P-delete`}
                              onClick={(event) => onDelete(event, choice._id,)}/>
                        <NavLink to={(choice as TReadChoice)._id}>
                            <div className={`P-choiceBannerContainer`} key={(choice as TReadChoice)._id}>
                                <div className={`P-choiceBanner`}>
                                    {choice.banner ?
                                        <img loading={"lazy"}
                                             src={choice.banner}
                                            alt={choice.bannerName}/> :
                                        <p className={`P-choiceText`}> {"Choice " + (choiceIndex + 1)}</p>
                                    }
                                </div>
                            </div>
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>}
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
            <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

        </div>
    </div>)
}
export default EachMeditationChoiceAdminPage