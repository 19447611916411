import Service from "../sevice/request.service"

interface ISuccess {
    success: boolean,
    originalName: string,
    src: string
}

class UploadApi {
    // static update = (data: IPageContent) => {
    //     return Service.PATCH <IPageContent, ISuccess>("/homeMusic", data)
    // }

    static send = async (data: File) => {
        const formData = new FormData()
        formData.append('file', data)
        // @ts-ignore
        // for (const key of formData.entries()) {

        // }
        return Service.POST<FormData, ISuccess>("/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export default UploadApi