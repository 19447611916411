import {ReactElement} from "react";

export enum musicPopUpEnum {
    mainMusic = "mainMusic",
    musicWithText = "musicWithText",
    musicWithoutText = "musicWithoutText",
    musicWithTextCenter = "musicWithTextCenter"
}

export interface IHomeMusicCreat {
    name: string,
    color: string,
    fit?: string,
    prequelOf?: string,
    sequelOf?: string,
    banner?: string,
    bannerName?: string,
    text?: string,
    src: string | ReactElement | HTMLAudioElement,
    src2: string | ReactElement | HTMLAudioElement,
    originalName?: string,
    originalName2?: string,
    order?: number,
    description?: string,
    comment?: string
    type: musicPopUpEnum
}

export interface IHomeMusic extends IHomeMusicCreat {
    id: string,
}