import React, {cloneElement, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {MuteContext} from "../../../../context/muteContext";
import {MusicContext} from "../../../../context/musicPlayerContext";


interface IWavePlayer {
    mediaElement: ReactElement

}

const WavePlayer = ({mediaElement}: IWavePlayer) => {
    const audio = useRef<HTMLAudioElement | null>(null)
    const progress = useRef<HTMLInputElement | null>(null)
    const playAnimation = useRef<number>()

    const {isMute} = useContext(MuteContext)
    const {finish, back, method, isClick, isPlay, setIsPlay, format, setIsLoaded} = useContext(MusicContext)
    const [portrait, setPortrait] = useState(window.innerWidth < window.innerHeight)
    const [currentTime, setCurrentTime] = useState(0)
    const [currentProgress, setCurrentProgress] = useState("0")
    const [max, setMax] = useState(0)
    const [firstTime, setFirstTime] = useState(true)
    const [autoPlay, setAutoPlay] = useState(false)


    const onPlayClick = () => {

        if (isPlay) {

            audio.current?.play()
            const interval = setInterval(() => {
                if (audio.current?.duration && audio.current?.duration > 1) {
                    audio.current?.play()
                    setAutoPlay(true)
                    clearInterval(interval)
                }
            }, 0)

        } else {
            audio.current?.pause()

        }
    }
    const addZero = (number: number) => {
        if (number < 10) {
            return "0" + number
        } else {
            return number
        }
    }
    const onProgressChange = () => {
        if (audio.current && progress.current) {
            setCurrentTime(+progress.current?.value)
            audio.current.currentTime = +progress.current?.value
        }
        // if (firstTime) {
        //     setFirstTime(false)
        //
        //     audio.current?.play()
        //     setIsPlay(true)
        // }

    };

    const repeat = useCallback(() => {
        if (audio.current && progress.current) {

            const currentTime = audio.current?.currentTime;
            setCurrentTime(currentTime);
            progress.current.value = `${currentTime}`;
            progress.current.style.setProperty(
                '--range-progress',
                `${(+progress.current.value / audio.current.duration) * 100}%`
            );

            playAnimation.current = requestAnimationFrame(repeat);
        }
    }, [audio, audio.current?.duration, progress]);

    useEffect(() => {
        // audio.current?.load()
        if (autoPlay) {
            setIsPlay(true)
            setIsLoaded(true)
        }

        window.addEventListener("resize", () => {
            setPortrait(window.innerWidth < window.innerHeight)
        })

        //
        return () => {
            //         subscriptions.forEach((unsub) => unsub())
            window.removeEventListener("resize", () => {
                setPortrait(window.innerWidth < window.innerHeight)
            })
            setIsLoaded(false)
        }
        //
    }, [method, mediaElement])
    useMemo(() => {
        onPlayClick()
    }, [isClick])

    useMemo(() => {
        if (audio.current) {
            const duration = audio.current.duration as number
            const progress = audio.current.currentTime / (duration) * 100
            if (isNaN(progress)) return
            if (portrait) {
                setCurrentProgress(() => `${(51 * progress / 100) + 6 > 55.9 ? (51 * progress / 100) + 12 : (51 * progress / 100) + 6}vw`)
            } else {
                setCurrentProgress(() => `${(13 * progress / 100) + 1 > 13.9 ? (13 * progress / 100) + 2 : (13 * progress / 100) + 1}vw`)
            }

        }
        return
    }, [currentTime])
    useEffect(() => {
        if (!(!!audio.current?.paused && audio.current?.currentTime < 1 && !audio.current.ended)) {
            setIsLoaded(false)
        }
        // setIsPlay(!!audio.current?.paused && audio.current.currentTime > 0 && !audio.current.ended)
    }, [audio.current?.currentTime])
    useEffect(() => {
        audio.current?.duration ? setMax(audio.current?.duration) : ""
    }, [audio.current?.duration])
    useEffect(() => {
        if (audio.current) {
            audio.current.currentTime = currentTime
        }
    }, [format])

    return (<div className={`P-wavePlayer`}>

        <div className={`P-audioWavePlayer wave `} id={`wave`}
            // ref={wave as LegacyRef<HTMLDivElement>}
             style={{
                 width: "15vw",
                 height: "5vw"
             }}>
            {cloneElement(mediaElement, {
                ref: audio,
                muted: isMute,
                controls: true,
                style: {display: "none"},
                onTimeUpdate: () => {
                    audio.current && setCurrentTime(audio.current.currentTime)

                },
                autoPlay: autoPlay,
                onEnded: () => {
                    if (method === "repeat" && audio.current) {
                        audio.current.currentTime = 0
                        audio.current.play()

                    } else {
                        setIsPlay(!isPlay)
                        finish()
                    }
                }
            })}

            <input type="range" ref={progress} onInput={onProgressChange} onChange={onProgressChange}
                   value={currentTime} max={`${max}`}/>
            <span
                className={`icon-previous`} onClick={back}/>
            <span
                className={`icon-next`} onClick={finish}/>
            <span className={`P-after`} style={{
                maskSize: `${currentProgress} 100%`,
                WebkitMaskSize: `${currentProgress} 100%`
            }}/>

            <span
                className={`P-currentTime`}>{Math.floor(currentTime / 60) + ":" + addZero(Math.floor(currentTime % 60))}
            </span>

            <span
                className={`P-duration`}>
                {isNaN(audio.current?.duration as number) ? "0:00" : Math.floor(audio.current?.duration as number / 60) + ":" + addZero(Math.floor(audio.current?.duration as number % 60))}</span>

        </div>
    </div>)
}

export default WavePlayer