import {useParams} from "react-router";
import GoBackButton from "src/components/goBackButton";
import {RoutesName} from "src/router";
import {ChangeEventHandler, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FutuInput from "src/components/futuInput";
import UploadFileComponent from "src/components/upload-file-component";
import UploadApi from "../../../../requests/api/uploadApi";
import ButtonLoader from "../../../../components/buttonLoader";
import {ISample, ISampleData} from "../../../../models/sample.model";
import "./style.scss"
import SampleApi from "../../../../requests/api/sampleApi";
import {decodeToken} from "react-jwt";
import {Jwt} from "jsonwebtoken";
import useValidation from "../../../../hooks/useValidation";
import ErrorPopUp from "../../../../components/errorPopUp";

interface IError {
    title: boolean
    previewSound: boolean
    downloadableSound: boolean
    amount: boolean

}

const SampleEachMusicPage = () => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState<"" | "wav" | "mp3">("")
    const [count, setCount] = useState(0)
    const {isEmpty} = useValidation()
    const [currentMusic, setCurrentMusic] = useState<Omit<ISampleData, "order"> | undefined>(undefined)
    const [values, setValues] = useState<Omit<ISample, "order">>({
        title: currentMusic?.title ?? "",
        subtitle: currentMusic?.subtitle ?? "",
        amount: currentMusic?.amount ?? "",
        previewSound: currentMusic?.previewSound ?? "",
        previewSoundName: (currentMusic?.previewSoundName ?? "") as string,
        downloadableSound: (currentMusic?.downloadableSound ?? "") as string,
        downloadableSoundName: (currentMusic?.downloadableSoundName ?? "") as string,

    })
    const [error, setError] = useState<IError>({
        title: false,
        previewSound: false,
        downloadableSound: false,
        amount: false,
    })
    const uploadFile = async (file: File, key: string) => {
        try {
            setIsLoading(key === "previewSound" ? "mp3" : "wav")
            const result = await UploadApi.send(file)
            setValues({
                ...values,
                [key]: result.data.src,
                [key + "Name"]: result.data.name
            })
            setIsLoading("")
        } catch (err) {
            console.log(err)
        }
    }

    const validation = () => {

        let validate = true
        const errors = {...error}
        if (isEmpty(values.title)) {
            errors.title = true
            validate = false

        }
        if (isEmpty(values.amount)) {
            errors.amount = true
            validate = false

        }
        if (isEmpty(values.downloadableSound)) {
            errors.downloadableSound = true
            validate = false

        }
        if (isEmpty(values.previewSound)) {
            errors.previewSound = true
            validate = false

        }
        setError({...errors})
        return validate
    }
    const onSave = async () => {
        setDisabled(true)
        if (validation()) {

            if (currentMusic) {
                try {
                    const result = await SampleApi.update({...values, _id: id as string})
                    if (result.data) {
                        ErrorPopUp({
                            icon: "success",
                            title: 'Succeeded',
                        })
                        setCurrentMusic({...values, _id: id as string})
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',
                        })
                    }
                } catch (err) {
                    console.log(err)
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            } else {
                try {
                    const resultGet = await SampleApi.read()
                    const newData: ISampleData[] = []
                    resultGet.data.map((item: string) => {
                        const token = decodeToken(item) as Jwt & { _doc: ISampleData }
                        newData.push(token._doc)
                    })

                    const lastOrder = newData.sort((a, b) => a.order - b.order)[newData.length - 1]?.order
                    const result = await SampleApi.creat({
                        ...values,
                        order: lastOrder ? lastOrder + 1 : 0
                    })
                    if (result.data) {
                        ErrorPopUp({
                            icon: "success",
                            title: 'Succeeded',
                        })
                        setCurrentMusic({...values, _id: id as string})
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',
                        })
                    }
                } catch (err) {
                    console.log(err)
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            }
        }
        setDisabled(false)
    }
    const onCancel = () => {
        setValues({
            title: currentMusic?.title ?? "",
            subtitle: currentMusic?.subtitle ?? "",
            amount: currentMusic?.amount ?? "",
            previewSound: currentMusic?.previewSound ?? "",
            previewSoundName: (currentMusic?.previewSoundName ?? "") as string,
            downloadableSound: (currentMusic?.downloadableSound ?? "") as string,
            downloadableSoundName: (currentMusic?.downloadableSoundName ?? "") as string,
        })
    }

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    useEffect(() => {
        if (!!id && id !== "add_sample") {
            (async () => {
                const result = await SampleApi.read()
                const newData: ISampleData[] = []
                result.data.map((item: string) => {
                    const token = decodeToken(item) as Jwt & { _doc: ISampleData }
                    newData.push(token._doc)
                })
                const current = newData.find(item => item._id === id)
                if (current) {
                    const {_id, order, ...value} = current
                    setCurrentMusic(current)
                    setCount(newData.length)
                    setValues({...value})
                }
            })()
        }
    }, [])

    return (
        <div className={`P-SampleMusicPage P-pageContent  `}>
            <GoBackButton backPage={`${RoutesName.Admin_page}/${RoutesName.Admin_SampleMusic}` as RoutesName}/>

            <div className={`P-musicContainer`}>
                <p className={`P-title`}>{currentMusic ? currentMusic.title : "Add Sample"}</p>
                <div className={`P-inputsContainer G-flex G-flex-wrap`}>


                    <div className={`P-inputBox`}>
                        <FutuInput value={values.title} onChange={onChangeHandler} name={"title"}
                                   label={"Name"}/>
                    </div>
                    <div className={`P-inputBox`}>
                        <FutuInput value={values.subtitle} onChange={onChangeHandler} name={"subtitle"}
                                   label={"Fit"}/>
                    </div>
                    <div className={`P-inputBox`}>
                        <FutuInput type={"number"} value={values.amount} onChange={onChangeHandler} name={"amount"}
                                   label={"Amount in USD"}/>
                    </div>

                </div>
                <div className={`P-inputsContainer G-flex G-flex-wrap`}>


                    <div className={`P-inputBox`}>
                        <p className={`P-label`}>Preview Sound</p>
                        <UploadFileComponent getFile={(file) => file ? uploadFile(file, "previewSound") : ""}
                                             accept={"audio/wav,audio/mp3,audio/mpeg,audio/mpeg3"}
                                             isLoading={isLoading === "mp3"}/>
                    </div>
                    <div className={`P-inputBox`}>
                        <p className={`P-label`}>Downloadable Sound</p>
                        <UploadFileComponent getFile={(file) => file ? uploadFile(file, "downloadableSound") : ""}
                                             accept={"audio/wav,audio/mp3,audio/mpeg,audio/mpeg3"}
                                             isLoading={isLoading === "wav"}/>

                    </div>
                </div>

            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave}
                              disabled={disabled}>{t("save-text") as string}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text") as string}</ButtonLoader>

            </div>

        </div>
    )
}
export default SampleEachMusicPage