import "./style.scss"
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
// import visa from "src/assets/images/Visa.png"
// import masterCard from "src/assets/images/Mastercard.png"

const Footer = () => {
    const {t} = useTranslation()
    return (
        <footer className={`P-footer`}>
            <div className={`G-container G-align-center G-justify-between`}>
                <section className={`P-paymentsSection `}>
                    {/*<h3 className={`P-sectionTitle`}>{t("Payment-Methods-text")}</h3>*/}
                    <div className={`P-paymentsContainer G-align-center`}>
                        {/*<img src={visa} alt="visa_icon"/>*/}
                        <NavLink to={"?regulation=termsAndCondition"} className={`P-regLinks`}>Terms</NavLink>
                        <NavLink to={"?regulation=privacyAndCookiesPolices "} className={`P-regLinks`}>Privacy and Cookies</NavLink>
                        {/*<img src={masterCard} alt="masterCard_Icon"/>*/}
                    </div>
                </section>
                {/*<section className={`P-copyrightSection`}>*/}
                {/*    Lorem ipsum dolor sit amet consectetur. Blandit facilisis ut hac aliquam vestibulum tincidunt ante*/}
                {/*    ultrices.*/}
                {/*</section>*/}

                <section className={`P-socialIconsContainer G-align-center`}>
                    {/*<h3 className={`P-sectionTitle`}>{t("Contact-us-text")}</h3>*/}
                    <h3 className={`P-sectionTitle`}>FUTUrili LLC ⓒ {new Date().getFullYear()}</h3>
                    {/*<SocialIcons/>*/}
                </section>
            </div>
        </footer>
    )
}
export default Footer