import Home from "./homePage";
import LivePage from "./livePage";
import YogaPage from "./yogaPage";
import ExclusivePage from "./exclusivePage";
import AdminPage from "./adminPage";
import HomePageContentPage from "./adminPage/subPages/homePageContentPage";
import YogaPageMusicPage from "./adminPage/subPages/yogaPageMusicPage";
import HomePageMusicPage from "./adminPage/subPages/homePageMusicPage";
import YogaPageContentPage from "./adminPage/subPages/yogaPageContentPage";
import HomeEachMusicPage from "./adminPage/subPages/homePageMusicPage/homeEachMusicPage";
import MyMusicPage from "./myMusicPage";
import SettingsPage from "./settingsPage";
import PersonalInformation from "./settingsPage/subPages/personalInformation";
import SocialMediasPage from "./adminPage/subPages/socialMediasPage";
import EachSocialMedia from "./adminPage/subPages/socialMediasPage/eachSocialMedia";
import PaymentKeys from "./adminPage/subPages/paymentKeys";
import SampleMusicPage from "./adminPage/subPages/sampleMusicPage";
import SampleEachMusicPage from "./adminPage/subPages/sampleMusicPage/sampleEachMusicPage";
import Meditation from "./meditation";
import MeditationAdminPage from "./adminPage/subPages/meditationAdminPage";
import EachMeditationAdminPage from "./adminPage/subPages/meditationAdminPage/eachMeditationAdminPage";
import EachMeditationDayAdminPage from "./adminPage/subPages/meditationAdminPage/eachMeditationDayAdminPage";
import EachMeditationChoiceAdminPage from "./adminPage/subPages/meditationAdminPage/eachMeditationChoiceAdminPage";
import EachMeditationSubChoiceAdminPage
    from "./adminPage/subPages/meditationAdminPage/eachMeditationSubChoiceAdminPage";
import EachMeditationAudioAdminPage from "./adminPage/subPages/meditationAdminPage/eachMeditationAudioAdminPage";
import EachYogaMusic from "./adminPage/subPages/yogaPageMusicPage/eachYogaMusic";
import RegulationsAdminPage from "./adminPage/subPages/regulationsAdminPage";

export const Pages = {
    Home,
    LivePage,
    YogaPage,
    ExclusivePage,
    AdminPage,
    HomePageContentPage,
    YogaPageContentPage,
    YogaPageMusicPage,
    HomePageMusicPage,
    HomeEachMusicPage,
    SampleMusicPage,
    SampleEachMusicPage,
    MeditationAdminPage,
    EachMeditationAdminPage,
    EachMeditationDayAdminPage,
    EachMeditationChoiceAdminPage,
    EachMeditationSubChoiceAdminPage,
    EachMeditationAudioAdminPage,
    EachYogaMusic,
    MyMusicPage,
    SettingsPage,
    PersonalInformation,
    SocialMediasPage,
    EachSocialMedia,
    PaymentKeys,
    Meditation,
    RegulationsAdminPage
}