import {useTranslation} from "react-i18next";
import FutuInput from "src/components/futuInput";
import {ChangeEvent, ChangeEventHandler, useContext, useEffect, useState} from "react";
import {IHomePage} from "../../../../models/content.model";
import {PageContentContext} from "../../../../context/pageContentContext";
import ErrorPopUp from "../../../../components/errorPopUp";
import ContentApi from "../../../../requests/api/contentApi";
import ButtonLoader from "../../../../components/buttonLoader";
import FutuSwitcher from "../../../../components/futuSwitcher";

interface IErrors {
    adBanner1: boolean,
    adBanner2: boolean,
    adBanner3: boolean,

    buttons: {
        futuYoga: {
            description: boolean
        },
        futuLive: {
            description: boolean
        },
    }
}

const HomePageContentPage = () => {
    const {t} = useTranslation()
    const {data, setData} = useContext(PageContentContext)
    const [disabled, setDisabled] = useState(false)
    const [values, setValues] = useState<IHomePage>({
        adBanner1: "",
        adBanner2: "",
        adBanner3: "",
        samplePage: false,
        buttons: {
            futuYoga: {
                description: ""
            },
            futuLive: {
                description: ""
            },

        }
    })
    const [error, setError] = useState<IErrors>({
        adBanner1: false,
        adBanner2: false,
        adBanner3: false,
        buttons: {
            futuYoga: {
                description: false
            },
            futuLive: {
                description: false
            },
        }
    })
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }
    const onChangeButton = (event: ChangeEvent<HTMLInputElement>, path: string) => {
        setValues({...values, buttons: {...values.buttons, [path]: {[event.target.name]: event.target.value}}})
        setError({...error, buttons: {...error.buttons, [path]: {[event.target.name]: event.target.value}}})
    }
    useEffect(() => {
        if (!!data)
            setValues({...data.homePage})
    }, [data])

    const onSave = async () => {
        setDisabled(true)

        try {
            const result = await ContentApi.update({...data, homePage: values})
            if (result.data.success) {
                setData({...data, homePage: values})
                ErrorPopUp({
                    icon: "success",
                    title: 'Succeeded',
                })
            }

        } catch (err) {
            console.log(err)
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
        }
        setDisabled(false)

    }
    const onCancel = () => {
        setValues({...data.homePage})
    }
    return (<div className={`P-pageContent`}>
            <p className={`P-title`}>{t("Home-Page-content-text")}</p>
            <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                {/*<div className={`P-inputBox`}>
                    <FutuInput value={values.title} onChange={onChangeHandler} name={"title"} label={"Title"}/>
                </div>*/}
                <p className={`P-subTitle`}>{t("Sample-Page-text")}</p>
                <div className={`P-inputBox`}>
                    <FutuSwitcher labelLeft={"Hide"} labelRight={"Show"} value={values.samplePage} onChange={() => {
                        setValues({...values, samplePage: !values.samplePage})
                    }}/>
                </div>
                <p className={`P-subTitle`}>{t("Ad-banner-texts-text")}</p>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.adBanner1} onChange={onChangeHandler} name={"adBanner1"}
                               label={"Ad text 1st row"}/>
                </div>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.adBanner2} onChange={onChangeHandler} name={"adBanner2"}
                               label={"Ad text 2nd row"}/>
                </div>
                <div className={`P-inputBox`}>
                    <FutuInput value={values.adBanner3} onChange={onChangeHandler} name={"adBanner3"}
                               label={"Ad text 3th row"}/>
                </div>
                <p className={`P-subTitle`}>{t("Futu-text")}{t("Yoga-text")} {t("button-text-text")}</p>
                {/*<div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuYoga.title}
                               onChange={(event) => onChangeButton(event, "futuYoga")}
                               name={"title"}
                               label={"title"}/>

                </div>*/}
                <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuYoga.description}
                               onChange={(event) => onChangeButton(event, "futuYoga")} name={"description"}
                               label={"description"}/>
                </div>
                <p className={`P-subTitle`}>{t("Futu-text")}{t("Live-text")} {t("button-text-text")}</p>
                {/* <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuLive.title}
                               onChange={(event) => onChangeButton(event, "futuLive")}
                               name={"title"}
                               label={"title"}/>

                </div>*/}
                <div className={`P-inputBox`}>
                    <FutuInput value={values.buttons.futuLive.description}
                               onChange={(event) => onChangeButton(event, "futuLive")} name={"description"}
                               label={"description"}/>
                </div>
            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave}
                              disabled={disabled}>{t("save-text") as string}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text") as string}</ButtonLoader>

            </div>
        </div>
    )
}

export default HomePageContentPage