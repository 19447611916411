import "./style.scss"
// import {useTranslation} from "react-i18next";
// import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
// import {RoutesName} from "src/router";
// import FutuButton from "src/components/futuButton";
// import {FutuButtonSizeEnum, FutuButtonTypeEnum} from "src/enums/futuButtonEnum";
// import {clock, ears, ellipses, ellipsesMobile, eye, eyee1, face, nose, sunglasses} from "src/assets/images/livePage"
// import React, {ReactElement, useEffect, useState} from "react";
// import AudioWavePlayer from "../../components/audioWavePlayer";
// import {IHomeMusic, musicPopUpEnum} from "../../models/homePageMusic.model";
//
// const futu88 = ""
//
// enum Players {
//     eye = "eye",
//     sunglasses = "sunglasses",
//     eyee1 = "eyee1",
//     nose = "nose",
//     ears = "ears",
//     clock = "clock",
//
//
// }

const LivePage = () => {
    // const {t} = useTranslation()
    // const [isPlay, setIsPlay] = useState<Players | "">("")
    // const [musicId, setMusicId] = useState("")
    // const navigate = useNavigate()
    // const [searchParams] = useSearchParams();
    // const [change, setChange] = useState(false)
    //
    // const mainMusic: IHomeMusic = {
    //     id: "assaEacZ122",
    //     name: "88",
    //     color: "#B429F9",
    //     src: <audio src={futu88}/>,
    //     src2: <audio src={futu88}/>,
    //     comment: "",
    //     description: "",
    //     text: "",
    //     type: musicPopUpEnum.mainMusic
    // }
    // const player = [
    //     {
    //         title: Players.eyee1,
    //         imgSrc: eyee1,
    //         music: mainMusic,
    //         route: "aRfwqw1314mnasd",
    //         answer: t("i-cant-but-music-can-text"),
    //         question: t("can-you-make-me-HAPPY-text")
    //
    //     }, {
    //         title: Players.clock,
    //         imgSrc: clock,
    //         music: mainMusic,
    //         route: "aRfagw1wec11679",
    //         answer: t("the-answer-text"),
    //         answer2: "08.08.2023",
    //         question: t("what-is-WATCH-text")
    //
    //     }, {
    //         title: Players.eye,
    //         imgSrc: eye,
    //         music: mainMusic,
    //         route: "ak18db22po",
    //         answer: t("the-answer-text"),
    //         question: t("why-Futu-text")
    //
    //
    //     }, {
    //         title: Players.nose,
    //         imgSrc: nose,
    //         music: mainMusic,
    //         route: "vkwkj3l",
    //         answer: t("listen-text"),
    //         question: t("how-do-you-live-when-DEPRESSION-kicks-in-text")
    //
    //
    //     }, {
    //         title: Players.ears,
    //         imgSrc: ears,
    //         music: mainMusic,
    //         route: "jwwetjhkbne08",
    //         answer: t("hehehe-text"),
    //         question: t("what-Future-do-you-fantasize-text")
    //
    //
    //     }, {
    //         title: Players.sunglasses,
    //         imgSrc: sunglasses,
    //         music: mainMusic,
    //         route: "aweasnn1bne08",
    //         answer: t("codes-of-reality-text"),
    //         question: t("why-do-details-matter-text")
    //
    //
    //     },
    // ]
    // useEffect(() => {
    //     if (searchParams.has("musicId")) {
    //         const newMusicID = searchParams.get("musicId") as string
    //         setMusicId(newMusicID)
    //     } else {
    //         setMusicId("")
    //     }
    //     let flag = false
    //     const interval = setInterval(() => {
    //
    //         setChange(!flag)
    //         flag = !flag
    //
    //     }, 5000)
    //     return () => {
    //         clearInterval(interval)
    //         setChange(false)
    //
    //         flag = false
    //     }
    // }, [searchParams])
    return (
        <div className={`P-livePage`}>
          {/*  <main className={`G-container`}>
                <div className={`P-titleContainer`}>
                    <p className={`P-title `}>
                        <span className={`left`}>{t("Ask-text")}</span>
                        <span className={`G-futu`}>{t("Futu-text")}</span>
                        <span className={`right`}>{t("A-QUESTION-text")}</span>
                    </p>
                </div>
                <section className={`P-musicsContainer`}>
                    <div className={`ID_ellipses G-desktop`}>
                        <img loading={"lazy"}  src={ellipses} alt="ellipses"/>

                    </div>
                    <div className={`ID_ellipses G-mobile`}>
                        <img  loading={"lazy"} src={ellipsesMobile} alt="ellipsesMobile"/>

                    </div>
                    <div id={`ID_face`}>
                        <img  loading={"lazy"} src={face} alt="face"/>
                        <div className={`P-player`}>
                            <AudioWavePlayer backPage={RoutesName.Home_page}
                                             music={mainMusic}></AudioWavePlayer>
                        </div>
                    </div>
                    {
                        player.map((item, index) => {
                            return (
                                <div id={`ID_${item.title}`} key={item.imgSrc + index}
                                     className={musicId === item.route ? "open" : ""}>
                                    <img loading={"lazy"}  src={item.imgSrc} alt={item.title} onClick={() => {
                                        navigate(`?musicId=${item.route}`)
                                    }}/>
                                    {musicId === item.route &&
                                        <div className={`P-playContainer`}>
                                            {!!item.music.src && <span
                                                className={isPlay === Players[item.title] ? `icon-pause` : `icon-play`}
                                                onClick={() => {
                                                    isPlay === Players[item.title] ? setIsPlay("") : setIsPlay(Players[item.title])
                                                }
                                                }/>}
                                            <p>{item.answer2 && change ? item.answer2 : item.answer}</p></div>}
                                    {musicId === item.route &&
                                        <div className={`P-player`}>

                                            <AudioWavePlayer backPage={RoutesName.Live_page}
                                                             music={item.music} isClick={isPlay === Players[item.title]}
                                                             setIsPlay={(is) => {
                                                                 is ? setIsPlay(Players[item.title]) : setIsPlay("")
                                                             }}
                                                             mediaElement={item.music.src as ReactElement}/>
                                        </div>}
                                    {musicId === item.route && <p className={`P-question`}>{item.question}</p>}
                                </div>
                            )
                        })
                    }


                </section>
                <section className={`P-actionsSection`}>
                    <NavLink to={RoutesName.Home_page} className={`P-button`}>
                        <p className={`P-homeTitle G-yellowText`}>{t("take-me-text")}</p>
                        <FutuButton type={FutuButtonTypeEnum.home}
                                    size={FutuButtonSizeEnum.s}><span
                            className={`G-home`}>{t("Home-text")}</span></FutuButton>
                    </NavLink>
                    <NavLink to={RoutesName.Yoga_page} className={`P-button`}>
                        <p className={`P-yogaTitle G-futu`}>{t("Futu-text")}</p>

                        <FutuButton type={FutuButtonTypeEnum.yoga}
                                    size={FutuButtonSizeEnum.s}><span
                            className={`G-yoga`}>{t("Yoga-text")}</span></FutuButton>
                    </NavLink>
                </section>
            </main>*/}
        </div>
    )
}
export default LivePage