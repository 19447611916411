import React from "react";
import "./style.scss"

const Loader = () => {
    return (<div className={`P-loading`}>
        <p className={`G-loaderContainer`}><span className={`icon-loader G-loader`}>Loading...</span>
        </p>
    </div>)
}

export default Loader