import {Outlet} from "react-router";
import React from "react";
import SideBar from "./components/sideBar";
import "./style.scss"

const AdminPage = () => {
    return (<div className={`P-adminPage`}>
        <SideBar/>
        <div className={`P-subPages`}>
            <Outlet/>

        </div>
    </div>)
}

export default AdminPage