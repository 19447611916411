import "./style.scss"
import FutuInput from "../futuInput";
import {useTranslation} from "react-i18next";
import PopUpContainer from "../popUpContainer";
import {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {AccountsEnum} from "src/enums/accountsEnum";
import useValidation from "../../hooks/useValidation";
import AuthAPI from "../../requests/api/authApi";
import {decodeToken} from "react-jwt";
import {AuthorizationContext} from "../../context/authorizationContext";
import {IToken} from "src/models/auth.model";
import ButtonLoader from "../buttonLoader";

interface IValues {
    email: string
    password: string
}


interface IError {
    email: boolean
    password: boolean
}

const SignInPopUp = ({onClose}: { onClose: () => void }) => {
    const {t} = useTranslation()
    const {setToken, setIsAdmin, setUser} = useContext(AuthorizationContext)
    const [disabled, setDisabled] = useState(false)
    const [values, setValues] = useState<IValues>({
        email: "",
        password: ""
    })
    const navigate = useNavigate()
    const [error, setError] = useState<IError>({
        email: false,
        password: false
    })
    const [show, setShow] = useState<boolean>(false)
    const {isEmail} = useValidation()

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    const valid = () => {
        let isValid = true
        const errors = {...error}
        if (isEmail(values.email)) {
            errors.email = true
            isValid = false
        }
        if (values.password.length < 8) {
            errors.password = true
            // errors.email = true

            isValid = false
        }
        setError({...errors})
        return isValid
    }

    const onLogIn = async () => {
        if (valid()) {
            setDisabled(true)
            try {
                const result = await AuthAPI.logIn({...values,email:values.email.toLowerCase()})
                if ((decodeToken(result.data.authToken) as IToken).role === "admin") {
                    setIsAdmin(true)
                }
                setUser(result.data)
                setToken(result.data.authToken)
                onClose()
                localStorage.setItem("AUTH_TOKEN", result.data.authToken)

            } catch (err) {
                setError({...error, password: true})
                console.log(err)
            }
            setDisabled(false)

        }
    }

    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            onLogIn()
            window.removeEventListener("keypress", enter)
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", enter)
        return () => {
            window.removeEventListener("keypress", enter)
        }
    }, [values])
    return (
        <PopUpContainer onClose={onClose} title={t("Log-in-text") as string}>
            <div className={`P-signInPopUp`}>
                <FutuInput
                    value={values.email}
                    onChange={onChangeHandler}
                    name={`email`}
                    label={`E-mail`}
                    required={true}
                    error={error.email}
                    errorMessage={"email is not valid"}
                />
                <FutuInput
                    value={values.password}
                    onChange={onChangeHandler}
                    name={`password`}
                    label={`Password`}
                    required={true}
                    type={show ? "text" : `password`}
                    rightIcon={show ? "icon-eye-blocked" : "icon-eye"}
                    error={error.password}
                    errorMessage={"password or e-mail is not correct"}
                    onClickIcon={() => {
                        setShow(!show)
                    }}
                />
                <div className={`P-actions`}>
                    <NavLink to={`?popUp=${AccountsEnum.signUp}`}><span
                        className={`icon-mdi_user`}/>{t("Create-an-account-text")}</NavLink>
                </div>
                <div className={`P-actions`}>
                    <NavLink to={`?popUP=forgotPassword`}><span
                        className={`icon-locked`}/>{t("Forgot-password-text")}</NavLink>
                </div>
                <div className={`P-buttons`}>
                    <ButtonLoader className={`primary`} onClick={onLogIn}
                                  disabled={disabled}>{t("Log-in-text")}</ButtonLoader>
                </div>
            </div>
        </PopUpContainer>
    )
}

export default SignInPopUp