import {ChangeEvent, useContext, useEffect, useState} from "react";
import {IYogaPageMusic} from "../../../../models/content.model";
import UploadApi from "../../../../requests/api/uploadApi";
import {useTranslation} from "react-i18next";
import {MusicContextYoga} from "../../../../context/musicContextYoga";
import ButtonLoader from "../../../../components/buttonLoader";
import {useParams} from "react-router";
import GoBackButton from "../../../../components/goBackButton";
import {RoutesName} from "../../../../router";
import FutuInput from "../../../../components/futuInput";
import UploadFileComponent from "../../../../components/upload-file-component";
import ErrorPopUp from "../../../../components/errorPopUp";
import YogaMusicApi from "../../../../requests/api/yogaMusicApi";

const EachYogaMusic = () => {


    const {t} = useTranslation()
    const {getMusics, data, setData} = useContext(MusicContextYoga)
    const {id} = useParams()
    const [currentMusic, setCurrentMusic] = useState(data.find(item => item._id === id))

    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState<"" | "wav" | "mp3">("")
    const [values, setValues] = useState<IYogaPageMusic>({
        title: currentMusic?.title ?? "",
        subtitle: currentMusic?.subtitle ?? "",
        src: currentMusic?.src ?? "",
        src2: currentMusic?.src2 ?? "",
        originalName: currentMusic?.originalName ?? "",
        originalName2: currentMusic?.originalName2 ?? "",
        order: currentMusic?.order ?? data[data.length - 1].order + 1

    })
    useEffect(() => {
        (async () => {
            await getMusics()
        })()
    }, [])


    const onSave = async () => {
        setDisabled(true)
        const newValues = {...values}
        if (!values.src || !values.src2) {
            if (!values.src) {
                newValues.src = values.src2
                newValues.originalName = values.originalName2
            }
            if (!values.src2) {
                newValues.src2 = values.src
                newValues.originalName2 = values.originalName
            }
        }
        if (currentMusic) {
            try {
                const result = await YogaMusicApi.update({...newValues}, id as string)
                if (result.data) {
                    const newData: IYogaPageMusic[] = [...data]
                    newData[newData.findIndex(item => item._id === id)] = {
                        ...newValues,
                        _id: id as string
                    }
                    setData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                console.log(err)
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
        } else {
            try {

                const result = await YogaMusicApi.creat({
                    ...newValues,
                })
                if (result.data) {
                    const newData: IYogaPageMusic[] = [...data]
                    newData.push({...result.data})
                    setData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                console.log(err)
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
        }

        setDisabled(false)

    }

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value

        })
    }
    const onCancel = () => {
        setValues({
            title: "",
            subtitle: "",
            src: "",
            src2: "",
            originalName: "",
            originalName2: "",
            order: 0

        })
    }

    const uploadFile = async (file: File, key: string) => {
        try {
            setIsLoading(key !== "2" ? "wav" : "mp3")
            const result = await UploadApi.send(file)

            setValues({
                ...values,
                ["src" + key]: result.data.src,
                ["originalName" + key]: result.data.name
            })
        } catch (err) {
            console.log(err)
        }
    }
    return (<div className={`P-eachYogaMusic`}>

        <GoBackButton backPage={`${RoutesName.Admin_page}/${RoutesName.Admin_YogaPageMusic}` as RoutesName}/>

        <div className={`P-musicContainer`}>
            <p className={`P-title`}>{currentMusic ? "" : "Add Music"}</p>
            <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                <div className={`P-inputBox`}>
                    <p className={`P-label`}>WAV Music</p>
                    <UploadFileComponent getFile={(file) => file ? uploadFile(file, "") : ""} accept={"audio/wav"}
                                         isLoading={isLoading === "wav"}/>
                    <p className={`P-try`}>{values.originalName}</p>
                </div>
                <div className={`P-inputBox`}>
                    <p className={`P-label`}>MP3 Music</p>
                    <UploadFileComponent getFile={(file) => file ? uploadFile(file, "2") : ""}
                                         accept={"audio/mp3,audio/mpeg,audio/mpeg3"} isLoading={isLoading === "mp3"}/>
                    <p className={`P-try`}>{values.originalName2}</p>

                </div>

                <div className={`P-inputBox`}>
                    <FutuInput value={values.title} onChange={onChangeHandler} name={"title"}
                               label={"Title"}/>
                    <FutuInput value={values.subtitle} onChange={onChangeHandler} name={"subtitle"}
                               label={"Subtitle"}/>
                </div>

            </div>


        </div>
        <br/>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave}
                          disabled={disabled}>{t("save-text") as string}</ButtonLoader>
            <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text") as string}</ButtonLoader>

        </div>
    </div>)
}

export default EachYogaMusic