import {IAudio} from "../../../../../models/meditation.model";
import ButtonLoader from "../../../../../components/buttonLoader";
import React, {ChangeEventHandler, useState} from "react";
import {useTranslation} from "react-i18next";
import FutuInput from "../../../../../components/futuInput";
import UploadApi from "../../../../../requests/api/uploadApi";
import UploadFileComponent from "../../../../../components/upload-file-component";

const EachMeditationAudioAdminPage = ({audio, setAudio}: { audio: IAudio, setAudio: (audio: IAudio) => void }) => {
    const [personalData, setPersonalData] = useState<IAudio>({...audio})
    const [disabled, setDisabled] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {t} = useTranslation()
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPersonalData({...personalData, [event.target.name]: event.target.value})
    }
    const uploadFile = async (file: File | undefined,) => {
        if (file) {
            setIsLoading(true)
            try {
                const result = await UploadApi.send(file)
                setPersonalData({
                    ...personalData,
                    audio: result.data.src,
                    audioName: result.data.name
                })
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (<div className={`P-`}>
        <div className={`P-inputBox`}>

            <FutuInput value={personalData.title} onChange={onChangeHandler} name={"title"}
                       label={"Title"}/>
            <FutuInput value={personalData.description} onChange={onChangeHandler} name={"description"}
                       label={"Description"}/>

        </div>
        <div className={`P-inputBox`}>
            <p className={`P-label`}>Music</p>
            <UploadFileComponent getFile={(file) => file ? uploadFile(file) : ""}
                                 accept={"audio/mp3,audio/mpeg,audio/mpeg3"} isLoading={isLoading}/>
            <p className={`P-try`}>{personalData.audioName}</p>

        </div>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={async () => {
                setDisabled(true)
                await setAudio({...personalData})
                setDisabled(false)
            }} disabled={disabled}>{t("save-text")}</ButtonLoader>
            {/*<ButtonLoader className={`secondary`}
                          onClick={() => setPersonalData({...audio})}>{t("cancel-text")}</ButtonLoader>
*/}
        </div>
    </div>)
}

export default EachMeditationAudioAdminPage