import "./style.scss"
import GoBackButton from "../../../../../components/goBackButton";
import {RoutesName} from "../../../../../router";
import React, {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {IMonth, TReadMonth} from "../../../../../models/meditation.model";
import {useParams} from "react-router";
import FutuInput from "../../../../../components/futuInput";
import {MeditationContext} from "../../../../../context/meditationContext";
import UploadCircleImageComponent from "../../../../../components/upload-circle-image-component";
import UploadApi from "../../../../../requests/api/uploadApi";
import ButtonLoader from "../../../../../components/buttonLoader";
import {useTranslation} from "react-i18next";
import MeditationAPI from "../../../../../requests/api/meditationAPI";
import {NavLink, useNavigate} from "react-router-dom";
import ErrorPopUp from "../../../../../components/errorPopUp";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


const MySwal = withReactContent(Swal)

const EachMeditationAdminPage = () => {
    const {t} = useTranslation()
    const {data, getData, setData, create, update} = useContext(MeditationContext)

    const [disabled, setDisabled] = useState<boolean>(false)
    const [isLoading, seIisLoading] = useState<boolean>(false)
    const [value, setValue] = useState<Omit<TReadMonth, "_id">>({
        title: "",
        days: [],
        banner: "",
        bannerName: "",
    })
    const {id} = useParams()
    const navigate = useNavigate()
    const getFromData = () => {
        const result = data.find(item => item._id === id) as TReadMonth
        const {title, days, banner, bannerName} = result
        setValue({title, days, banner, bannerName})

    }

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
    }

    const onLoad = async () => {
        if (id !== "AddMonth") {
            if (data.length === 0) {
                await getData()
            } else {
                getFromData()
            }
        } else {
            setValue({
                title: "",
                days: [],
                banner: "",
                bannerName: "",
            })
        }
    }
    const onCancel = async () => {
        await onLoad()
    }

    const onSave = async () => {
        setDisabled(true)
        if (id !== "AddMonth") {
            try {
                const newData = [...data]
                const current = newData.find(item => item._id === id) as TReadMonth
                for (const key in value) {
                    // @ts-ignore
                    current[key as keyof TReadMonth] = value[key as keyof Omit<TReadMonth, "_id">]
                }
                await setData([...newData])
                await update(id as string)
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                const result = await MeditationAPI.creat({...value})
                navigate("/Admin/Meditation/")

            } catch (err) {
                console.log(err)
            }
        }
        setDisabled(false)

    }

    const uploadFile = async (file: File | undefined,) => {
        console.log(file)
        seIisLoading(true)
        if (file) {
            try {
                const result = await UploadApi.send(file)
                setValue({
                    ...value,
                    banner: result.data.src,
                    bannerName: result.data.name
                })
                seIisLoading(false)
            } catch (err) {
                console.log(err)
            }
        }

    }


    const onDelete = async (event: React.MouseEvent, dayId: string) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // const result = await meditationAPI.delete(id)
                    // if (result.status === 200) {
                    const newData = [...data]
                    const currentMonth = newData.find(item => item._id === id)
                    currentMonth?.days.splice(currentMonth?.days.findIndex(item => item._id === dayId), 1)

                    await setData([...newData])
                    await update(id as string)
                    MySwal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                    // } else {
                    //     ErrorPopUp({
                    //         icon: 'error',
                    //         title: 'Oops...',
                    //         text: 'Something wrong with the network',
                    //
                    //     })
                    // }

                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }


    useEffect(() => {
        onLoad()
    }, [data])
    return (
        <div className={`P-EachMeditationAdminPage`}>
            <GoBackButton
                backPage={(RoutesName.Admin_page + "/" + RoutesName.Admin_MeditationAdminPage) as RoutesName}/>
            <p className={`P-title`}>{data.find(item => item._id === id) ? (data.find(item => item._id === id) as IMonth).title : "Add Month"}</p>
            <div className={`P-inputsContainer G-flex G-flex-wrap`}>
                <div className={`P-inputBox G-flex`}>

                    <UploadCircleImageComponent getFile={uploadFile} src={value?.banner ? value.banner : ""}
                                                isLoading={isLoading}/>
                </div>
                <div className={`P-inputBox`}>
                    <FutuInput value={value.title} onChange={onChangeHandler} name={"title"}
                               label={"Title"}/>

                </div>

            </div>
            <div className={`G-flex G-justify-between G-align-center P-top`}>
                <p className={`P-title`}>{t("Days-text")} </p>
                {id !== "AddMonth"&&<span className={`P-addButton P-primaryBTN`} onClick={async () => {
                    if (id === "AddMonth") {
                        setData([...data, {...value} as TReadMonth])
                        const result = await MeditationAPI.creat({...value})
                        navigate("/Admin/Meditation/" + result.data._id + "/AddDay")
                    } else {
                        navigate("/Admin/Meditation/" + id + "/AddDay")

                    }
                }}>Add Day</span>}
            </div>
            <div className={`P-days`}>

                {value.days.map((item, index) => (
                        <div className={`P-day P-deleteContainer`} key={item._id}>
                            <span style={{zIndex: 3}} className={`icon-bin P-delete`}
                                  onClick={(event) => onDelete(event, item._id)}/>
                            <NavLink to={item._id}>
                                {item.title}
                            </NavLink>
                        </div>

                    )
                )}
            </div>
            <div className={`P-actionsSection`}>
                <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
                <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

            </div>

        </div>
    )
}

export default EachMeditationAdminPage