import "./style.scss"
import FutuInput from "../futuInput";
import {useTranslation} from "react-i18next";
import PopUpContainer from "../popUpContainer";
import {ChangeEventHandler, useContext, useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import useValidation from "../../hooks/useValidation";
import AuthAPI from "../../requests/api/authApi";
import {decodeToken} from "react-jwt";
import {IToken} from "src/models/auth.model";
import ButtonLoader from "../buttonLoader";
import {AccountsEnum} from "../../enums/accountsEnum";
import "./style.scss"
import ErrorPopUp from "../errorPopUp";
interface IValues {
    email: string
}


interface IError {
    email: boolean
}

const ForgotPassword = ({onClose}: { onClose: () => void }) => {
    const {t}= useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [values, setValues] = useState<IValues>({
        email: "",
    })
    const navigate = useNavigate()
    const [error, setError] = useState<IError>({
        email: false,
    })
    const {isEmail} = useValidation()

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    const valid = () => {
        let isValid = true
        const errors = {...error}
        if (isEmail(values.email)) {
            errors.email = true
            isValid = false
        }

        setError({...errors})
        return isValid
    }

    const onForgot = async () => {
        if (valid()) {
            setDisabled(true)
            try {
                const result = await AuthAPI.forgot({...values,email:values.email.toLowerCase()})
                if (result.data.success) {
                    ErrorPopUp({
                        icon: "warning",
                        title: 'Please check your email',
                        text: 'We have send you email for verification',
                        timer: 7000,
                    })
                }
                onClose()


            } catch (err) {
                setError({...error})
                console.log(err)
            }
            setDisabled(false)

        }
    }

    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            onForgot()
            window.removeEventListener("keypress", enter)
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", enter)
        return () => {
            window.removeEventListener("keypress", enter)
        }
    }, [values])
  return(
      <PopUpContainer onClose={onClose} title={t("Forgot-password-text") as string}>
          <div className={`P-signInPopUp P-forgotPassword`}>
              <FutuInput
                  value={values.email}
                  onChange={onChangeHandler}
                  name={`email`}
                  label={`E-mail`}
                  required={true}
                  error={error.email}
                  type={"email"}
                  errorMessage={"email is not valid or not found"}
              />
              <div className={`P-buttons`}>
                  <ButtonLoader className={`primary`} onClick={onForgot}
                                disabled={disabled}>{t("Confirm-text")}</ButtonLoader>
                  <ButtonLoader className={`secondary`} onClick={()=>navigate(`?popUP=${AccountsEnum.logIn}`)}
                                >{t("cancel-text")}</ButtonLoader>
              </div>
          </div>
      </PopUpContainer>
      )
}

export default ForgotPassword