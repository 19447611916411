import {NavLink} from "react-router-dom";
import {RoutesName} from "../../router";
import {useContext} from "react";
import {ClickSoundContext} from "../../context/clickSoundContext";
import {useTranslation} from "react-i18next";
import "./style.scss"

const GoBackButton = ({
                          backPage,
                          backClick = () => history.back()
                      }: { backPage: RoutesName | "#", backClick?: () => void }) => {
    const {playSound} = useContext(ClickSoundContext)
    const {t} = useTranslation()
    return (
        <span className={`P-backArrowContainer`}>

        <NavLink to={backPage} className={`G-align-center`} onMouseDown={() => {
            location.pathname === RoutesName.Live_page ? playSound() : ""

        }} onClick={() => {
            if (backPage === "#") backClick()
        }}>
            <img loading={"lazy"} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/backArrow.png"} alt="backArrow"/>
            <span>{t("go-back-text")}</span>
        </NavLink>
        </span>)
}
export default GoBackButton