import "./style.scss"
import FutuInput from "../futuInput";
import {useTranslation} from "react-i18next";
import PopUpContainer from "../popUpContainer";
import {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useValidation from "../../hooks/useValidation";
import {AuthorizationContext} from "../../context/authorizationContext";
import AuthAPI from "src/requests/api/authApi";
import ErrorPopUp from "../errorPopUp";
import ButtonLoader from "../buttonLoader";

interface IValues {
    fullName: string
    email: string
    password: string
}


interface IError {
    fullName: boolean
    email: boolean
    password: boolean
}


const SignUpPopUp = ({onClose}: { onClose: () => void }) => {
    const {t} = useTranslation()
    const {setToken, setIsAdmin, setUser} = useContext(AuthorizationContext)
    const [disabled, setDisabled] = useState(false)
    const [values, setValues] = useState<IValues>({
        fullName: "",
        email: "",
        password: ""
    })
    const navigate = useNavigate()
    const [error, setError] = useState<IError>({
        fullName: false,
        email: false,
        password: false
    })
    const [show, setShow] = useState<boolean>(false)
    const {isEmail, isFullName} = useValidation()

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValues({...values, [event.target.name]: event.target.value})
        setError({...error, [event.target.name]: false})
    }

    const valid = () => {
        let isValid = true
        const errors = {...error}
        if (isFullName(values.fullName)) {
            errors.fullName = true
            isValid = false
        }
        if (isEmail(values.email)) {
            errors.email = true
            isValid = false
        }
        if (values.password.length < 8) {
            errors.password = true
            // errors.email = true

            isValid = false
        }
        setError({...errors})
        return isValid
    }

    const onSignUp = async () => {
        if (valid()) {
            setDisabled(true)
            try {
                const result = await AuthAPI.verify({...values,email:values.email.toLowerCase()})
                if (result.data.success) {
                    ErrorPopUp({
                        icon: "warning",
                        title: 'Please check your email',
                        text: 'We have send you email for verification',
                        timer: 10000,
                    })
                }/* setUser(result.data) setToken(result.data.authToken)*/
                onClose()// localStorage.setItem("AUTH_TOKEN", result.data.authToken)


            } catch (err) {
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'User with this e-mail already exist',

                })
            }
            setDisabled(false)
        }
    }
    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            onSignUp()
            window.removeEventListener("keypress", enter)
        }
    }

    useEffect(() => {
        window.addEventListener("keypress", enter)
        return () => {
            window.removeEventListener("keypress", enter)
        }
    }, [values])
    return (
        <PopUpContainer onClose={onClose} title={t("Sign-up-text") as string}>
            <div className={`P-signUpPopUp`}>
                <FutuInput
                    value={values.fullName}
                    onChange={onChangeHandler}
                    name={`fullName`}
                    label={`Full Name`}
                    required={true}
                    error={error.fullName}
                    errorMessage={"Full Name is an incorrect form. Example` John Doe"}
                />
                <FutuInput
                    value={values.email}
                    onChange={onChangeHandler}
                    name={`email`}
                    label={`E-mail`}
                    required={true}
                    error={error.email}
                    errorMessage={"email is not valid"}
                />
                <FutuInput
                    value={values.password}
                    onChange={onChangeHandler}
                    name={`password`}
                    label={`Password`}
                    required={true}
                    type={show ? "text" : `password`}
                    rightIcon={show ? "icon-eye-blocked" : "icon-eye"}
                    error={error.password}
                    errorMessage={"password must have a minimum of 8 characters"}
                    onClickIcon={() => {
                        setShow(!show)
                    }}
                />
                <div className={`P-buttons`}>
                    <ButtonLoader className={`primary`} onClick={onSignUp}>{t("Register-text")}</ButtonLoader>
                </div>
            </div>
        </PopUpContainer>
    )
}

export default SignUpPopUp