import React, {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {MeditationContext} from "../../../../../context/meditationContext";
import {IMonth, TReadChoice, TReadChoices, TReadDay, TReadMonth} from "../../../../../models/meditation.model";
import {useParams} from "react-router";
import GoBackButton from "../../../../../components/goBackButton";
import FutuInput from "../../../../../components/futuInput";
import ButtonLoader from "../../../../../components/buttonLoader";
import {useTranslation} from "react-i18next";
import "./style.scss"
import UploadCircleImageComponent from "../../../../../components/upload-circle-image-component";
import UploadApi from "../../../../../requests/api/uploadApi";
import TextareaAutosize from "react-textarea-autosize";
import {RoutesName} from "../../../../../router";
import {useNavigate} from "react-router-dom";
import UploadFileComponent from "../../../../../components/upload-file-component";

const EachMeditationSubChoiceAdminPage = () => {
    const {data, getData, setData, create, update} = useContext(MeditationContext)
    const {id, dayId, choiceId, subChoiceId} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<"" | "wav" | "mp3" | "png">("")

    const [value, setValue] = useState<Omit<TReadChoice, "_id">>({
        title: "",
        audio: "",
        audioName: "",
        audioReal: "",
        audioRealName: "",
        banner: "",
        bannerName: "",
        price: "",
        description: ""
    })


    const getFromData = () => {
        const newData = [...data]
        const current = newData.find(item => item._id === id) as TReadMonth
        const currentDay = current.days.find(day => day._id === dayId) as TReadDay
        const currentChoice = currentDay.choices.find(choice => choice._id === choiceId) as TReadChoices
        const {
            title,
            banner,
            bannerName,
            audio,
            audioRealName,
            audioReal,
            audioName,
            description,
            price
        } = currentChoice.subChoices.find(subChoice => subChoice._id === subChoiceId) as TReadChoice
        setValue({title, banner, bannerName, audio, audioRealName, audioReal, audioName, description, price})
    }
    const onLoad = () => {
        if (subChoiceId !== "AddSubChoice") {
            if (data.length <= 0) {
                getData()
            } else {
                getFromData()
            }
        } else {
            setValue({
                title: "",
                audio: "",
                audioName: "",
                audioReal: "",
                audioRealName: "",
                banner: "",
                bannerName: "",
                price: "",
                description: ""
            })
        }
    }
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue({...value, [event.target.name]: event.target.value})
    }

    const onCancel = async () => {
        await onLoad()
    }

    const onSave = async () => {
        setDisabled(true)
        const newData = [...data]
        const current = newData.find(item => item._id === id) as TReadMonth
        const currentDay = (dayId !== "AddDay" ? current.days.find(item => item._id === dayId) : current.days[current.days.length - 1]) as TReadDay
        const currentChoice = (choiceId !== "AddChoice" ? currentDay.choices.find(item => item._id === choiceId) : currentDay.choices[currentDay.choices.length - 1]) as TReadChoices
        if (subChoiceId !== "AddSubChoice") {
            const currentSubChoice = currentChoice.subChoices.find(item => item._id === subChoiceId) as TReadChoice
            for (const key in value) {
                // @ts-ignore
                currentChoice[key as keyof TReadDay] = value[key as keyof Omit<TReadDay, "_id">]
            }
        } else {
            currentChoice.subChoices.push({...value} as TReadChoice)
        }
        await setData([...newData])
        await update(id as string)
        if (subChoiceId === "AddSubChoice") navigate("/Admin/Meditation/" + id+"/"+dayId+"/"+choiceId)

        setDisabled(false)
    }

    const uploadAudio = async (file: File, key: string) => {
        setLoading(key === "Real" ? "wav" : "mp3")

        try {
            const result = await UploadApi.send(file)

            setValue({
                ...value,
                ["audio" + key]: result.data.src,
                ["audio" + key + "Name"]: result.data.name
            })
            setLoading("")
        } catch (err) {
            console.log(err)
        }
    }
    const uploadFile = async (file: File | undefined,) => {
        setLoading("png")

        if (file) {
            try {
                const result = await UploadApi.send(file)
                setValue({
                    ...value,
                    banner: result.data.src,
                    bannerName: result.data.name
                })
                setLoading("")
            } catch (err) {
                console.log(err)
            }
        }

    }


    useEffect(() => {
        onLoad()
    }, [data])
    return (<div className={`P-eachMeditationDayAdminPage`}>
        <GoBackButton
            backPage={(RoutesName.Admin_page + "/" + RoutesName.Admin_MeditationAdminPage + "/" + id + "/" + dayId + "/" + choiceId) as RoutesName}/>
        <p className={`P-title`}>{data.find(item => item._id === id) ? (data.find(item => item._id === id) as IMonth).title : "Add Month"}</p>
        <div className={`P-inputsContainer G-flex G-flex-wrap`}>

            <div className={`P-inputBox G-flex`}>

                <UploadCircleImageComponent getFile={uploadFile} src={value.banner} isLoading={isLoading === "png"}/>
            </div>
            <div className={`P-inputBox`}>
                <p className={`P-label`}>Real Music</p>
                <UploadFileComponent getFile={(file) => file ? uploadAudio(file, "Real") : ""} accept={"audio/wav"}
                                     isLoading={isLoading === "wav"}/>
                <p className={`P-try`}>{value.audioRealName}</p>

                <p className={`P-label`}>Preview Music</p>
                <UploadFileComponent getFile={(file) => file ? uploadAudio(file, "") : ""}
                                     accept={"audio/mp3,audio/mpeg,audio/mpeg3"} isLoading={isLoading === "mp3"}/>
                <p className={`P-try`}>{value.audioName}</p>

            </div>
            <div className={`P-inputBox`}>

                <FutuInput value={value.title} onChange={onChangeHandler} name={"title"}
                           label={"Title"}/>

            </div>
            <div className={`P-inputBox`}>
                <div className={`P-textArea`}>

                    <p className={`P-label`}>Description</p>

                    <TextareaAutosize className={`P-input`} value={value.description}
                                      onChange={(e) => setValue({
                                          ...value,
                                          description: e.target.value
                                      })}></TextareaAutosize>

                </div>
                <FutuInput value={value.price} onChange={onChangeHandler} name={"price"}
                           label={"Price in USD"} type={"number"}/>

            </div>
        </div>

        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{t("save-text")}</ButtonLoader>
            <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>

        </div>
    </div>)
}
export default EachMeditationSubChoiceAdminPage