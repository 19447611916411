export enum FutuButtonSizeEnum {
    s = "S",
    m = "M",
    l = "L"
}

export enum FutuButtonTypeEnum {
    yoga = "yoga",
    home = "home",
    live = "live"
}