import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {RoutesName} from "../../../../router";
import {useTranslation} from "react-i18next";

const SideBar = () => {
    const [show, setShow] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(true)
    const [portrait, setPortrait] = useState(window.innerWidth < window.innerHeight)

    const navigate = useNavigate()
    const {t} = useTranslation()
const resize=() => {
    setPortrait(window.innerWidth < window.innerHeight)
}
    useEffect(() => {


        window.addEventListener("resize",resize )
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])
    useEffect(() => {
        setOpen(!portrait)

    }, [portrait])
    const onClick = () => {
        if (portrait) {
            setOpen(false)
        }
    }
    return (<div className={`P-sideBar ${open ? "" : "close"}`}>
            <span className={`P-sideBarIcon ${open ? "icon-menu_open" : "icon-menu"}`} onClick={() => {
                setOpen(!open)
            }}/>
            <NavLink to={RoutesName.Admin_PagesContent}
                     className={`P-dropDownLabel`} onClick={(e) => {
                e.preventDefault()
                if (!show) {
                    navigate(RoutesName.Admin_HomePageContent)
                    setShow(true)
                    onClick()

                } else {
                    setShow(false)
                }
            }}>{t("Pages-content-text")}<span className={show ? `icon-circle-up` : `icon-circle-down`}/></NavLink>
            <div className={`P-dropDown ${show ? "open" : ""}`}>
                <NavLink to={RoutesName.Admin_HomePageContent} onClick={onClick}>{t("Home-Page-content-text")}</NavLink>
                <NavLink to={RoutesName.Admin_YogaPageContent} onClick={onClick}>{t("Yoga-Page-content-text")}</NavLink>
            </div>
            <NavLink to={RoutesName.Admin_HomePageMusic} onClick={onClick}>{t("Home-page-music-text")}</NavLink>
            <NavLink to={RoutesName.Admin_YogaPageMusic} onClick={onClick}>{t("Yoga-page-music-text")}</NavLink>
            <NavLink to={RoutesName.Admin_MeditationAdminPage} onClick={onClick}>{t("Meditation-Page-text")}</NavLink>
            <NavLink to={RoutesName.Admin_SampleMusic} onClick={onClick}>{t("Samples-text")}</NavLink>
            <NavLink to={RoutesName.Admin_SocialMedias} onClick={onClick}>{t("Social-medias-text")}</NavLink>
            <NavLink to={RoutesName.Admin_PaymentKeys} onClick={onClick}>{t("Payment-keys-text")}</NavLink>
            <NavLink to={RoutesName.Admin_Regulation} onClick={onClick}>{t("Regulations-text")}</NavLink>
        </div>
    )
}

export default SideBar