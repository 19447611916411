import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {IHomeMusic} from "../../models/homePageMusic.model";
import HomeMusicApi from "../../requests/api/homeMusicApi";
import ErrorPopUp from "../../components/errorPopUp";


interface IMusicContext {
    duration: string;
    setDuration: (token: string) => void
    finish: () => void
    onFinish: Dispatch<SetStateAction<() => void>>
    back: () => void
    onBack: Dispatch<SetStateAction<() => void>>
    method: string
    setMethod: Dispatch<SetStateAction<string>>
    format: string
    setFormat: Dispatch<SetStateAction<string>>
    isPlay: boolean
    setIsPlay: Dispatch<SetStateAction<boolean>>
    isClick: boolean
    setIsClick: Dispatch<SetStateAction<boolean>>
    isPlayYoga: boolean,
    setIsPlayYoga: Dispatch<SetStateAction<boolean>>
    isLoaded: boolean,
    setIsLoaded: Dispatch<SetStateAction<boolean>>
    mainMusicData: IHomeMusic | null,
    setMainMusicData: Dispatch<SetStateAction<IHomeMusic | null>>
    homeMusicData: IHomeMusic[],
    setHomeMusicData: Dispatch<SetStateAction<IHomeMusic[]>>,
    allHomeMusicData: IHomeMusic[],
    setAllHomeMusicData: Dispatch<SetStateAction<IHomeMusic[]>>
}


export const MusicContext = React.createContext<IMusicContext>({
    duration: "",
    setDuration: () => {
        ""
    },
    finish: () => {
        ""
    },
    onFinish: () => {
        ""
    },
    back: () => {
        ""
    },
    onBack: () => {
        ""
    },
    method: "",
    setMethod: () => {
        ""
    },
    format: "",
    setFormat: () => {
        ""
    },
    isPlay: false,
    setIsPlay: () => {
        ""
    },
    isClick: false,
    setIsClick: () => {
        ""
    },
    isPlayYoga: false,
    setIsPlayYoga: () => {
        ""
    },
    isLoaded: false,
    setIsLoaded: () => {
        ""
    },
    mainMusicData: null,
    setMainMusicData: () => {
        ""
    },
    homeMusicData: [],
    setHomeMusicData: () => {
        ""
    },
    allHomeMusicData: [],
    setAllHomeMusicData: () => {
        ""
    }
})
const MusicProvider = ({children}: { children: React.ReactNode }) => {
    const [duration, setDuration] = useState("")
    const [isClick, setIsClick] = useState(false)
    const [isPlay, setIsPlay] = useState(false)
    const [method, setMethod] = useState("loop")
    const [format, setFormat] = useState("mp3")
    const [isPlayYoga, setIsPlayYoga] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [mainMusicData, setMainMusicData] = useState<IHomeMusic | null>(null)
    const [homeMusicData, setHomeMusicData] = useState<IHomeMusic[]>([])
    const [allHomeMusicData, setAllHomeMusicData] = useState<IHomeMusic[]>([])
    const [finish, onFinish] = useState(() => () => {
        ""
    })
    const [back, onBack] = useState(() => () => {
        ""
    })
    const getMusic = async () => {
        try {
            const result = await HomeMusicApi.read()
            const newData = result.data.map((item: IHomeMusic & { _id: string }, index: number) => {
                return {...item, id: item._id}
            })
            newData.sort((a: IHomeMusic, b: IHomeMusic) => {
                // @ts-ignore
                return a.order - b.order
            })
            setAllHomeMusicData(newData)
        } catch (err) {
            ErrorPopUp({
                icon: "error",
                title: 'Oops...',
                text: 'Something wrong with the network'
            })
            console.log(err)
        }
    }
    useEffect(() => {
        setMainMusicData(allHomeMusicData[0])
        setHomeMusicData(allHomeMusicData.slice(1))

    }, [allHomeMusicData])
    useEffect(() => {
        getMusic()
    }, [])

    return (
        <MusicContext.Provider value={{
            mainMusicData,
            setMainMusicData,
            homeMusicData, setHomeMusicData,
            allHomeMusicData, setAllHomeMusicData,
            duration,
            setDuration,
            onFinish,
            finish,
            method,
            setMethod,
            isPlay,
            setIsClick,
            setIsPlay,
            isClick,
            isPlayYoga,
            setIsPlayYoga,
            back,
            onBack,
            format,
            setFormat,
            isLoaded,
            setIsLoaded
        }}>
            {children}
        </MusicContext.Provider>
    )


}

export default MusicProvider