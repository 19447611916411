import {LinkAuthenticationElement, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {FormEvent, FormEventHandler, useEffect, useState} from "react";
import useValidation from "../../../../hooks/useValidation";
import ErrorPopUp from "../../../errorPopUp";
import {useSearchParams} from "react-router-dom";
import ButtonLoader from "../../../buttonLoader";

const CheckoutForm = ({onSuccess}: { onSuccess?: () => void }) => {

    const stripe = useStripe()
    const elements = useElements()
    const [isProcessing, setIsProcessing] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [value, setValue] = useState({email: ""})
    const {isEmail} = useValidation()
    const validation = () => {
        let isValid = true
        if (isEmail(value.email)) {
            isValid = false
        }

        return isValid
    }
    const onClose = () => {
        searchParams.delete("popUP")
        setSearchParams(searchParams);

    }
    const handleSubmit: FormEventHandler = async (event) => {
        event.preventDefault()


        if (validation()) {
            if (!stripe || !elements) {
                return
            }
            setIsProcessing(true)

            const {error, paymentIntent} = await stripe.confirmPayment({
                elements, confirmParams: {return_url: window.location.origin}, redirect: 'if_required'
            })
            if (error) {
                console.log(error)
                ErrorPopUp({
                    icon: "error",
                    title: "Oooops...",
                    text: error.message
                })
            } else if (paymentIntent && paymentIntent.status === "succeeded") {
                ErrorPopUp({
                    icon: "success",
                    title: "succeeded..."
                })
                onSuccess && onSuccess()
                onClose()
            } else {
                ErrorPopUp({
                    icon: "error",
                    title: "Oooops...",
                    text: "Unexpected state"
                })
            }
            setIsProcessing(false)
        } else {
            elements?.getElement("linkAuthentication")?.focus()
        }
    }

    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSubmit(event as unknown as FormEvent)
            window.removeEventListener("keypress", enter)
        }
    }
    useEffect(() => {
        window.addEventListener("keypress", enter)
    }, [])
    return (<div className={`P-checkoutForm`}>
        <form id={`payment-form`}>
            <LinkAuthenticationElement onChange={(event) => {
                setValue({...value, ...event.value})
            }}/>
            {/* <input type="email" value={value.email} onChange={(event) => {
                setValue({...value, email: event.target.value})
            }
            }/>*/}
            <span style={{
                margin: "1vw"
            }}/>
            <PaymentElement options={{
                wallets: {
                    applePay: 'auto',
                    googlePay: 'auto'
                },


            }}/>
            <span style={{
                margin: "1vw"
            }}/>
            <ButtonLoader className={`primary`} onClick={handleSubmit}
                          disabled={isProcessing}>PAY</ButtonLoader>
        </form>
    </div>)
}

export default CheckoutForm