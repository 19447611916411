import Service from "../sevice/request.service"
import {ISocialIcons} from "../../models/content.model";

interface ISuccess {
    success: boolean
}

class SocialIconsApi {
    static creat = (data: ISocialIcons,) => {
        return Service.POST <ISocialIcons, ISuccess>(`/socialMedias`, data)
    }
    static delete = (id: string,) => {
        return Service.DELETE <null, ISuccess>(`/socialMedias/${id}`, null)
    }
    static update = (data: ISocialIcons & { id: string },) => {
        return Service.PATCH <ISocialIcons, ISuccess>(`/socialMedias/${data.id}`, data)
    }
    static updateAll = (data: ISocialIcons[]) => {
        return Service.PATCH <{ data: ISocialIcons[] }, ISuccess>("/socialMedias", {data})
    }

    static read = () => {
        return Service.GET<null, (ISocialIcons & { _id: string })>("/socialMedias",)
    }
}

export default SocialIconsApi