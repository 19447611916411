import {useContext, useEffect, useState} from "react";
import {MusicContext} from "../../../../context/musicPlayerContext";
import "./style.scss"
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import AudioCircle from "./components/audioCircle";
import {IHomeMusic} from "../../../../models/homePageMusic.model";
import ErrorPopUp from "../../../../components/errorPopUp";
import HomeMusicApi from "../../../../requests/api/homeMusicApi";
import ButtonLoader from "../../../../components/buttonLoader";

const HomePageMusicPage = () => {
    const {t} = useTranslation()
    const {allHomeMusicData, setAllHomeMusicData} = useContext(MusicContext)
    const [newData, setNewData] = useState<IHomeMusic[]>(structuredClone(allHomeMusicData))
    const [editMode, setEditMode] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [tackedElement, setTackedElement] = useState("")
    const [coveredElement, setCoveredElement] = useState("")
    useEffect(() => {
        setNewData(structuredClone(allHomeMusicData))
    }, [allHomeMusicData])

    const onSave = async () => {

        if (editMode) {
            setDisabled(true)
            try {
                const result = await HomeMusicApi.updateAll(newData)
                if (result.data) {

                    setAllHomeMusicData([...newData])
                    ErrorPopUp({
                        icon: "success",
                        title: 'Succeeded',
                    })
                } else {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }
            } catch (err) {
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something wrong with the network',

                })
            }
            setDisabled(false)
        }
        setEditMode(!editMode)
    }
    const onCancel = () => {
        setNewData(structuredClone(allHomeMusicData))
        setEditMode(false)
    }

    return (<div className={`P-pageContent`}>
        <div className={`G-flex G-justify-between G-align-center P-top`}>
            <p className={`P-title`}>{t("Home-Page-Music-text")} </p>
            <NavLink className={`P-addButton P-primaryBTN`} to={"add_music"}>Add Music</NavLink>
        </div>
        <div className={`P-HomePageMusic`}>
            {newData.sort((a, b) => {
                if (a.order && b.order) {
                    return (a.order - b.order as number)
                } else {
                    return 0
                }
            }).map((item, index) => {
                return <div className={`P-music`} key={item.id}
                            draggable={editMode}


                            onDragStart={(e) => {
                                setTackedElement(item.id)

                            }}
                            onDragOver={(e) => {
                                setCoveredElement(item.id)

                            }}
                            onDragEnd={(e) => {
                                if (editMode) {
                                    const data = [...newData]
                                    const tacked = newData.find(element => element.id === tackedElement)
                                    const covered = newData.find(element => element.id === coveredElement)
                                    if ((tacked?.order && covered?.order) || (tacked?.order === 0 || covered?.order === 0)) {
                                        const preOrder = covered?.order
                                        const file = data.find(el => el.order === tacked?.order) as IHomeMusic
                                        if (tacked?.order as number > (covered?.order as number)) {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order < tacked.order && item.order >= covered.order) {
                                                    item.order = item.order + 1
                                                }
                                            })
                                        } else {
                                            data.map(item => {
                                                if (covered?.order && tacked?.order && item.order && item.order > tacked.order && item.order <= covered.order) {
                                                    item.order -= 1
                                                }
                                            })
                                        }

                                        file.order = preOrder
                                        setNewData([...data])
                                    }
                                }
                            }}
                ><AudioCircle music={item}/></div>

            })
            }
        </div>
        <div className={`P-actionsSection`}>
            <ButtonLoader className={`primary`} onClick={onSave} disabled={disabled}>{editMode ? t("save-text") :
                <span><span className={`icon-pen`}/> {t("Edit-text")}</span>}</ButtonLoader>
            {editMode && <ButtonLoader className={`secondary`} onClick={onCancel}>{t("cancel-text")}</ButtonLoader>}

        </div>
    </div>)
}

export default HomePageMusicPage