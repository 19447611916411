import React, {useContext, useEffect} from "react";
import {MeditationContext} from "../../../../context/meditationContext";
import "./style.scss"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ErrorPopUp from "../../../../components/errorPopUp";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import meditationAPI from "../../../../requests/api/meditationAPI";

const MySwal = withReactContent(Swal)

const MeditationAdminPage = () => {
    const {getData, data, setData} = useContext(MeditationContext)
    const {t} = useTranslation()
    useEffect(() => {
        getData()
    }, [])
    const onDelete = async (event: React.MouseEvent, id: string) => {
        event.stopPropagation()
        event.preventDefault()
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await meditationAPI.delete(id)
                    if (result.status === 200) {
                        const newData = [...data]
                        newData.splice(newData.findIndex(item => item._id === id), 1)
                        setData([...newData])
                        MySwal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    } else {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network',

                        })
                    }

                } catch (err) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something wrong with the network',

                    })
                }

            }

        })

    }

    return (<div className={`P-meditationAdminPageContainer`}>
        <div className={`G-flex G-justify-between G-align-center P-top`}>
            <p className={`P-title`}>{t("Meditation-text")} </p>
            <NavLink className={`P-addButton P-primaryBTN`} to={"AddMonth"}>Add Month</NavLink>
        </div>
        <div className={`P-meditationAdminPage`}>
            {data.map((item) => {
                return (
                    <div key={item._id} className={`P-tab`}>
                            <span style={{zIndex: 3}} className={`icon-bin P-delete`}
                                  onClick={(event) => onDelete(event, item._id)}/>
                        <NavLink to={`${item._id}`}>

                            <>
                                <p className={`P-meditationTab`}><img loading={"lazy"}  src={item.banner} alt={item.bannerName}/>
                                </p>
                                {item.title &&
                                    <p style={{whiteSpace: "nowrap", maxWidth: "6vw"}}>{item.title} </p>}
                            </>
                        </NavLink>
                    </div>
                );
            })}
        </div>
    </div>)
}

export default MeditationAdminPage