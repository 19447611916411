import Service from "../sevice/request.service"
import {IYogaPageMusic} from "../../models/content.model";

interface ISuccess {
    success: boolean
}

class YogaMusic {
    static creat = (data: IYogaPageMusic,) => {
        return Service.POST <IYogaPageMusic, ISuccess>(`/yogaMusic`, data)
    }
    static delete = (id: string,) => {
        return Service.DELETE <null, ISuccess>(`/yogaMusic/${id}`, null)
    }
    static update = (data: IYogaPageMusic,id:string) => {
        return Service.PATCH <IYogaPageMusic, ISuccess>(`/yogaMusic/${id}`, data)
    }

    static read = () => {
        return Service.GET<null, IYogaPageMusic[]>("/yogaMusic",)
    }
}

export default YogaMusic