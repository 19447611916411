import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";

const MySwal = withReactContent(Swal)


const ErrorPopUp = ({
                        icon,
                        title,
                        text,
    timer=3000
                    }: { icon: SweetAlertIcon | undefined, title: string, text?: string,timer?:number }) => {
    return MySwal.fire({
        icon: icon,
        title: title,
        text: text,
        timer: timer,
        showConfirmButton: false

    })
}

export default ErrorPopUp