import Service from "../sevice/request.service"
import {IPageContent} from "../../models/content.model";
import {IHomeMusic, IHomeMusicCreat} from "../../models/homePageMusic.model";

interface ISuccess {
    success: boolean
}

class HomeMusicApi {
    static creat = (data: IHomeMusicCreat,) => {
        return Service.POST <IHomeMusicCreat, ISuccess>(`/homeMusic`, data)
    }
    static delete = (id:string,) => {
        return Service.DELETE <null, ISuccess>(`/homeMusic/${id}`,null)
    }
    static    update = (data: IHomeMusic,) => {
        return Service.PATCH <IHomeMusic, ISuccess>(`/homeMusic/${data.id}`, data)
    }
    static updateAll = (data: IHomeMusic[]) => {
        return Service.PATCH <{ data: IHomeMusic[] }, ISuccess>("/homeMusic", {data})
    }

    static read = () => {
        return Service.GET<null, IHomeMusic>("/homeMusic",)
    }
}

export default HomeMusicApi