import React, {ChangeEvent, DragEventHandler, FC, MouseEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss"
import Loader from "../Loader";

type getFile = (file: File | undefined) => void
const UploadImageComponent: FC<{ getFile: getFile, accept?: string, isLoading: boolean }> = ({
                                                                                                 getFile,
                                                                                                 accept,
                                                                                                 isLoading = true
                                                                                             }) => {
    const {t} = useTranslation()
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<File | undefined>()
    const inputRef = useRef<HTMLInputElement>(null);


    const handleDrag: DragEventHandler<HTMLDivElement | HTMLFormElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop: DragEventHandler<HTMLDivElement | HTMLFormElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);

        if (event.dataTransfer?.files && event.dataTransfer.files[0]) {
            if (!!accept) {
                if (accept.includes(event.dataTransfer.files[0].type)) {
                    setFile(event.dataTransfer.files[0])

                }
            } else {
                setFile(event.dataTransfer.files[0])

            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            if (!!accept) {
                if (accept.includes(event.target.files[0].type)) {
                    setFile(event.target.files[0])

                }
            } else {
                setFile(event.target.files[0])

            }
        }
    };
    useEffect(() => {

        getFile(file);

    }, [file?.name])
    const onButtonClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        inputRef.current?.click();
    };
    const onCancelClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        setFile(undefined)
    }

    return (
        <form className="G-form-file-upload" onDragEnter={handleDrag} draggable={true}
              onSubmit={(e) => e.preventDefault()}>

            <label className="G-label-file-upload">
                <input ref={inputRef} type="file" className="G-input-file-upload" multiple={false}
                       onChange={handleChange}
                       accept={accept}/>
                {file
                    ?
                    <div className={`G-file-name-container`}>
                        {isLoading ? <Loader/> : <p className={`G-file-name`}>{file.name}</p>}
                        <span className={`icon-cross`} onClick={onCancelClick}/>
                    </div>
                    :
                    <div>
                        <p>{t("Drop files here or click to upload.")}</p>
                        <span className="icon upload-file" onClick={onButtonClick}/>
                    </div>
                }


            </label>
            {dragActive &&
                <div className="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                     onDragOver={handleDrag}
                     onDrop={handleDrop}></div>}
        </form>
    );
};

export default UploadImageComponent