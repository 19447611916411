import {useEffect, useState} from "react";
import PopUpContainer from "../popUpContainer";
import DonationPopUp from "../donationPopUp";
import "./style.scss"
import {useTranslation} from "react-i18next";
import FutuInput from "../futuInput";
import ButtonLoader from "../buttonLoader";
import {NavLink} from "react-router-dom";

const Donation = ({onClose}: { onClose: () => void }) => {
    const [step, setStep] = useState(1)
    const [error, setError] = useState(false)
    const {t} = useTranslation()
    const variants = ["5", "20", "50"]
    const [amount, setAmount] = useState(variants[0])
    const nextHandler = () => {
        if (+amount < 1) {
            setError(true)
        } else {
            setStep(2)
        }
    }
    const enter = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            nextHandler()
            window.removeEventListener("keypress", enter)
        }
    }
    useEffect(() => {
        window.addEventListener("keypress", enter)
    }, [])
    return (
        <PopUpContainer onClose={onClose}>
            <div className={`P-donation`}>
                {step === 1 && <div className={`P-donationContainer`}>
                    {variants.map((item, index) => {
                        return (
                            <div key={`${item}` + index} className={`P-donationBox ${amount === item ? "active" : ""}`}
                                 onClick={() => {
                                     setAmount(item)
                                     // setStep(2)
                                 }}>
                                <span> {item}$</span>
                            </div>)
                    })
                    }
                    {/* <div className={`P-donationBox custom`}
                         onClick={() => {
                             setOpen(true)
                         }}>
                        <span>{t("choose-your-option-text")} </span>
                    </div>*/}

                    <FutuInput type={"number"} value={amount}
                               label={`${t("choose-your-option-text")} (min 1$ - max 999.999$)`}
                               onChange={(event) => {
                                   if (+event.target.value > 1000000) {
                                       setAmount("999999")
                                   } else {
                                       setAmount(event.target.value.replace(/^0+/, ''))
                                   }
                                   setError(false)
                               }}

                               error={error}
                               errorMessage={"min 1$"}
                    />
                    <p className={`P-terms`}>By clicking “pay”You confirm that You have read, understood, and accept Futurili’s <NavLink to={"?regulation=termsAndCondition&popUP=donation"}>Terms</NavLink></p>
                    <ButtonLoader className={`primary`} onClick={nextHandler}>{t("NEXT-text")}
                    </ButtonLoader>
                </div>
                }
                {step === 2 && <DonationPopUp amount={+amount}/>}
            </div>
        </PopUpContainer>
    )
}

export default Donation