import {useTranslation} from "react-i18next";
import React, {useContext, useEffect} from "react";
import AudioPlayer from "../../../../components/audioPlayer";
import {AudioPlayerEnum} from "../../../../enums/audioPlayerEnum";
import {MusicContextYoga} from "../../../../context/musicContextYoga";
import {NavLink} from "react-router-dom";
import "./style.scss"

const YogaPageMusicPage = () => {
    const {t} = useTranslation()
    const {getMusics, data} = useContext(MusicContextYoga)

    useEffect(() => {
        (async () => {
            await getMusics()
        })()
    }, [])
    const onDelete = (id: string) => {
        ""
    }

    return (<div className={`P-pageContent P-YogaMusic`}>
            <div className={`G-flex G-justify-between G-align-center P-top`}><p
                className={`P-title`}>{t("Yoga-Page-music-text")}</p>
                <NavLink className={`P-addButton P-primaryBTN`} to={"add_music"}>Add Music</NavLink>
            </div>


            {data && data.map((item,index) => {
                    if (item._id) {
                        return (<div key={item._id as string} className={`P-deleteContainer`}><span
                                className={`P-delete icon-bin`}
                                onClick={(event) => onDelete(item._id as string)}/><NavLink
                                className={`P-present`} to={item._id}>
                                <AudioPlayer orientation={AudioPlayerEnum.right} textAsBanner={`${index+1}`}
                                             currentPlaying={false}
                                             onPlay={(e) => {
                                                 ""
                                             }}
                                             music={{src: item.src, src2: item.src2, route: "none" as string}}
                                             title={
                                                 <div className={`P-presentText`}>
                                                     {item.title}
                                                     <p className={`P-info`}>{item.subtitle} </p>
                                                 </div>
                                             }
                                />

                            </NavLink></div>
                        )
                    } else {
                        return null
                    }
                }
            )}


        </div>
    )
}

export default YogaPageMusicPage