import "./style.scss"
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {RoutesName} from "src/router";
import {useTranslation} from "react-i18next";
import SocialIcons from "src/components/socialIcons";
import AuthorizationButton from "./components/authorizationButton";
import {ButtonEnum} from "src/enums/buttonEnum";
import {useContext, useEffect, useState} from "react";
import {MuteContext} from "src/context/muteContext";
import {ClickSoundContext} from "../../context/clickSoundContext";
import {AccountsEnum} from "src/enums/accountsEnum";
import ClickOutsideBlock from "../click-outsideBlock";
import {AuthorizationContext} from "../../context/authorizationContext";
import ProfileElement from "../profileElement";
import Donation from "../donation";
import SocialLinksPopUp from "../socialLinksPopUP";
import SignInPopUp from "../signInPopUp";
import SignUpPopUp from "../signUpPopUp";
import {publishableKey} from "../../models/publishableKey";
import {decodeToken} from "react-jwt";
import {Jwt} from "jsonwebtoken";
import authApi, {ISignUpData} from "../../requests/api/authApi";
import ErrorPopUp from "../errorPopUp";
import ForgotPassword from "../forgotPassword";
import RecoveryPassword from "../recoveryPassword";
import PopUpContainer from "../popUpContainer";
import {PageContentContext} from "../../context/pageContentContext";

const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [openPopUp, setOpenPopUp] = useState("")
    const [id, setId] = useState("")
    const {setToken, setIsAdmin, setUser} = useContext(AuthorizationContext)
    const {regulations} = useContext(PageContentContext)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {isMute, setIsMute} = useContext(MuteContext)
    const {playSound} = useContext(ClickSoundContext)
    const {token} = useContext(AuthorizationContext)
    const onClose = () => {
        searchParams.delete("popUP")
        searchParams.delete("forgotPassword")
        searchParams.delete("regulation")
        searchParams.delete(AccountsEnum.streamingLinks)
        setSearchParams(searchParams);
    }
    useEffect(() => {
        const opened = localStorage.getItem("Xhlk")
        if (!!opened) {
            if (+opened < 10) {
                localStorage.setItem("Xhlk", `${+opened + 1}`)
            } else {
                localStorage.removeItem("Xhlk")
            }
        } else {
            navigate(`?${AccountsEnum.streamingLinks}`)
            localStorage.setItem("Xhlk", `0`)

        }
    }, [])
    useEffect(() => {
        if (searchParams.has("verify")) {
            (async () => {
                const token = decodeToken(searchParams.get("verify") as string) as Jwt & ISignUpData & { exp: number }
                if (token && token.exp * 1000 < Date.now()) {
                    ErrorPopUp({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Your token has expired',

                    })

                } else if (token) {
                    try {
                        const result = await authApi.signUp({
                            email: token.email,
                            password: token.password,
                            fullName: token.fullName
                        })
                        setUser(result.data);
                        setToken(result.data.authToken)
                        localStorage.setItem("AUTH_TOKEN", result.data.authToken)
                        ErrorPopUp({
                            icon: 'success',
                            title: 'Success',
                            text: 'Your email has been verified',
                        })
                    } catch (err) {
                        ErrorPopUp({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something wrong with the network ',

                        })
                    }
                }
                searchParams.delete("verify")
                setSearchParams(searchParams);
            })()
        }

        if (searchParams.has("forgotPassword")) {
            const token = decodeToken(searchParams.get("forgotPassword") as string) as Jwt & { id: string, exp: number }
            if (token && token.exp * 1000 < Date.now()) {
                ErrorPopUp({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Your token has expired',

                })

            } else if (token) {
                setId(token.id)
                console.log(token)
                return setOpenPopUp("recovery")

            }
        }
        if (searchParams.has("regulation")) {
            setOpenPopUp(searchParams.get("regulation") as string)
        }else if (searchParams.has("popUP")) {
            setOpenPopUp(searchParams.get("popUP") as string)
        } else if (searchParams.has(AccountsEnum.streamingLinks)) {
            setOpenPopUp(AccountsEnum.streamingLinks)
        } else {
            setOpenPopUp("")
        }
        if (token && searchParams.get("popUP") !== AccountsEnum.donation && !searchParams.has("regulation")  && !searchParams.has(AccountsEnum.streamingLinks)) {
            onClose()
        }
    }, [searchParams])
    useEffect(() => {
        if (token && searchParams.get("popUP") !== AccountsEnum.donation && !searchParams.has(AccountsEnum.streamingLinks)) {
            onClose()
        }
    }, [token])

    return (
        <header className={`P-header`}>
            <nav className={`G-container P-navigationContainer G-align-center G-justify-between`}>
                <NavLink to={RoutesName.Home_page} className={`P-logo`} onMouseDown={playSound}><img loading={"lazy"} src={"https://futustatics.s3.eu-central-1.amazonaws.com/images/logo_white.webp"}
                                                                                                     alt="logo"/></NavLink>
                <button className={`P-donateButton  `} onClick={() => {
                    navigate(`?popUP=${AccountsEnum.donation}`)
                }}>
                    {t("Donate-text")}
                </button>
                <SocialIcons/>
                {!token ? <section className={`P-authorizationActions `}>
                        <AuthorizationButton type={ButtonEnum.primary} onClick={() => {
                            ""
                            if (publishableKey) navigate(`?popUP=${AccountsEnum.logIn}`)
                        }}>{t("Log-in-text")}</AuthorizationButton>
                        <AuthorizationButton type={ButtonEnum.secondary} onClick={() => {
                            if (publishableKey) navigate(`?popUP=${AccountsEnum.signUp}`)
                            ""
                        }}>{t("Sign-up-text")}</AuthorizationButton>
                    </section> :
                    <section className={`P-profile`}>
                        <ProfileElement></ProfileElement>
                    </section>}
                <section className={`P-soundIconContainer`}>
                    <span className={isMute ? `icon-mute` : `icon-sound`} onClick={() => {
                        setIsMute(!isMute)
                    }}/>
                </section>
                <div className={`P-popUps`}>


                    {publishableKey && openPopUp === AccountsEnum.logIn && !token &&
                        <ClickOutsideBlock onClick={onClose}>
                            <SignInPopUp
                                onClose={onClose}></SignInPopUp>
                        </ClickOutsideBlock>}
                    {publishableKey && openPopUp === AccountsEnum.signUp && !token &&
                        <ClickOutsideBlock onClick={onClose}>
                            <SignUpPopUp
                                onClose={onClose}></SignUpPopUp>
                        </ClickOutsideBlock>}
                    {openPopUp === AccountsEnum.streamingLinks && <ClickOutsideBlock onClick={onClose}>
                        <>
                            <SocialLinksPopUp onClose={onClose}/>
                        </>
                    </ClickOutsideBlock>}
                    {openPopUp === AccountsEnum.forgotPassword && <ClickOutsideBlock onClick={onClose}>
                        <>
                            <ForgotPassword onClose={onClose}/>
                        </>
                    </ClickOutsideBlock>}
                    {openPopUp === AccountsEnum.recoveryPassword && <ClickOutsideBlock onClick={onClose}>
                        <>
                            <RecoveryPassword onClose={onClose} id={id}/>
                        </>
                    </ClickOutsideBlock>}
                        {openPopUp === "termsAndCondition" && <ClickOutsideBlock onClick={onClose}>
                        <PopUpContainer onClose={()=>{
                            searchParams.delete("regulation")
                            setSearchParams(searchParams);
                        }} title={"Terms"}>
                            <p className={`P-regulations`}>{regulations.termsAndConditions}</p>
                        </PopUpContainer>
                    </ClickOutsideBlock>}
                    {openPopUp === "privacyAndCookiesPolices" && <ClickOutsideBlock onClick={onClose}>
                        <PopUpContainer onClose={onClose} title={""}>
                            <div>
                            <p className={`P-regulations`}>
                                <h3 className={`P-regulationTitle`}>Privacy policy</h3>

                                {regulations.privacyPolicy}
                                <h3 className={`P-regulationTitle`}>Cookies policy</h3>
                                {regulations.cookiesPolicy}</p>

                            </div>
                        </PopUpContainer>

                    </ClickOutsideBlock>}
                    {openPopUp === AccountsEnum.donation && <ClickOutsideBlock onClick={onClose}>
                        <Donation
                            onClose={onClose}></Donation>
                    </ClickOutsideBlock>}


                </div>
            </nav>
        </header>
    )
}
export default Header