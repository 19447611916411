import {FC, MouseEventHandler, ReactNode} from "react";
import {ButtonEnum} from "src/enums/buttonEnum";
import "./style.scss"
import {publishableKey} from "../../../models/publishableKey";

interface IAuthorizationButton {
    children: ReactNode,
    type: ButtonEnum,
    onClick: MouseEventHandler
}

const AuthorizationButton: FC<IAuthorizationButton> = ({children, type, onClick}) => {
    return (<button onClick={onClick} className={`P-authorizationButton ${publishableKey ? "" : "comingSoon"} ${type}`}>
        {children}
    </button>)
}

export default AuthorizationButton