import React, {useContext, useEffect} from 'react';
import {AuthorizationContext} from "./context/authorizationContext";
import Header from "./components/header";
import {Navigate, Route, Routes} from "react-router";
import {
    adminRoutes,
    adminSubRoutes,
    exclusivePage,
    privateRoutes,
    publicRoutes,
    RoutesName,
    settingSubRoutes
} from "./router";
import {MusicContext} from "./context/musicPlayerContext";
import Loader from "./components/Loader";
import {PageContentContext} from "./context/pageContentContext";
import Footer from "./components/footer";

function App() {
    const {token, isAdmin} = useContext(AuthorizationContext)
    const {homeMusicData, mainMusicData} = useContext(MusicContext)
    const {loading, data} = useContext(PageContentContext)
    useEffect(() => {
        const body = document.querySelector("#root") as HTMLDivElement
        if (!!body) {
            body.style.height = `${window.innerHeight}px `

        }

        window.addEventListener("resize", () => {

            if (!!body) {
                body.style.height = `${window.innerHeight}px  `

            }
        })
    }, [])

    return (
        <div className="App">
            <Header/>
            {loading ? <Loader/> : (homeMusicData.length > 0 || !!mainMusicData) && <main className={`G-mainContainer`}>
                <Routes>
                    {
                        publicRoutes.map((item,) => (
                            <Route path={item.path} element={<item.component/>} key={item.path}/>
                        ))
                    }
                    {
                        !!token ? privateRoutes.map((item,) => (
                            <Route path={item.path} element={<item.component/>} key={item.path}>
                                <Route index element={<Navigate to={RoutesName.Personal_Information} replace/>}/>
                                {/*<Route path={RoutesName.Admin_PagesContent}*/}
                                {/*       element={<Navigate to={"/"} replace/>}/>*/}
                                {settingSubRoutes.map((item,) => (
                                    <Route path={item.path} element={<item.component/>} key={item.path}/>
                                ))
                                }
                            </Route>
                        )) : ""
                    }
                    {
                        isAdmin ? adminRoutes.map((item,) => (
                            <Route path={item.path} element={<item.component/>} key={item.path}>
                                <Route index element={<Navigate to={RoutesName.Admin_HomePageContent} replace/>}/>
                                <Route path={RoutesName.Admin_PagesContent}
                                       element={<Navigate to={"/"} replace/>}/>
                                {adminSubRoutes.map((item,) => (
                                    <Route path={item.path} element={<item.component/>} key={item.path}/>
                                ))
                                }
                            </Route>
                        )) : ""
                    }
                    {
                        data.homePage.samplePage &&
                        <Route path={exclusivePage.path} element={<exclusivePage.component/>}/>
                    }
                    <Route path="*" element={<Navigate to="/" replace/>}/>{" "}

                </Routes>
            </main>}

            <Footer/>
        </div>
    );
}

export default App;
